import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSol } from "../../../store/sol/hooks"
// import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"
import { SolConformityTypes } from "@iko-design-center/shared"

export function useSolBuildingType() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setSolBuildingTypeType: setSolBuildingTypeTypeState,
  } = useSol()

  const solBuildingType = configurationState.solBuildingType.type
  const solConformity = configurationState.solConformity.type
  const optionsDisabled = solConformity === SolConformityTypes.P1_1_2
  const nextButtonDisabled = !solBuildingType

  function handleNextButtonClick() {
    history.push(Routes.SOL_4_OVERVIEW)
  }

  function setSolBuildingTypeType(type: any) {
    setSolBuildingTypeTypeState(type)
    // renderer.displaySolInsulationType(type)
  }

  return {
    t,
    solBuildingType,
    optionsDisabled,
    setSolBuildingTypeType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
