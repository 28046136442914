"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialCavityWallConfigurationState = void 0;
const constants_1 = require("../constants");
const fe_materials_1 = require("../fe_materials");
exports.initialCavityWallConfigurationState = {
    humanReadableName: null,
    wallType: {
        type: null,
        material: null,
    },
    wallInsulation: {
        type: null,
        material: null,
    },
    outerCavityLeaf: {
        type: constants_1.OuterCavityLeafTypes.FACADE_MASONRY,
        material: fe_materials_1.FE_MATERIALS.find((material) => material.id === constants_1.MaterialIDs.FACADE_MASONRY),
    },
    files: {
        nl_be: null,
        fr_be: null,
        nl_nl: null,
        fr_fr: null,
    },
};
