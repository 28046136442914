import { SupportDTO, RequestAdviceDTO } from "@iko-design-center/shared";
import * as Sentry from "@sentry/react";
import axios from "axios";

// const baseUrl = process.env.NODE_ENV === "production" ? "" : "http://localhost:3000";
const baseUrl = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

function postDownload(dto: any, applicationType: string): Promise<void> {
  return new Promise((resolve, reject) => {
    axios
      .post<string>(`${baseUrl}/api/download/${applicationType}`, dto)
      .then((response) => {
        resolve();
      })
      .catch((e: any) => {
        Sentry.captureException(e);
        reject(e);
      });
  });
}

function postRequestAdvice(dto: RequestAdviceDTO): Promise<void> {
  return new Promise((resolve, reject) => {
    axios
      .post<string>(`${baseUrl}/api/request-advice`, dto)
      .then((response) => {
        resolve();
      })
      .catch((e: any) => {
        Sentry.captureException(e);
        reject(e);
      });
  });
}

function postSupport(dto: SupportDTO): Promise<void> {
  return new Promise((resolve, reject) => {
    axios
      .post<string>(`${baseUrl}/api/support`, dto)
      .then((response) => {
        resolve();
      })
      .catch((e: any) => {
        Sentry.captureException(e);
        reject(e);
      });
  });
}

export const api = {
  postDownload,
  postRequestAdvice,
  postSupport,
};
