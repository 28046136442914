import {
  CavityWallConfigurationState,
  CavityWallThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { DownloadState } from "./download";
import { cavityWallSlice } from "./store";

export function useCavityWall() {
  const dispatch = useAppDispatch();
  const cavityWallState = useAppSelector((state) => state.cavityWall);
  const configurationState = useAppSelector(
    (state) => state.cavityWall.configuration
  );
  const downloadState = useAppSelector((state) => state.cavityWall.download);
  const possibleConfigurations = useAppSelector(
    (state) => state.cavityWall.possibleConfigurations
  );
  const thermalPerformance = useAppSelector(
    (state) => state.cavityWall.thermalPerformance
  );
  const cavityWallScreenshots = useAppSelector((state) => state.cavityWall.screenshots);

  const setWallTypeType = useCallback(
    (wallTypeType: any) => {
      dispatch(cavityWallSlice.actions.setWallTypeType(wallTypeType));
    },
    [dispatch]
  );

  const setWallInsulationType = useCallback(
    (wallInsulationType: any) => {
      dispatch(
        cavityWallSlice.actions.setWallInsulationType(wallInsulationType)
      );
    },
    [dispatch]
  );

  const setWallTypeMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(cavityWallSlice.actions.setWallTypeMaterial(material));
    },
    [dispatch]
  );

  const setWallInsulationMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(cavityWallSlice.actions.setWallInsulationMaterial(material));
    },
    [dispatch]
  );

  const setOuterCavityLeafMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(cavityWallSlice.actions.setOuterCavityLeafMaterial(material));
    },
    [dispatch]
  );

  const setThermalPerformance = useCallback(
    (thermalPerformance: CavityWallThermalPerformanceState) => {
      dispatch(
        cavityWallSlice.actions.setThermalPerformance(thermalPerformance)
      );
    },
    [dispatch]
  );

  const resetConfigurationToWallType = useCallback(
    () => dispatch(cavityWallSlice.actions.resetConfigurationToWallType()),
    [dispatch]
  );

  const resetConfigurationToWallInsulation = useCallback(
    () =>
      dispatch(cavityWallSlice.actions.resetConfigurationToWallInsulation()),
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration: CavityWallConfigurationState) =>
      dispatch(cavityWallSlice.actions.setConfiguration(configuration)),
    [dispatch]
  );

  const setHumanReadableName = useCallback(
    (name: string) =>
      dispatch(cavityWallSlice.actions.setHumanReadableName(name)),
    [dispatch]
  );

  const resetConfiguration = useCallback(
    () => dispatch(cavityWallSlice.actions.resetConfiguration()),
    [dispatch]
  );

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(cavityWallSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  );

  const addScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(cavityWallSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  );

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(cavityWallSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  );

  return {
    cavityWallState,
    addScreenshot,
    setOverviewScreenshot,
    cavityWallScreenshots,
    thermalPerformance,
    setThermalPerformance,
    downloadState,
    possibleConfigurations,
    configurationState,
    setWallTypeType,
    setWallInsulationType,
    setWallTypeMaterial,
    setWallInsulationMaterial,
    setOuterCavityLeafMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToWallType,
    resetConfigurationToWallInsulation,
    resetConfiguration,
  };
}
