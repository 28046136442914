import {
  SlopedRoofConfigurationState,
  SlopedRoofThermalPerformanceState,
  slopedRoofMaterialMap,
  SlopedRoofTypeTypes,
  SlopedRoofInsulationTypeTypes,
  FE_MATERIALS,
  FEMaterial,
} from "@iko-design-center/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadState } from "./download";
import { initialSlopedRoofState } from "./state";
import {
  getThermalPerformance,
  updateThermalLayer,
} from "./thermalPerformance";

export const slopedRoofSlice = createSlice({
  name: "slopedRoof",
  initialState: initialSlopedRoofState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialSlopedRoofState,
        download: state.download,
        screenshots: state.screenshots,
      };
    },
    resetConfigurationToRoofType: (state) => {
      const draft = {
        ...initialSlopedRoofState,
        download: state.download,
        configuration: {
          ...initialSlopedRoofState.configuration,
          roofType: state.configuration.roofType,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    resetConfigurationToRoofInsulation: (state) => {
      const draft = {
        ...initialSlopedRoofState,
        download: state.download,
        configuration: {
          ...initialSlopedRoofState.configuration,
          roofType: state.configuration.roofType,
          roofInsulation: state.configuration.roofInsulation,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    setThermalPerformance: (
      state,
      action: PayloadAction<SlopedRoofThermalPerformanceState>
    ) => {
      state.thermalPerformance = action.payload;
    },
    setSlopedRoofTypeType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          slopedRoofMaterialMap[action.payload as SlopedRoofTypeTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          roofType: {
            ...state.configuration.roofType,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setSlopedRoofInsulationType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          slopedRoofMaterialMap[action.payload as SlopedRoofInsulationTypeTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          roofInsulation: {
            ...state.configuration.roofInsulation,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setSlopedRoofTypeMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.roofType.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "roofType"
      );
    },
    setSlopedRoofInsulationMaterial: (
      state,
      action: PayloadAction<FEMaterial>
    ) => {
      const material = action.payload;
      state.configuration.roofInsulation.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "roofInsulation"
      );
    },
    setConfiguration(
      state,
      action: PayloadAction<SlopedRoofConfigurationState>
    ) {
      state.configuration = action.payload;
      state.thermalPerformance = getThermalPerformance(action.payload);
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload;
      state.thermalPerformance = getThermalPerformance(state.configuration);
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload;
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload];
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload;
    },
  },
});
