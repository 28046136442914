import {
  FloorConfigurationState,
  FloorThermalPerformanceState,
  initialFloorConfigurationState,
  initialFloorThermalPerformanceState,
} from "@iko-design-center/shared";
import { DownloadState, initialDownloadState } from "./download";

export type FloorState = {
  configuration: FloorConfigurationState;
  download: DownloadState;
  thermalPerformance: FloorThermalPerformanceState;
  screenshots: string[];
  overviewScreenshot: string | null;
};

export const initialFloorState: FloorState = {
  configuration: initialFloorConfigurationState,
  download: initialDownloadState,
  thermalPerformance: initialFloorThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
};
