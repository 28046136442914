import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSol } from "../../../store/sol/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"

export function useSolAcousticLayer() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setSolAcousticLayerType: setSolAcousticLayerTypeState,
  } = useSol()

  const solNoiseLevelType = configurationState.solNoiseLevel.type
  const solAcousticLayerType = configurationState.solAcousticLayer.type
  const nextButtonDisabled = !solAcousticLayerType

  function handleNextButtonClick() {
    history.push(Routes.SOL_4_OVERVIEW)
  }

  function setSolAcousticLayerType(type: any) {
    setSolAcousticLayerTypeState(type)
    renderer.displaySolAcousticLayerType(type)
  }

  return {
    t,
    solNoiseLevelType,
    solAcousticLayerType,
    setSolAcousticLayerType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
