import styled from "styled-components";

type SpacerProps = { widthPx?: number; heightPx?: number };

export const Spacer = styled.div<SpacerProps>`
  width: ${({ widthPx, theme }) =>
    widthPx ? `${theme.designWidth(widthPx)}px` : `auto`};
  height: ${({ heightPx, theme }) =>
    heightPx ? `${theme.designHeight(heightPx)}px` : `auto`};
`;
