import styled from "styled-components";
import { styles } from "../../styles";

export const Button = styled.button`
  &:hover {
    background: ${styles.colors.primaryNonHover};
  }
  
  &.clicked {
    background-image: url('/assets/images/pulse.svg');
    background-repeat: no-repeat;
    background-position: center center; 
    background-size: auto 1000%;
    
    &:hover {
      cursor: default;
    }
  }

  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  width: 100%;
  background: ${styles.colors.primary};
  color: ${styles.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-width: 0;
  color: ${styles.colors.white};
  font-size: 1rem;
`;
