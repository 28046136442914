import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { toastSlice } from "./store";

export function useToast() {
  const dispatch = useAppDispatch();
  const display = useAppSelector((state) => state.toast.display);

  const closeToast = useCallback(
    () => dispatch(toastSlice.actions.closeToast()),
    [dispatch]
  );

  const displayScreenshotSavedToast = useCallback(
    () => dispatch(toastSlice.actions.displayScreenshotSavedToast()),
    [dispatch]
  );

  return {
    display,
    closeToast,
    displayScreenshotSavedToast,
  };
}
