import {
  VentilatedWallConfigurationState,
  VentilatedWallThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { DownloadState } from "./download";
import { ventilatedWallSlice } from "./store";

export function useVentilatedWall() {
  const dispatch = useAppDispatch();
  const ventilatedWallState = useAppSelector((state) => state.ventilatedWall);
  const configurationState = useAppSelector(
    (state) => state.ventilatedWall.configuration
  );
  const downloadState = useAppSelector(
    (state) => state.ventilatedWall.download
  );
  const thermalPerformance = useAppSelector(
    (state) => state.ventilatedWall.thermalPerformance
  );
  const ventilatedWallScreenshots = useAppSelector(
    (state) => state.ventilatedWall.screenshots
  );

  const setVentilatedWallWallType = useCallback(
    (ventilatedWallWallType: any) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallWallType(
          ventilatedWallWallType
        )
      );
    },
    [dispatch]
  );
  const setVentilatedWallFacadeCladdingType = useCallback(
    (VentilatedWallFacadeCladdingType: any) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallFacadeCladdingType(
          VentilatedWallFacadeCladdingType
        )
      );
    },
    [dispatch]
  );
  const setVentilatedWallInsulationType = useCallback(
    (VentilatedWallInsulationType: any) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallInsulationType(
          VentilatedWallInsulationType
        )
      );
    },
    [dispatch]
  );

  const setVentilatedWallWallTypeMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallWallTypeMaterial(material)
      );
    },
    [dispatch]
  );
  const setVentilatedWallFacadeCladdingMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallFacadeCladdingMaterial(
          material
        )
      );
    },
    [dispatch]
  );
  const setVentilatedWallInsulationMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(
        ventilatedWallSlice.actions.setVentilatedWallInsulationMaterial(
          material
        )
      );
    },
    [dispatch]
  );

  const setThermalPerformance = useCallback(
    (thermalPerformance: VentilatedWallThermalPerformanceState) => {
      dispatch(
        ventilatedWallSlice.actions.setThermalPerformance(thermalPerformance)
      );
    },
    [dispatch]
  );

  const resetConfigurationToWallType = useCallback(
    () => dispatch(ventilatedWallSlice.actions.resetConfigurationToWallType()),
    [dispatch]
  );
  const resetConfigurationToFacadeCladding = useCallback(
    () =>
      dispatch(
        ventilatedWallSlice.actions.resetConfigurationToFacadeCladding()
      ),
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration: VentilatedWallConfigurationState) =>
      dispatch(ventilatedWallSlice.actions.setConfiguration(configuration)),
    [dispatch]
  );

  const setHumanReadableName = useCallback(
    (name: string) =>
      dispatch(ventilatedWallSlice.actions.setHumanReadableName(name)),
    [dispatch]
  );

  const resetConfiguration = useCallback(
    () => dispatch(ventilatedWallSlice.actions.resetConfiguration()),
    [dispatch]
  );

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(ventilatedWallSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  );

  const addScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(ventilatedWallSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  );

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(ventilatedWallSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  );

  return {
    ventilatedWallState,
    addScreenshot,
    setOverviewScreenshot,
    ventilatedWallScreenshots,
    thermalPerformance,
    setThermalPerformance,
    downloadState,
    configurationState,
    setVentilatedWallWallType,
    setVentilatedWallFacadeCladdingType,
    setVentilatedWallInsulationType,
    setVentilatedWallWallTypeMaterial,
    setVentilatedWallFacadeCladdingMaterial,
    setVentilatedWallInsulationMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToWallType,
    resetConfigurationToFacadeCladding,
    resetConfiguration,
  };
}
