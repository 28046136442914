import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useTabs } from "./tabs.hook";

export type TabProps = {
  step: number;
  text: string;
  active: boolean;
  visited: boolean;
  onClick: () => void;
};

export type Tab = {
  component: FunctionComponent<TabProps>;
} & TabProps;

const Wrapper = styled.div`
  height: 100%;

  @media screen and (pointer: coarse) {
    width: 100%;
    position: relative;

    &::before,
    &::after {
      z-index: 1;
      content: "";
      top: 0;
      position: absolute;
      height: 100%;
      width: 15%;
      pointer-events: none;
    }

    &::before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (pointer: coarse) {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    overflow-x: auto;
    gap: 32px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0;
    }

    button {
      width: auto;

      &:first-of-type {
        margin-left: 16px;
      }

      div {
        min-height: 60px;
        padding: 0 16px;
      }

      p {
        white-space: nowrap;
      }
    }

    * {
      font-size: 15px;
      line-height: 18px;
    }
  }
`;

export const Tabs = () => {
  const { tabs } = useTabs();

  if (tabs.find((tab) => tab.active)) {
    return (
      <Wrapper>
        <Container className={"tabs"}>
          {tabs.map(
            ({
              step,
              text,
              active,
              visited,
              component: Component,
              onClick,
            }: Tab) => (
              <Component
                step={step}
                onClick={onClick}
                key={step}
                text={text}
                active={active}
                visited={visited}
              />
            )
          )}
        </Container>
      </Wrapper>
    );
  } else {
    return null;
  }
};
