import { VentilatedWallLayers } from "@iko-design-center/shared";
import { MultiSelectOption } from "../../components/common/MultiSelect";

export type DownloadState = {
  technicalFiche: {
    wallType: boolean;
    wallInsulation: boolean;
  };
  DOP: {
    wallType: boolean;
    wallInsulation: boolean;
  };
  thermalCalculation: boolean;
  specifications: boolean;
  BIMFile: boolean;
  screenshots: boolean;
  processingGuideline: boolean;
};

export const initialDownloadState: DownloadState = {
  technicalFiche: {
    wallType: false,
    wallInsulation: false,
  },
  DOP: {
    wallType: false,
    wallInsulation: false,
  },
  thermalCalculation: false,
  specifications: false,
  BIMFile: false,
  screenshots: false,
  processingGuideline: false,
};

export const allSelectedDownloadState: DownloadState = {
  technicalFiche: {
    wallType: true,
    wallInsulation: true,
  },
  DOP: {
    wallType: true,
    wallInsulation: true,
  },
  thermalCalculation: true,
  specifications: true,
  BIMFile: true,
  screenshots: true,
  processingGuideline: true,
};

export type LayerState = {
  [key in VentilatedWallLayers]: boolean;
};

export function getLayerOptions({
  t,
  state,
}: {
  t: any;
  state: LayerState;
}): MultiSelectOption<VentilatedWallLayers>[] {
  return [
    {
      label: t("modal.download.insulation"),
      value: VentilatedWallLayers.wallInsulation,
      checked: state.wallInsulation,
    },
  ];
}
