import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { styles } from "../../../styles";
import { Dropdown } from "../../common/Dropdown";
import { ResetIcon } from "../../common/Icons";
import { InfoList } from "../../common/InfoList";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useSecuringMethods } from "./securingMethods.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SecuringMethods = () => {
  const {
    t,
    handleNextButtonClick,
    nextButtonDisabled,
    topLayer,
    bottomLayer,
    insulation,
    vaporShield,
    topLayerOptions,
    bottomLayerOptions,
    insulationOptions,
    vaporShieldOptions,
    setSecuringMethodTopLayer,
    setSecuringMethodBottomLayer,
    setSecuringMethodInsulation,
    setSecuringMethodVaporShield,
    reset,
    displayResetButton,
  } = useSecuringMethods();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonDisabled={nextButtonDisabled}
      nextButtonText={`${t("flatRoof.securingMethods.nextButton")} (${currentStep + 1}/${totalSteps})`}
    >
      <Intro>
        <H1>{t("flatRoof.securingMethods.title")}</H1>
        {!isMobile() && <Spacer heightPx={48} />}
      </Intro>
      <RadioButtonList>
        <Dropdown
          selectedValue={topLayer}
          setSelectedValue={setSecuringMethodTopLayer}
          label={t("flatRoof.securingMethods.topLayer")}
          helperLabel={t("flatRoof.securingMethods.topLayerDropdownHelper")}
          options={topLayerOptions}
        />
        <Spacer heightPx={!isMobile() ? 24 : 0} />
        <Dropdown
          selectedValue={bottomLayer}
          setSelectedValue={setSecuringMethodBottomLayer}
          label={t("flatRoof.securingMethods.bottomLayer")}
          helperLabel={t("flatRoof.securingMethods.bottomLayerDropdownHelper")}
          options={bottomLayerOptions}
        />
        <Spacer heightPx={!isMobile() ? 24 : 0} />
        <Dropdown
          selectedValue={insulation}
          setSelectedValue={setSecuringMethodInsulation}
          label={t("flatRoof.securingMethods.insulation")}
          helperLabel={t("flatRoof.securingMethods.insulationDropdownHelper")}
          options={insulationOptions}
        />
        <Spacer heightPx={!isMobile() ? 24 : 0} />
        <Dropdown
          selectedValue={vaporShield}
          setSelectedValue={setSecuringMethodVaporShield}
          label={t("flatRoof.securingMethods.vaporShield")}
          helperLabel={t("flatRoof.securingMethods.vaporShieldDropdownHelper")}
          options={vaporShieldOptions}
        />
        {!isMobile() && <Spacer heightPx={16} />}
        {displayResetButton && <ResetButton onClick={reset} />}
        <Spacer heightPx={!isMobile() ? 40 : 24} />
        <InfoList
          title={t("flatRoof.securingMethods.moreInfo")}
          info={[
            t("flatRoof.securingMethods.info0"),
            t("flatRoof.securingMethods.info1"),
            t("flatRoof.securingMethods.info2"),
          ]}
        />
      </RadioButtonList>
    </Sidebar>
  );
};

const ResetContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (pointer: coarse) {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Button = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  > p {
    text-decoration: underline;
  }
`;

const ResetButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <ResetContainer>
      <Button onClick={onClick}>
        <P fontSizePx={14} lineHeightPx={22} color={styles.colors.darkBlue}>
          {t("flatRoof.securingMethods.resetButton")}
        </P>
        <Spacer widthPx={!isMobile() ? 10 : 24} />
        <ResetIcon />
      </Button>
    </ResetContainer>
  );
};
