import { FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useApplication } from "../../store/application/hooks"
import { ApplicationTypes } from "../../store/application/store"
import {
  AtticFloorIcon,
  AtticSlopedRoofIcon,
  BasementIcon,
  CavityWallIcon,
  FlatRoofIcon,
  FloorIcon,
  InnerWallIcon,
  OuterWallIcon,
  SloperRoofIcon,
} from "../common/Icons"
import { renderer } from "../viewer/Renderer"
import { Models } from "../viewer/renderer.api"
import {
  SlopedRoofInsulationTypeTypes,
  VentilatedWallInsulationTypes,
  FloorInsulationTypes,
} from "@iko-design-center/shared"
import { useRoutes } from "../../store/routes/hooks"
import i18n from "../../translations/i18n"

export type ApplicationOption = {
  type: ApplicationTypes
  translationKey: string
  icon: FunctionComponent<{ lineColor: string }>
  rendererCall?: () => void
  disabled: boolean
}

const applicationOptionsList: any = [
  {
    type: ApplicationTypes.FLAT_ROOF,
    translationKey: "home.flatRoof",
    icon: FlatRoofIcon,
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF),
  },
  {
    type: ApplicationTypes.SLOPED_ROOF,
    translationKey: "home.slopedRoof",
    icon: SloperRoofIcon,
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF),
  },
  {
    type: ApplicationTypes.CAVITY_WALL,
    translationKey: "home.cavityWall",
    icon: CavityWallIcon,
    rendererCall: () => renderer.displayModel(Models.CAVITY_WALL),
  },
  {
    type: ApplicationTypes.OUTER_WALL,
    translationKey: "home.outerWall",
    icon: OuterWallIcon,
    rendererCall: () => renderer.displayModel(Models.VENTILATED_WALL),
  },
  {
    type: ApplicationTypes.FLOOR,
    translationKey: "home.floor",
    icon: FloorIcon,
    rendererCall: () => renderer.displayModel(Models.FLOOR),
  },
  {
    type: ApplicationTypes.SOL,
    translationKey: "home.sol",
    icon: FloorIcon,
    rendererCall: () => renderer.displayModel(Models.SOL_SOUSDALLAGE),
  },
  {
    type: ApplicationTypes.INNER_WALL,
    translationKey: "home.innerWall",
    icon: InnerWallIcon,
  },
  {
    type: ApplicationTypes.ATTIC_FLOOR,
    translationKey: "home.atticFloor",
    icon: AtticFloorIcon,
  },
  {
    type: ApplicationTypes.BASEMENT,
    translationKey: "home.basement",
    icon: BasementIcon,
  },
  {
    type: ApplicationTypes.ATTIC_SLOPED_ROOF,
    translationKey: "home.atticSlopedRoof",
    icon: AtticSlopedRoofIcon,
  },
]

export const applicationsPerLanguage: any = {
  nl_be: [
    { type: ApplicationTypes.FLAT_ROOF, enabled: true },
    { type: ApplicationTypes.SLOPED_ROOF, enabled: true },
    { type: ApplicationTypes.CAVITY_WALL, enabled: true },
    { type: ApplicationTypes.OUTER_WALL, enabled: true },
    { type: ApplicationTypes.FLOOR, enabled: true },
    { type: ApplicationTypes.INNER_WALL, enabled: false },
  ],
  fr_be: [
    { type: ApplicationTypes.FLAT_ROOF, enabled: true },
    { type: ApplicationTypes.SLOPED_ROOF, enabled: true },
    { type: ApplicationTypes.CAVITY_WALL, enabled: true },
    { type: ApplicationTypes.OUTER_WALL, enabled: true },
    { type: ApplicationTypes.FLOOR, enabled: true },
    { type: ApplicationTypes.INNER_WALL, enabled: false },
  ],
  nl_nl: [
    { type: ApplicationTypes.FLAT_ROOF, enabled: true },
    { type: ApplicationTypes.SLOPED_ROOF, enabled: true },
    { type: ApplicationTypes.CAVITY_WALL, enabled: true },
    { type: ApplicationTypes.OUTER_WALL, enabled: true },
    { type: ApplicationTypes.FLOOR, enabled: true },
    { type: ApplicationTypes.INNER_WALL, enabled: false },
  ],
  fr_fr: [{ type: ApplicationTypes.SOL, enabled: true }],
}

export function useHome() {
  const history = useHistory()
  const { t } = useTranslation()
  const { Routes } = useRoutes() as any
  const { setApplicationType, applicationType } = useApplication()

  const applicationOptions: ApplicationOption[] = useMemo(() => {
    const options: ApplicationOption[] = []

    applicationsPerLanguage[i18n.language].forEach((application: any) => {
      options.push({
        ...applicationOptionsList.find((a: any) => a.type === application.type),
        disabled: !application.enabled,
      } as any)
    })

    return options
  }, [])

  function handleNextButtonClick() {
    switch (applicationType) {
      // FLAT ROOF
      case ApplicationTypes.FLAT_ROOF:
        history.push(Routes.FLAT_ROOF_1_ROOF_TYPE)
        break
      // CAVITY WALL
      case ApplicationTypes.CAVITY_WALL:
        history.push(Routes.CAVITY_WALL_1_WALL_TYPE)
        break
      // SLOPED ROOF
      case ApplicationTypes.SLOPED_ROOF:
        history.push(Routes.SLOPED_ROOF_1_ROOF_TYPE)
        break
      // VENTILATED WALL
      case ApplicationTypes.OUTER_WALL:
        history.push(Routes.VENTILATED_WALL_1_WALL_TYPE)
        break
      // FLOOR
      case ApplicationTypes.FLOOR:
        history.push(Routes.FLOOR_1_FLOOR_HEATING)
        break
      // SOL
      case ApplicationTypes.SOL:
        history.push(Routes.SOL_1_INSULATION)
        break
      default:
        history.push(Routes.HOME)
        break
    }
  }

  function handleClick(type: ApplicationTypes) {
    const config = applicationOptions.find((c) => c.type === type)

    if (!config!.disabled) {
      setApplicationType(type)
      renderer.hideMarkers()

      if (config!.rendererCall) {
        config!.rendererCall()

        switch (type) {
          // FLAT ROOF
          case ApplicationTypes.FLAT_ROOF:
            renderer.displayFlatRoofInsulation("ISOLATIE-ALU")
            break
          // CAVITY WALL
          case ApplicationTypes.CAVITY_WALL:
            break
          // SLOPED ROOF
          case ApplicationTypes.SLOPED_ROOF:
            renderer.displaySlopedRoofInsulation(
              SlopedRoofInsulationTypeTypes.ALU_NF
            )
            break
          // VENTILATED WALL
          case ApplicationTypes.OUTER_WALL:
            renderer.displayVentilatedWallInsulation(
              VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE
            )
            break
          // FLOOR
          case ApplicationTypes.FLOOR:
            renderer.displayFloorInsulationType(
              FloorInsulationTypes.ENERTHERM_ALU
            )
            break
          // SOL
          case ApplicationTypes.SOL:
            break
          default:
            break
        }
      }
    }
  }

  return {
    handleClick,
    applicationType,
    applicationOptions,
    handleNextButtonClick,
    t,
  }
}
