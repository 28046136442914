import { VentilatedWallConfigurationState } from "@iko-design-center/shared";
import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useApplication } from "../../../store/application/hooks";
import { ApplicationTypes } from "../../../store/application/store";
import { Home } from "../Home";
import { useVentilatedWall } from "../../../store/ventilated-wall/hooks";
import { useRoutes } from "../../../store/routes/hooks";

export const Guard = (props: { children: ReactNode }) => {
  return (
    <>
      <ResetStateGuard>
        <ValidStateGuard>{props.children}</ValidStateGuard>
      </ResetStateGuard>
    </>
  );
};

// This makes sure, that when a user navigates backwards,
// either via the Navbar or via the browser back button,
// the state is reset. This so the user can reselect any options.
const ResetStateGuard = (props: { children: ReactNode }) => {
  const {
    resetConfiguration,
    resetConfigurationToWallType,
    resetConfigurationToFacadeCladding,
  } = useVentilatedWall();
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const routeHome = Routes.HOME;
  const routeVentilatedWallWallType = Routes.VENTILATED_WALL_1_WALL_TYPE;
  const routeVentilatedWallFacadeCladding = Routes.VENTILATED_WALL_2_FACADE_CLADDING;
  const routeVentilatedWallOverview = Routes.VENTILATED_WALL_3_OVERVIEW;

  useEffect(() => {
    switch (location.pathname) {
      case routeHome:
        resetConfiguration();
        break;
      case routeVentilatedWallWallType:
        resetConfigurationToWallType();
        break;
      case routeVentilatedWallFacadeCladding:
        resetConfigurationToFacadeCladding();
        break;
      case routeVentilatedWallOverview:
        break;
    }
  }, [
    resetConfiguration,
    resetConfigurationToWallType,
    resetConfigurationToFacadeCladding,
    location.pathname,
    routeHome,
    routeVentilatedWallWallType,
    routeVentilatedWallFacadeCladding,
    routeVentilatedWallOverview,
  ]);

  return <>{props.children}</>;
};

// When a user refreshes the screen on let's say the OverviewPage,
// No state will be present in Redux, thus 'cannot read of undefined's will pop up.
// This prevents that.
const ValidStateGuard = (props: { children: ReactNode }) => {
  const { directLink, setDirectLink, setApplicationType, applicationType } = useApplication();
  const location = useLocation();
  const { configurationState } = useVentilatedWall();
  const { Routes } = useRoutes() as any;
  const history = useHistory();

  const valid = checkValidStateForRoute(
    applicationType,
    configurationState,
    location.pathname,
    Routes
  );

  useEffect(() => {
    if (!valid) {
      if (!directLink) {
        setDirectLink(true)
        setApplicationType(ApplicationTypes.OUTER_WALL);
        history.push(Routes.VENTILATED_WALL_1_WALL_TYPE);
      } else {
        history.push(Routes.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, setApplicationType, history, setDirectLink, directLink])

  return valid ? <>{props.children}</> : <Home />;
};

function checkValidStateForRoute(
  applicationType: ApplicationTypes | null,
  configurationState: VentilatedWallConfigurationState,
  route: string,
  Routes: any
): boolean | undefined {
  const { wallType, wallInsulation, facadeCladding } = configurationState;

  const applicationTypeValid = applicationType !== null;
  const wallTypeValid = wallType.type !== null;
  const wallInsulationValid = wallInsulation.type !== null;
  const facadeCladdingValid = facadeCladding.type !== null;

  switch (route) {
    case Routes.VENTILATED_WALL_3_OVERVIEW:
      return wallTypeValid && wallInsulationValid && facadeCladdingValid;
    case Routes.VENTILATED_WALL_2_FACADE_CLADDING:
      return wallTypeValid && wallInsulationValid;
    case Routes.VENTILATED_WALL_1_WALL_TYPE:
      return applicationTypeValid;
    case Routes.HOME:
      return true;
  }
}
