import { useTranslation } from "react-i18next"

export function useRoutes() {
  const { t } = useTranslation() as any

  enum Routes {
    HOME = t("routes.HOME"),
    PREVIEW = t("routes.PREVIEW"),

    // FLAT ROOF
    FLAT_ROOF_1_ROOF_TYPE = t("routes.FLAT_ROOF_1_ROOF_TYPE"),
    FLAT_ROOF_2_ROOF_FLOOR = t("routes.FLAT_ROOF_2_ROOF_FLOOR"),
    FLAT_ROOF_3_WATERPROOFING = t("routes.FLAT_ROOF_3_WATERPROOFING"),
    FLAT_ROOF_4_SECURING_METHODS = t("routes.FLAT_ROOF_4_SECURING_METHODS"),
    FLAT_ROOF_5_OVERVIEW = t("routes.FLAT_ROOF_5_OVERVIEW"),

    // CAVITY WALL
    CAVITY_WALL_1_WALL_TYPE = t("routes.CAVITY_WALL_1_WALL_TYPE"),
    CAVITY_WALL_2_WALL_INSULATION = t("routes.CAVITY_WALL_2_WALL_INSULATION"),
    CAVITY_WALL_3_OVERVIEW = t("routes.CAVITY_WALL_3_OVERVIEW"),

    // SLOPED ROOF
    SLOPED_ROOF_1_ROOF_TYPE = t("routes.SLOPED_ROOF_1_ROOF_TYPE"),
    SLOPED_ROOF_2_ROOF_INSULATION = t("routes.SLOPED_ROOF_2_ROOF_INSULATION"),
    SLOPED_ROOF_3_OVERVIEW = t("routes.SLOPED_ROOF_3_OVERVIEW"),

    // VENTILATED WALL
    VENTILATED_WALL_1_WALL_TYPE = t("routes.VENTILATED_WALL_1_WALL_TYPE"),
    VENTILATED_WALL_2_FACADE_CLADDING = t("routes.VENTILATED_WALL_2_FACADE_CLADDING"),
    VENTILATED_WALL_3_OVERVIEW = t("routes.VENTILATED_WALL_3_OVERVIEW"),

    // FLOOR
    FLOOR_1_FLOOR_HEATING = t("routes.FLOOR_1_FLOOR_HEATING"),
    FLOOR_2_FLOOR_INSULATION = t("routes.FLOOR_2_FLOOR_INSULATION"),
    FLOOR_3_FLOOR_SCREED = t("routes.FLOOR_3_FLOOR_SCREED"),
    FLOOR_4_OVERVIEW = t("routes.FLOOR_4_OVERVIEW"),

    // SOL
    SOL_1_INSULATION = t("routes.SOL_1_INSULATION"),
    SOL_2_NOISE_LEVEL = t("routes.SOL_2_NOISE_LEVEL"),
    SOL_3_ACOUSTIC_LAYER = t("routes.SOL_3_ACOUSTIC_LAYER"),
    SOL_2_CONFORMITY = t("routes.SOL_2_CONFORMITY"),
    SOL_3_BUILDING_TYPE = t("routes.SOL_3_BUILDING_TYPE"),
    SOL_4_OVERVIEW = t("routes.SOL_4_OVERVIEW"),
  }

  return { Routes }
}
