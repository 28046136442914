import { configureStore } from "@reduxjs/toolkit"
import { applicationSlice, ApplicationState } from "./application/store"

import { modalSlice, ModalState } from "./modal/store"
import { toastSlice, ToastState } from "./toast/store"

// FLAT ROOF
import { FlatRoofState } from "./flat-roof/state"
import { flatRoofSlice } from "./flat-roof/store"

// CAVITY WALL
import { CavityWallState } from "./cavity-wall/state"
import { cavityWallSlice } from "./cavity-wall/store"

// SLOPED ROOF
import { SlopedRoofState } from "./sloped-roof/state"
import { slopedRoofSlice } from "./sloped-roof/store"

// VENTILATED WALL
import { VentilatedWallState } from "./ventilated-wall/state"
import { ventilatedWallSlice } from "./ventilated-wall/store"

// FLOOR
import { FloorState } from "./floor/state"
import { floorSlice } from "./floor/store"

// SOL
import { SolState } from "./sol/state"
import { solSlice } from "./sol/store"

const storeOptions = {
  reducer: {
    toast: toastSlice.reducer,
    modal: modalSlice.reducer,
    flatRoof: flatRoofSlice.reducer,
    cavityWall: cavityWallSlice.reducer,
    slopedRoof: slopedRoofSlice.reducer,
    ventilatedWall: ventilatedWallSlice.reducer,
    floor: floorSlice.reducer,
    sol: solSlice.reducer,
    application: applicationSlice.reducer,
  },
  middleware:
    process.env.NODE_ENV === "development"
      ? []
      : (getDefaultMiddleware: any) => getDefaultMiddleware(),
}

export const store = configureStore(storeOptions)

export type AppState = {
  toast: ToastState
  modal: ModalState
  flatRoof: FlatRoofState
  cavityWall: CavityWallState
  slopedRoof: SlopedRoofState
  ventilatedWall: VentilatedWallState
  floor: FloorState
  sol: SolState
  application: ApplicationState
}

export type AppDispatch = typeof store.dispatch
