import React from "react";
import { styles } from "../../styles";
import { Spacer } from "../common/Spacer";
import { H1, H2, P } from "../common/Typography";
import { Grid } from "./Grid";
import { useHome } from "./home.hooks";
import { Tile } from "./HomeTile";
import { Sidebar } from "./Sidebar";
import { isMobile } from "../../services/helpers.service";

const Home = () => {
  const { t, handleNextButtonClick, applicationType, applicationOptions, handleClick } = useHome();
  const activatedApplicationsTypes: any = applicationOptions
    .filter((app) => !app.disabled)
    .map((app) => app.type);

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={t("home.button")}
      nextButtonDisabled={!activatedApplicationsTypes.includes(applicationType)}
    >
      {!isMobile() && (
        <>
          <H1>{t("home.title")}</H1>
          <Spacer heightPx={12} />
          <P lineHeightPx={22}>{t("home.subtitle")}</P>
          <Spacer heightPx={isMobile() ? 12 : 32} />
          <H2>{t("home.chooseApplication")}</H2>
          <Spacer heightPx={16} />
        </>
      )}
      <Grid rows={3} gaps={true} className={"application-grid"}>
        {applicationOptions.map(
          ({ translationKey, icon: Icon, type, disabled }) => (
            <Tile
              value={type}
              key={type}
              image={<Icon lineColor={styles.colors.dark2} />}
              translationKey={translationKey}
              handleClick={handleClick}
              selected={applicationType === type}
              disabled={disabled}
            />
          )
        )}
      </Grid>
    </Sidebar>
  );
};

export { Home };
