import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSol } from "../../../store/sol/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"
import {
  SolAcousticLayerTypes,
  SolNoiseLevelTypes,
} from "@iko-design-center/shared"

export function useSolNoiseLevel() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setSolNoiseLevelType: setSolNoiseLevelTypeState,
  } = useSol()

  const solNoiseLevelType = configurationState.solNoiseLevel.type
  const nextButtonDisabled = !solNoiseLevelType

  function handleNextButtonClick() {
    history.push(Routes.SOL_3_ACOUSTIC_LAYER)
  }

  function setSolNoiseLevelType(type: any) {
    setSolNoiseLevelTypeState(type)
    switch (type) {
      case SolNoiseLevelTypes.DB_18:
      case SolNoiseLevelTypes.DB_19:
      case SolNoiseLevelTypes.DB_20:
      case SolNoiseLevelTypes.AUCUNE:
        renderer.displaySolAcousticLayerType(SolAcousticLayerTypes.AUCUNE)
        break
      case SolNoiseLevelTypes.DB_22:
        renderer.displaySolAcousticLayerType(
          SolAcousticLayerTypes.ASSOURCHAPE_20
        )
        break
      case SolNoiseLevelTypes.DB_23:
        renderer.displaySolAcousticLayerType(
          SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO
        )
        break
    }
  }

  return {
    t,
    solNoiseLevelType,
    setSolNoiseLevelType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
