import {
  BottomLayerMaterialIDs,
  BottomLayerSecuringMethodTypes,
  FlatRoofConfigurationState,
  InsulationMaterialIDs,
  InsulationSecuringMethodTypes,
  PossibleConfiguration,
  TopLayerMaterialIDs,
  TopLayerSecuringMethodTypes,
  VaporShieldMaterialIDs,
  VaporShieldSecuringMethodTypes,
} from "@iko-design-center/shared"
import { uniq } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { DropdownOption } from "../../common/Dropdown"
import { renderer } from "../../viewer/Renderer"
import { getSecuringMethodTranslation } from "./securingMethodTranslations"
import { useRoutes } from "../../../store/routes/hooks"

export function useSecuringMethods() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation()
  const history = useHistory()
  const {
    possibleConfigurations,
    configurationState,
    setSecuringMethodTopLayer: setStoreSecuringMethodTopLayer,
    setSecuringMethodBottomLayer: setStoreSecuringMethodBottomLayer,
    setSecuringMethodInsulation: setStoreSecuringMethodInsulation,
    setSecuringMethodVaporShield: setStoreSecuringMethodVaporShield,
    setConfiguration,
    flatRoofState,
    resetConfigurationToSecuringMethods,
  } = useFlatRoof()
  const {
    topLayer: stateTopLayer,
    bottomLayer: stateBottomLayer,
    insulation: stateInsulation,
    vaporShield: stateVaporShield,
  } = configurationState.securingMethods

  const [displayResetButton, setDisplayResetButton] = useState<boolean>(false)
  const [defaultConfig, setDefaultConfig] = useState<PossibleConfiguration>()
  // When navigating backwards from the overview page,
  // We need to reset the state to allow for all possible configurations.
  // I.e., all roofStructure materials needs to be set to null, and securing methods as well.
  // This happens asynchronously.
  // When clicking the back button, the Guard on Router.tsx resets the state.
  // However, this component is loaded before that happens.
  // Therefore, we implement this loading state.
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const {
      topLayerMaterial,
      bottomLayerMaterial,
      vaporShieldMaterial,
      insulationMaterial,
      roofFloorMaterial,
    } = flatRoofState.configuration.roofStructure
    const {
      topLayer,
      bottomLayer,
      insulation,
      vaporShield,
    } = flatRoofState.configuration.securingMethods

    const validInitialState =
      topLayerMaterial === null &&
      bottomLayerMaterial === null &&
      vaporShieldMaterial === null &&
      insulationMaterial === null &&
      roofFloorMaterial === null &&
      topLayer === null &&
      bottomLayer === null &&
      insulation === null &&
      vaporShield === null

    if (!loaded && validInitialState) {
      setLoaded(true)
    }
  }, [possibleConfigurations, loaded, setLoaded, flatRoofState])

  useEffect(
    () => {
      if (loaded) {
        setDefaultConfig(
          possibleConfigurations.find((config) => config.default)
        )
        setDisplayResetButton(possibleConfigurations.length > 1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded]
  )

  const renderConfig = useCallback((config: FlatRoofConfigurationState) => {
    renderer.displayTopLayerMaterial(
      config.roofStructure.topLayerMaterial!.id as TopLayerMaterialIDs
    )
    renderer.displayBottomLayerMaterial(
      config.roofStructure.bottomLayerMaterial!.id as BottomLayerMaterialIDs
    )
    renderer.displayInsulationMaterial(
      config.roofStructure.insulationMaterial!.id as InsulationMaterialIDs
    )
    renderer.displayVaporShieldMaterial(
      config.roofStructure.vaporShieldMaterial!.id as VaporShieldMaterialIDs
    )
  }, [])

  useEffect(() => {
    if (defaultConfig) {
      renderConfig(defaultConfig)
    }
  }, [defaultConfig, renderConfig])

  const setSecuringMethodTopLayer = useCallback(
    (topLayer: TopLayerSecuringMethodTypes) => {
      renderer.displayTopLayerSecuringMethod(topLayer)
      setStoreSecuringMethodTopLayer(topLayer)
    },
    [setStoreSecuringMethodTopLayer]
  )

  const setSecuringMethodBottomLayer = useCallback(
    (bottomLayer: BottomLayerSecuringMethodTypes) => {
      renderer.displayBottomLayerSecuringMethod(bottomLayer)
      setStoreSecuringMethodBottomLayer(bottomLayer)
    },
    [setStoreSecuringMethodBottomLayer]
  )

  const setSecuringMethodInsulation = useCallback(
    (insulation: InsulationSecuringMethodTypes) => {
      renderer.displayInsulationSecuringMethod(insulation)
      setStoreSecuringMethodInsulation(insulation)
    },
    [setStoreSecuringMethodInsulation]
  )

  const setSecuringMethodVaporShield = useCallback(
    (vaporShield: VaporShieldSecuringMethodTypes) => {
      renderer.displayVaporShieldSecuringMethod(vaporShield)
      setStoreSecuringMethodVaporShield(vaporShield)
    },
    [setStoreSecuringMethodVaporShield]
  )

  const topLayerOptions = getSecuringMethodDropdownOptions<TopLayerSecuringMethodTypes>(
    t,
    possibleConfigurations,
    "topLayer"
  )
  const bottomLayerOptions = getSecuringMethodDropdownOptions<BottomLayerSecuringMethodTypes>(
    t,
    possibleConfigurations,
    "bottomLayer"
  )
  const insulationOptions = getSecuringMethodDropdownOptions<InsulationSecuringMethodTypes>(
    t,
    possibleConfigurations,
    "insulation"
  )
  const vaporShieldOptions = getSecuringMethodDropdownOptions<VaporShieldSecuringMethodTypes>(
    t,
    possibleConfigurations,
    "vaporShield"
  )

  // This could also be placed in Redux, and probably has better performance that way.
  // However, I do like that all logic is centralized here.
  useEffect(() => {
    if (topLayerOptions.length === 1) {
      const configTopLayer = topLayerOptions[0].value
      if (stateTopLayer !== configTopLayer)
        setSecuringMethodTopLayer(configTopLayer)
    } else if (defaultConfig && defaultConfig.securingMethods.topLayer) {
      renderer.displayTopLayerSecuringMethod(
        defaultConfig.securingMethods.topLayer
      )
    }

    if (bottomLayerOptions.length === 1) {
      const configBottomLayer = bottomLayerOptions[0].value
      if (stateBottomLayer !== configBottomLayer)
        setSecuringMethodBottomLayer(configBottomLayer)
    } else if (defaultConfig && defaultConfig.securingMethods.bottomLayer) {
      renderer.displayBottomLayerSecuringMethod(
        defaultConfig.securingMethods.bottomLayer
      )
    }

    if (insulationOptions.length === 1) {
      const configInsulation = insulationOptions[0].value
      if (stateInsulation !== configInsulation)
        setSecuringMethodInsulation(configInsulation)
    } else if (defaultConfig && defaultConfig.securingMethods.insulation) {
      renderer.displayInsulationSecuringMethod(
        defaultConfig.securingMethods.insulation
      )
    }

    if (vaporShieldOptions.length === 1) {
      const configVaporShield = vaporShieldOptions[0].value
      if (stateVaporShield !== configVaporShield)
        setSecuringMethodVaporShield(configVaporShield)
    } else if (defaultConfig && defaultConfig.securingMethods.vaporShield) {
      renderer.displayVaporShieldSecuringMethod(
        defaultConfig.securingMethods.vaporShield
      )
    }

    setDefaultConfig(possibleConfigurations.find((config) => config.default))
  }, [
    defaultConfig,
    stateTopLayer,
    stateBottomLayer,
    stateInsulation,
    stateVaporShield,
    topLayerOptions,
    bottomLayerOptions,
    insulationOptions,
    vaporShieldOptions,
    setSecuringMethodTopLayer,
    setSecuringMethodBottomLayer,
    setSecuringMethodInsulation,
    setSecuringMethodVaporShield,
    possibleConfigurations,
  ])

  useEffect(() => {
    if (possibleConfigurations.length === 1) {
      const finalPossibleConfig = possibleConfigurations[0]
      const copy = JSON.parse(JSON.stringify(finalPossibleConfig))
      delete copy.default
      const config = copy as FlatRoofConfigurationState
      renderConfig(config)
      setConfiguration(config)
    }
  }, [possibleConfigurations, renderConfig, setConfiguration])

  const topLayer =
    stateTopLayer ?? defaultConfig?.securingMethods.topLayer ?? null
  const bottomLayer =
    stateBottomLayer ?? defaultConfig?.securingMethods.bottomLayer ?? null
  const insulation =
    stateInsulation ?? defaultConfig?.securingMethods.insulation ?? null
  const vaporShield =
    stateVaporShield ?? defaultConfig?.securingMethods.vaporShield ?? null

  const nextButtonDisabled =
    !topLayer || !bottomLayer || !insulation || !vaporShield

  function handleNextButtonClick() {
    if (possibleConfigurations.length !== 1 && !defaultConfig) {
      throw Error(
        `More than one configuration remaining, and no default config available. ${flatRoofState}`
      )
    }
    if (possibleConfigurations.length !== 1 && defaultConfig) {
      setConfiguration(defaultConfig)
    }
    history.push(Routes.FLAT_ROOF_5_OVERVIEW)
  }

  function reset() {
    if (defaultConfig) {
      renderConfig(defaultConfig)
    }
    resetConfigurationToSecuringMethods()
  }

  return {
    t,
    topLayer,
    bottomLayer,
    insulation,
    vaporShield,
    topLayerOptions,
    bottomLayerOptions,
    insulationOptions,
    vaporShieldOptions,
    nextButtonDisabled,
    handleNextButtonClick,
    reset,
    setSecuringMethodTopLayer,
    setSecuringMethodBottomLayer,
    setSecuringMethodInsulation,
    setSecuringMethodVaporShield,
    displayResetButton,
  }
}

function getSecuringMethodDropdownOptions<T>(
  t: any,
  possibleConfigurations: PossibleConfiguration[],
  layer: keyof FlatRoofConfigurationState["securingMethods"]
): DropdownOption<T>[] {
  //@ts-ignore
  const availableSecuringMethods: T[] = uniq(
    possibleConfigurations.map((config) => config.securingMethods[layer]!)
  )

  return availableSecuringMethods.map(
    (type) =>
      ({
        value: type,
        // @ts-ignore
        label: getSecuringMethodTranslation(t, type),
      } as DropdownOption<T>)
  )
}
