import { FlatRoofConfigurationState } from "@iko-design-center/shared";
import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useApplication } from "../../../store/application/hooks";
import { ApplicationTypes } from "../../../store/application/store";
import { Home } from "../Home";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { useRoutes } from "../../../store/routes/hooks";

export const Guard = (props: { children: ReactNode }) => {
  return (
    <>
      <ResetStateGuard>
        <ValidStateGuard>{props.children}</ValidStateGuard>
      </ResetStateGuard>
    </>
  );
};

// This makes sure, that when a user navigates backwards,
// either via the Navbar or via the browser back button,
// the state is reset. This so the user can reselect any options.
const ResetStateGuard = (props: { children: ReactNode }) => {
  const {
    resetConfiguration,
    resetConfigurationToRoofType,
    resetConfigurationToRoofFloor,
    resetConfigurationToWaterproofing,
    resetConfigurationToSecuringMethods,
  } = useFlatRoof();
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const routeHome = Routes.HOME;
  const routeFlatRoofRoofType = Routes.FLAT_ROOF_1_ROOF_TYPE;
  const routeFlatRoofRoofFloor = Routes.FLAT_ROOF_2_ROOF_FLOOR;
  const routeFlatRoofWaterproofing = Routes.FLAT_ROOF_3_WATERPROOFING;
  const routeFlatRoofSecuringMethods = Routes.FLAT_ROOF_4_SECURING_METHODS;
  const routeFlatRoofOverview = Routes.FLAT_ROOF_5_OVERVIEW;

  useEffect(() => {
    switch (location.pathname) {
      case routeHome:
        resetConfiguration();
        break;
      case routeFlatRoofRoofType:
        resetConfigurationToRoofType();
        break;
      case routeFlatRoofRoofFloor:
        resetConfigurationToRoofFloor();
        break;
      case routeFlatRoofWaterproofing:
        resetConfigurationToWaterproofing();
        break;
      case routeFlatRoofSecuringMethods:
        resetConfigurationToSecuringMethods();
        break;
      case routeFlatRoofOverview:
        break;
    }
  }, [
    resetConfiguration,
    resetConfigurationToRoofType,
    resetConfigurationToRoofFloor,
    resetConfigurationToWaterproofing,
    resetConfigurationToSecuringMethods,
    location.pathname,
    routeHome,
    routeFlatRoofRoofType,
    routeFlatRoofRoofFloor,
    routeFlatRoofWaterproofing,
    routeFlatRoofSecuringMethods,
    routeFlatRoofOverview,
  ]);

  return <>{props.children}</>;
};

// When a user refreshes the screen on let's say the OverviewPage,
// No state will be present in Redux, thus 'cannot read of undefined's will pop up.
// This prevents that.
const ValidStateGuard = (props: { children: ReactNode }) => {
  const { directLink, setDirectLink, setApplicationType, applicationType } = useApplication();
  const location = useLocation();
  const { configurationState } = useFlatRoof();
  const { Routes } = useRoutes() as any;
  const history = useHistory();

  const valid = checkValidStateForRoute(
    applicationType,
    configurationState,
    location.pathname,
    Routes
  );

  useEffect(() => {
    if (!valid) {
      if (!directLink) {
        setDirectLink(true)
        setApplicationType(ApplicationTypes.FLAT_ROOF);
        history.push(Routes.FLAT_ROOF_1_ROOF_TYPE);
      } else {
        history.push(Routes.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, setApplicationType, history, setDirectLink, directLink])

  return valid ? <>{props.children}</> : <Home />;
};

function checkValidStateForRoute(
  applicationType: ApplicationTypes | null,
  configurationState: FlatRoofConfigurationState,
  route: string,
  Routes: any
): boolean | undefined {
  const {
    roofStructure,
    securingMethods,
    waterproofing,
    roofFloor,
    roofType,
  } = configurationState;

  const applicationTypeValid = applicationType !== null;
  const roofStructureValid =
    roofStructure.topLayerMaterial !== null &&
    roofStructure.bottomLayerMaterial !== null &&
    roofStructure.insulationMaterial !== null &&
    roofStructure.vaporShieldMaterial !== null &&
    roofStructure.roofFloorMaterial !== null;
  const securingMethodsValid =
    securingMethods.topLayer !== null &&
    securingMethods.bottomLayer !== null &&
    securingMethods.insulation !== null &&
    securingMethods.vaporShield !== null;
  const waterproofingValid = waterproofing.type !== null;
  const roofFloorValid = roofFloor.type !== null;
  const roofTypeValid =
    roofType.type !== null && roofType.climateClass !== null;

  switch (route) {
    case Routes.FLAT_ROOF_5_OVERVIEW:
      return (
        roofStructureValid &&
        securingMethodsValid &&
        waterproofingValid &&
        roofFloorValid &&
        roofTypeValid
      );
    case Routes.FLAT_ROOF_4_SECURING_METHODS:
      return waterproofingValid && roofFloorValid && roofTypeValid;
    case Routes.FLAT_ROOF_3_WATERPROOFING:
      return roofFloorValid && roofTypeValid;
    case Routes.FLAT_ROOF_2_ROOF_FLOOR:
      return roofTypeValid;
    case Routes.FLAT_ROOF_1_ROOF_TYPE:
      return applicationTypeValid;
    case Routes.HOME:
      return true;
  }
}
