import { SlopedRoofTypeTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { Grid } from "../Grid";
import { PictureTile } from "../PictureTile";
import { useRoofType } from "./roofType.hooks";
import { Sidebar } from "../Sidebar";
import { getRoofTypeTranslations } from "./translations";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const RoofType = () => {
  const {
    t,
    slopedRoofTypeType,
    setSlopedRoofTypeType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useRoofType();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("slopedRoof.roofType.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("slopedRoof.roofType.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("slopedRoof.roofType.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <Grid rows={2} gaps={true} className={"tiles-grid"}>
        <PictureTile
          src={"/./assets/images/sloped-roof/tiles-texture.jpg"}
          selected={slopedRoofTypeType === SlopedRoofTypeTypes.TILED_ROOF}
          text={getRoofTypeTranslations(t, SlopedRoofTypeTypes.TILED_ROOF)}
          onClick={() => setSlopedRoofTypeType(SlopedRoofTypeTypes.TILED_ROOF)}
        />
        <PictureTile
          src={"/./assets/images/sloped-roof/slats-texture.jpg"}
          selected={slopedRoofTypeType === SlopedRoofTypeTypes.SLATED_ROOF}
          text={getRoofTypeTranslations(t, SlopedRoofTypeTypes.SLATED_ROOF)}
          onClick={() => setSlopedRoofTypeType(SlopedRoofTypeTypes.SLATED_ROOF)}
        />
      </Grid>
    </Sidebar>
  );
};
