import {
  BottomLayerSecuringMethodTypes,
  FEMaterial,
  FlatRoofConfigurationState,
  InsulationSecuringMethodTypes,
  RoofFloorTypes,
  RoofTypeClimateClasses,
  RoofTypeTypes,
  TopLayerSecuringMethodTypes,
  VaporShieldSecuringMethodTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { DownloadState } from "./download";
import { flatRoofSlice } from "./store";

export function useFlatRoof() {
  const dispatch = useAppDispatch();
  const flatRoofState = useAppSelector((state) => state.flatRoof);
  const configurationState = useAppSelector(
    (state) => state.flatRoof.configuration
  );
  const downloadState = useAppSelector((state) => state.flatRoof.download);
  const possibleConfigurations = useAppSelector(
    (state) => state.flatRoof.possibleConfigurations
  );
  const thermalPerformance = useAppSelector(
    (state) => state.flatRoof.thermalPerformance
  );
  const flatRoofScreenshots = useAppSelector((state) => state.flatRoof.screenshots);

  const setRoofTypeType = useCallback(
    (roofTypeType: RoofTypeTypes) =>
      dispatch(flatRoofSlice.actions.setRoofTypeType(roofTypeType)),
    [dispatch]
  );

  const setRoofTypeClimateClass = useCallback(
    (roofTypeClimateClass: RoofTypeClimateClasses) =>
      dispatch(
        flatRoofSlice.actions.setRoofTypeClimateClass(roofTypeClimateClass)
      ),
    [dispatch]
  );

  const setRoofFloorType = useCallback(
    (roofFloorType: RoofFloorTypes) =>
      dispatch(flatRoofSlice.actions.setRoofFloorType(roofFloorType)),
    [dispatch]
  );

  const setWaterproofingType = useCallback(
    (waterproofingType: WaterproofingTypes) =>
      dispatch(flatRoofSlice.actions.setWaterproofingType(waterproofingType)),
    [dispatch]
  );

  const setSecuringMethodTopLayer = useCallback(
    (topLayer: TopLayerSecuringMethodTypes) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodTopLayer(topLayer)),
    [dispatch]
  );

  const setSecuringMethodBottomLayer = useCallback(
    (bottomLayer: BottomLayerSecuringMethodTypes) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodBottomLayer(bottomLayer)),
    [dispatch]
  );

  const setSecuringMethodInsulation = useCallback(
    (insulation: InsulationSecuringMethodTypes) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodInsulation(insulation)),
    [dispatch]
  );

  const setSecuringMethodVaporShield = useCallback(
    (vaporShield: VaporShieldSecuringMethodTypes) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodVaporShield(vaporShield)),
    [dispatch]
  );

  const resetConfigurationToRoofType = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToRoofType()),
    [dispatch]
  );

  const resetConfigurationToRoofFloor = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToRoofFloor()),
    [dispatch]
  );

  const resetConfigurationToWaterproofing = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToWaterproofing()),
    [dispatch]
  );

  const resetConfigurationToSecuringMethods = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToSecuringMethods()),
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration: FlatRoofConfigurationState) =>
      dispatch(flatRoofSlice.actions.setConfiguration(configuration)),
    [dispatch]
  );

  const setRoofStructureInsulationMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(
        flatRoofSlice.actions.setRoofStructureInsulationMaterial(material)
      ),
    [dispatch]
  );

  const resetConfiguration = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfiguration()),
    [dispatch]
  );

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(flatRoofSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  );

  const addScreenshot = useCallback(
    (dataURL: string) => dispatch(flatRoofSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  );

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(flatRoofSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  );

  return {
    flatRoofState,
    addScreenshot,
    setOverviewScreenshot,
    flatRoofScreenshots,
    thermalPerformance,
    downloadState,
    possibleConfigurations,
    configurationState,
    setRoofFloorType,
    setRoofTypeClimateClass,
    setRoofTypeType,
    setWaterproofingType,
    setSecuringMethodTopLayer,
    setSecuringMethodBottomLayer,
    setSecuringMethodInsulation,
    setSecuringMethodVaporShield,
    setRoofStructureInsulationMaterial,
    setConfiguration,
    setDownloadState,
    resetConfigurationToRoofType,
    resetConfigurationToRoofFloor,
    resetConfigurationToWaterproofing,
    resetConfigurationToSecuringMethods,
    resetConfiguration,
  };
}
