import { useEffect } from "react"
import { useApplication } from "../../../store/application/hooks"
import { Models } from "../../viewer/renderer.api"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSol } from "../../../store/sol/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"
import { SolInsulationTypes } from "@iko-design-center/shared"

export function useSolInsulation() {
  const { currentModel, directLink, setDirectLink } = useApplication()
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setSolInsulationType: setSolInsulationTypeState,
  } = useSol()

  const solInsulationType = configurationState.solInsulation.type
  const nextButtonDisabled = !solInsulationType

  useEffect(() => {
    if (renderer && currentModel === Models.DOLLHOUSE && directLink) {
      setDirectLink(false)
      renderer.displayModel(Models.SOL_SOUSDALLAGE).then(() => renderer.hideMarkers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleNextButtonClick() {
    switch (solInsulationType) {
      case SolInsulationTypes.ENERTHERM_CHAPE_TG:
        history.push(Routes.SOL_2_NOISE_LEVEL)
        break
      case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
        history.push(Routes.SOL_2_CONFORMITY)
        break
      case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
        history.push(Routes.SOL_4_OVERVIEW)
        break
    }
  }

  function setSolInsulationType(type: any) {
    setSolInsulationTypeState(type)
    renderer.displaySolInsulationType(type)
  }

  return {
    t,
    solInsulationType,
    setSolInsulationType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
