import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import { Button } from "../../common/Button";
import { CloseIcon, DownloadIcon } from "../../common/Icons";
import { MultiSelect } from "../../common/MultiSelect";
import { SingleSelect } from "../../common/SingleSelect";
import { Spacer } from "../../common/Spacer";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useDownload } from "./download.hook";
import { useOverview } from "./overview.hooks";
import { isMobile } from "../../../services/helpers.service";
import { SolInsulationTypes } from "@iko-design-center/shared";

const Card = styled.section`
  max-height: 95%;
  width: ${(props) => props.theme.designWidth(855)}px;
  max-width: 1200px;
  background: ${styles.colors.dark2};
  position: relative;
  padding: ${(props) => props.theme.designWidth(40)}px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media screen and (pointer: coarse) {
    padding: 56px 16px 16px 16px;
    position: relative;
    overflow-y: auto;
    border-radius: 0;
    max-height: unset;
    max-width: unset;
    width: 100%;
    height: 100%;

    svg {
      position: absolute;
    }

    .top-icon svg {
      top: 16px;
      left: 16px;
      width: 20px;
      height: 20px;
    }

    * {
      font-size: 14px;
      line-height: 20px;
    }

    h1 {
      font-size: 20px;
      line-height: 20px;
    }

    button:last-child {
      padding: 16px;
      margin-top: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const CloseButton = styled(UnstyledButton)`
  height: auto;
  position: absolute;
  right: ${(props) => props.theme.designWidth(30)}px;
  top: ${(props) => props.theme.designHeight(30)}px;
  width: ${(props) => props.theme.designWidth(18)}px;

  @media screen and (pointer: coarse) {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;

    svg {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }
`;

const SelectAllContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SelectAllButton = styled(UnstyledButton)`
  p {
    text-decoration: underline;
  }

  @media screen and (pointer: coarse) {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
`;

const Container = styled.div`
  display: block;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (pointer: coarse) {
    overflow-y: unset;
  }
`;

const containerWidth = 855 - 40 * 2;
const columnSpacerPercent = 20 / containerWidth;
const columnWidthPercent = (containerWidth - 20) / 2 / containerWidth;

const LeftColumn = styled.div`
  flex: ${columnWidthPercent};
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const SpacerColumn = styled.div`
  flex: ${columnSpacerPercent};
`;

const RightColumn = styled.div`
  flex: ${columnWidthPercent};
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Flex = styled.div`
  display: flex;

  @media screen and (pointer: coarse) {
    flex-direction: column;
    gap: 18px;
    margin: 18px 0;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${styles.colors.darkBlue};
`;

export const Download = () => {
  const {
    handleCardClick,
    closeModal,
    selectAll,
    t,
    technicalFicheOptions,
    toggleAll,
    toggleLayersOption,
    DOPOptions,
    downloadState,
    toggle,
    handleDownload,
  } = useDownload();

  const { solInsulationType } = useOverview();

  return (
    <Card onClick={handleCardClick}>
      <CloseButton>
        <CloseIcon onClick={closeModal} />
      </CloseButton>
      <TitleContainer>
        <div className={"top-icon"}>
          <DownloadIcon />
        </div>
        {!isMobile() && <Spacer widthPx={15} />}
        <H1 fontSizePx={20} color={styles.colors.white}>
          {t("modal.download.title")}
        </H1>
      </TitleContainer>
      <Spacer heightPx={19} />
      <H2 fontSizePx={14} color={styles.colors.white}>
        {t("modal.download.subtitle")}
      </H2>
      <Spacer heightPx={10} />
      <SelectAllContainer>
        <SelectAllButton onClick={selectAll}>
          <P fontWeight={500} fontSizePx={14} color={styles.colors.white}>
            {t("modal.download.selectAll")}
          </P>
        </SelectAllButton>
      </SelectAllContainer>
      <Spacer heightPx={20} />
      <Container>
        {!isMobile() ? (
          <Flex>
            <LeftColumn>
              {solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG &&
                <SingleSelect
                  checked={downloadState.processingGuideline}
                  toggle={() => toggle("processingGuideline")}
                  label={t("modal.download.processingGuideline")}
                />
              }
              <SingleSelect
                checked={downloadState.BIMFile}
                toggle={() => toggle("BIMFile")}
                label={t("modal.download.BIMFile")}
              />
            </LeftColumn>
            <SpacerColumn />
            <RightColumn>
              <SingleSelect
                checked={downloadState.screenshots}
                toggle={() => toggle("screenshots")}
                label={t("modal.download.screenshots")}
              />
            </RightColumn>
          </Flex>
        ) : (
          <Flex>
            {solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG &&
              <SingleSelect
                checked={downloadState.processingGuideline}
                toggle={() => toggle("processingGuideline")}
                label={t("modal.download.processingGuideline")}
              />
            }
            <SingleSelect
              checked={downloadState.BIMFile}
              toggle={() => toggle("BIMFile")}
              label={t("modal.download.BIMFile")}
            />
            <SingleSelect
              checked={downloadState.screenshots}
              toggle={() => toggle("screenshots")}
              label={t("modal.download.screenshots")}
            />
          </Flex>
        )}
        {!isMobile() && <Spacer heightPx={20} />}
        <Line />
        {!isMobile() && <Spacer heightPx={20} />}
        {!isMobile() ? (
          <Flex>
            <LeftColumn>
              <SingleSelect
                checked={downloadState.thermalCalculation}
                toggle={() => toggle("thermalCalculation")}
                label={t("sol.modal.download.thermalCalculation" as any)}
              />
              <MultiSelect
                title={t("modal.download.DOP")}
                options={DOPOptions}
                toggleAll={() => toggleAll("DOP")}
                toggleOption={(option) => toggleLayersOption("DOP", option)}
              />
            </LeftColumn>
            <SpacerColumn />
            <RightColumn>
              <MultiSelect
                title={t("modal.download.technicalFiche")}
                options={technicalFicheOptions}
                toggleAll={() => toggleAll("technicalFiche")}
                toggleOption={(option) =>
                  toggleLayersOption("technicalFiche", option)
                }
              />
              {/*
                <MultiSelect
                  title={t("modal.download.roofDetails")}
                  options={roofDetailOptions}
                  toggleAll={() => toggleAll("roofDetails")}
                  toggleOption={toggleRoofDetailsOption}
                />
              */}
            </RightColumn>
          </Flex>
        ) : (
          <Flex>
            <SingleSelect
              checked={downloadState.thermalCalculation}
              toggle={() => toggle("thermalCalculation")}
              label={t("sol.modal.download.thermalCalculation" as any)}
            />
            <MultiSelect
              title={t("modal.download.DOP")}
              options={DOPOptions}
              toggleAll={() => toggleAll("DOP")}
              toggleOption={(option) => toggleLayersOption("DOP", option)}
            />
            <MultiSelect
              title={t("modal.download.technicalFiche")}
              options={technicalFicheOptions}
              toggleAll={() => toggleAll("technicalFiche")}
              toggleOption={(option) =>
                toggleLayersOption("technicalFiche", option)
              }
            />
          </Flex>
        )}
        {!isMobile() && <Spacer heightPx={20} />}
        <Line />
        {!isMobile() && <Spacer heightPx={20} />}
        <Flex>
          <LeftColumn>
            <SingleSelect
              checked={downloadState.specifications}
              toggle={() => toggle("specifications")}
              label={t("modal.download.specifications")}
            />
          </LeftColumn>
          <SpacerColumn />
          <RightColumn></RightColumn>
        </Flex>
      </Container>
      <Spacer heightPx={40} />
      <Button onClick={handleDownload}>
        {t("modal.download.downloadSelected")}
      </Button>
    </Card>
  );
};
