import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import {
  CalculatorEuroIcon,
  DownloadIcon,
  EyeClosedIcon,
  EyeOpenIcon,
} from "../../common/Icons";
import { OverviewItem, OverviewItemProps } from "../../common/OverviewItem";
import { Spacer } from "../../common/Spacer";
import { ThermalPerformance } from "../../common/ThermalPerformance";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useOverview } from "./overview.hooks";
import {
  FloorHeatingTypes,
  FloorInsulationTypes,
} from "@iko-design-center/shared";
import { getFloorScreedTranslations } from "./translations";
import { Section } from "../Sidebar";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";

const Container = styled.div`
  height: 100%;
  display: block;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (pointer: coarse) {
    padding-bottom: 128px;
  }
`;

const Padding = styled.div`
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${styles.colors.darkBlue10};
`;

const AdjustSpecificationsContainer = styled.div`
  height: ${(props) => props.theme.designHeight(48)}px;
  width: 100%;
  background: ${styles.colors.darkBlue10};
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    text-decoration: underline;
  }
`;

const ButtonIcon = styled.div`
  width: 18px;
`;

const ThermalPerformaceBlock = styled.div`
  @media screen and (pointer: coarse) {
    h2 {
      font-size: 14px;
      line-height: 20px;
    }

    button p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const Overview = () => {
  const {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    floorHeatingType,
    floorInsulationType,
    floorScreedType,

    handleToggleClick,
    handleDetailsClick,

    handleDownloadFilesClick,
    handleRequestAdviceClick,
    handleAdjustSpecificationsClick,
  } = useOverview();

  let shouldShow = currentModel !== "DOLLHOUSE" && currentModel !== "FLOOR";

  // @ts-ignore
  return (
    <Section>
      <Container>
        {!isMobile() ? (
          <Intro>
            <Spacer heightPx={40} />
            <Padding>
              <H1>{t("floor.overview.title")}</H1>
            </Padding>
            <Spacer heightPx={12} />
            <Padding>
              <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
                {t("floor.overview.subtitle")}
              </P>
            </Padding>
            <Spacer heightPx={20} />
          </Intro>
        ) : (
          <Intro>
            <H1>{t("floor.overview.title")}</H1>
            <Spacer heightPx={12} />
            <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
              {t("floor.overview.mobile.subtitle")}
            </P>
          </Intro>
        )}

        <Line />
        <InteractiveOverviewItem
          onClick={() => handleToggleClick("floorType", "VLOERBEKLEDING")}
          visible={visibleState.floorType}
          title={t("floor.overview.floorType").toUpperCase()}
          text={t("floor.overview.floorTypeValue")}
        />
        <Line />

        <InteractiveOverviewItem
          onClick={() => handleToggleClick("floorScreed", "DEKVLOER")}
          visible={visibleState.floorScreed}
          title={t("floor.overview.floorScreed").toUpperCase()}
          text={getFloorScreedTranslations(t, floorScreedType)}
        />
        <Line />

        {floorHeatingType === FloorHeatingTypes.FLOOR_HEATING && (
          <>
            <InteractiveOverviewItem
              onClick={() =>
                handleToggleClick("floorHeating", "VLOERVERWARMING")
              }
              visible={visibleState.floorHeating}
              title={t("floor.overview.floorHeating").toUpperCase()}
              text={t("floor.overview.floorHeatingValue")}
            />
            <Line />
          </>
        )}

        {floorInsulationType === FloorInsulationTypes.ENERTHERM_ALU && (
          <>
            <InteractiveOverviewItem
              onClick={() =>
                handleToggleClick(
                  "floorInsulationFoil",
                  "ISOLATIE_SCHEIDINGSFOLIE"
                )
              }
              visible={visibleState.floorInsulationFoil}
              title={t("floor.overview.floorInsulationFoil").toUpperCase()}
              text={t("floor.overview.floorInsulationFoilValue")}
            />
            <Line />
          </>
        )}

        <InteractiveOverviewItem
          onClick={() => handleToggleClick("floorInsulation", "ISOLATIE")}
          visible={visibleState.floorInsulation}
          title={t("floor.overview.floorInsulation").toUpperCase()}
          text={
            floorInsulationType === FloorInsulationTypes.ENERTHERM_ALU
              ? t("material.ENERTHERM_ALU")
              : t("material.ENERTHERM_KR_ALU")
          }
        />
        <Line />

        <InteractiveOverviewItem
          onClick={() =>
            handleToggleClick("floorInsulationEdge", "RANDISOLATIE")
          }
          visible={visibleState.floorInsulationEdge}
          title={t("floor.overview.floorInsulationEdge").toUpperCase()}
          text={t("floor.overview.floorInsulationEdgeValue")}
        />
        <Line />

        <InteractiveOverviewItem
          onClick={() => handleToggleClick("bearingFloor", "DRAAGVLOER")}
          visible={visibleState.bearingFloor}
          title={t("floor.overview.bearingFloor").toUpperCase()}
          text={t("floor.overview.bearingFloorValue")}
        />
        <Line />

        {!shouldShow && (
          <InteractiveOverviewItem
            onClick={handleDetailsClick}
            visible={visibleState.details}
            title={t("floor.overview.detailsTitle").toUpperCase()}
            text={t("floor.overview.detailsText")}
          />
        )}
        <Line />
        <Spacer heightPx={24} />
        <Padding>
          <ThermalPerformaceBlock>
            <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
              {t("flatRoof.overview.thermalPerformance")}
            </H2>
            <Spacer heightPx={8} />
            <ThermalPerformance
              insulationThickness={
                thermalPerformance.floorInsulation.thickness!
              }
              UCValue={thermalPerformance.UCValue!}
              RTValue={thermalPerformance.RTValue!}
              background={true}
            />
            <AdjustSpecificationsContainer
              onClick={handleAdjustSpecificationsClick}
            >
              <UnstyledButton>
                <P fontWeight={500} fontSizePx={13}>
                  {t("flatRoof.overview.adjustSpecifications")}
                </P>
              </UnstyledButton>
            </AdjustSpecificationsContainer>
          </ThermalPerformaceBlock>
          <Spacer heightPx={24} />
          <DownloadButton
            onClick={handleDownloadFilesClick}
            className={"download-button"}
          >
            <P fontSizePx={14} color={styles.colors.white}>
              {t("flatRoof.overview.downloadFiles")}
            </P>
            <ButtonIcon>
              <DownloadIcon />
            </ButtonIcon>
          </DownloadButton>
          <Spacer heightPx={8} />
          <AdviceButton
            onClick={handleRequestAdviceClick}
            className={"advice-button"}
          >
            <P fontSizePx={14} color={styles.colors.primary}>
              {t("flatRoof.overview.requestAdvice")}
            </P>
            <ButtonIcon>
              <CalculatorEuroIcon />
            </ButtonIcon>
          </AdviceButton>
          <Spacer heightPx={40} />
        </Padding>
      </Container>
    </Section>
  );
};

const DownloadButton = styled.button`
  &:hover {
    background: ${styles.colors.primaryNonHover};
  }

  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  padding-left: ${(props) => props.theme.designWidth(16)}px;
  padding-right: ${(props) => props.theme.designWidth(29.2)}px;
  width: 100%;
  background: ${styles.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border-width: 0;

  @media screen and (pointer: coarse) {
    &.download-button {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${styles.colors.primary};
      border: none;
      border-radius: unset;
      padding: 24px 0;
      gap: 32px;

      * {
        font-size: 20px;
        line-height: 23px;
      }

      svg {
        margin-top: 6px;
      }
    }

    &.advice-button {
      padding: 16px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      * {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`;

const AdviceButton = styled(DownloadButton)`
  &:hover {
    border: 1px solid ${styles.colors.primaryNonHover};
    background: ${styles.colors.primaryNonHover};

    p {
      color: ${styles.colors.white};
    }

    svg > path {
      fill: ${styles.colors.white};
    }
  }

  border: 1px solid ${styles.colors.primaryNonHover};
  background: ${styles.colors.white};
`;

type OverviewMaterialItemProps = {
  visible: boolean;
  onClick: () => void;
  disabled?: boolean;
} & OverviewItemProps;

const OverviewItemContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};

  @media screen and (pointer: coarse) {
    pointer-events: none;
  }
`;

const WidthContainer = styled.div<{ suffix: string | undefined }>`
  width: 85%;

  @media screen and (pointer: coarse) {
    padding: 0 16px;

    * {
      font-size: 16px;
      line-height: 24px;

      p {
        margin-top: 0;

        &:first-of-type {
          padding-right: 6px;
          margin-right: 6px;
          border-right: ${(props) =>
            props.suffix ? `1px solid ${styles.colors.primary}` : ""};
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    h2 {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const InteractiveOverviewItem = ({
  visible,
  onClick,
  title,
  text,
  suffix,
  disabled = false,
}: OverviewMaterialItemProps) => {
  return (
    <OverviewItemContainer
      onClick={() => (!disabled ? onClick() : null)}
      disabled={disabled}
    >
      {!isMobile() && (!visible ? <EyeClosedIcon /> : <EyeOpenIcon />)}
      {!isMobile() && <Spacer widthPx={16} />}
      <WidthContainer suffix={suffix}>
        <OverviewItem title={title} text={text} suffix={suffix} />
      </WidthContainer>
    </OverviewItemContainer>
  );
};
