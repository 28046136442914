import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { DesignCenter } from "./components/viewer/DesignCenter";

import { store } from "./store/store";
import { styles } from "./styles";
import { SizeProvider } from "./styles/SizeProvider";
import "./translations/i18n";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="application-name" content={t("head.applicationName")} />
          <meta name="author" content={t("head.author")} />
          <meta name="description" content={t("head.description")} />
          <meta name="og:description" content={t("head.description")} />
          <meta name="keywords" content={t("head.keywords")} />
          <title>{t("head.title")}</title>
        </Helmet>
        <Provider store={store}>
          <BrowserRouter>
            <styles.GlobalStyle />
            <SizeProvider>
              <DesignCenter />
            </SizeProvider>
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
