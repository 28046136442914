import {
  TopLayerMaterialIDs,
  WaterproofingTypes,
} from "@iko-design-center/shared";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { renderer } from "../../viewer/Renderer";
import { useRoutes } from "../../../store/routes/hooks";

export function useWaterproofing() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setWaterproofingType: setStoreWaterproofingType,
    possibleConfigurations,
  } = useFlatRoof();

  const bituminousConfig = possibleConfigurations!.find(
    (config) => config.waterproofing.type === WaterproofingTypes.BITUMINOUS
  );
  const bituminousDisabled = !bituminousConfig;
  const liquidConfig = possibleConfigurations!.find(
    (config) => config.waterproofing.type === WaterproofingTypes.LIQUID
  );
  const liquidDisabled = !liquidConfig;

  const [localType, setLocalType] = useState<WaterproofingTypes | null>(
    bituminousDisabled ? null : WaterproofingTypes.BITUMINOUS
  );

  const displayFinalTopLayerMaterial = useCallback(() => {
    const finalTopLayerMaterial =
      possibleConfigurations[0].roofStructure.topLayerMaterial;
    renderer.displayTopLayerMaterial(
      finalTopLayerMaterial!.id as TopLayerMaterialIDs
    );
  }, [possibleConfigurations]);

  useEffect(() => {
    if (!bituminousDisabled) {
      displayFinalTopLayerMaterial();
    }
  }, [bituminousDisabled, displayFinalTopLayerMaterial]);

  function handleNextButtonClick() {
    setStoreWaterproofingType(localType!);
    history.push(Routes.FLAT_ROOF_4_SECURING_METHODS);
  }

  function setWaterproofingType(type: WaterproofingTypes) {
    setLocalType(type);
    if (type === WaterproofingTypes.LIQUID) {
      renderer.displayWaterproofing(type);
    }

    if (type === WaterproofingTypes.BITUMINOUS) {
      displayFinalTopLayerMaterial();
    }
  }

  const nextButtonDisabled = !localType;

  return {
    t,
    type: localType,
    setWaterproofingType,
    handleNextButtonClick,
    nextButtonDisabled,
    bituminousDisabled,
    liquidDisabled,
  };
}
