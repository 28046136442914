import { createSlice } from "@reduxjs/toolkit";

export enum ToastDisplayTypes {
  NONE = "NONE",
  SCREENSHOT_SAVED = "SCREENSHOT_SAVED",
}

export type ToastState = {
  display: ToastDisplayTypes;
};

const initialState: ToastState = {
  display: ToastDisplayTypes.NONE,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    closeToast: (state) => {
      state.display = ToastDisplayTypes.NONE;
    },
    displayScreenshotSavedToast: (state) => {
      state.display = ToastDisplayTypes.SCREENSHOT_SAVED;
    },
  },
});
