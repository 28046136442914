import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"
import { useApplication } from "../../store/application/hooks"
import { renderer } from "../viewer/Renderer"
import { Models } from "../viewer/renderer.api"
import { OverviewTab } from "./OverviewTab"
import { RegularTab } from "./RegularTab"
import { Tab } from "./Tabs"
import { useRoutes } from "../../store/routes/hooks"
import { useSol } from "../../store/sol/hooks"
import { SolInsulationTypes } from "@iko-design-center/shared"

export function useTabs() {
  const { Routes } = useRoutes() as any
  const routeMap = new Map<string, number>([
    // GENERAL
    [Routes.HOME, 0],
    // FLAT ROOF
    [Routes.FLAT_ROOF_1_ROOF_TYPE, 1],
    [Routes.FLAT_ROOF_2_ROOF_FLOOR, 2],
    [Routes.FLAT_ROOF_3_WATERPROOFING, 3],
    [Routes.FLAT_ROOF_4_SECURING_METHODS, 4],
    [Routes.FLAT_ROOF_5_OVERVIEW, 5],
    // CAVITY WALL
    [Routes.CAVITY_WALL_1_WALL_TYPE, 1],
    [Routes.CAVITY_WALL_2_WALL_INSULATION, 2],
    [Routes.CAVITY_WALL_3_OVERVIEW, 5],
    // SLOPED ROOF
    [Routes.SLOPED_ROOF_1_ROOF_TYPE, 1],
    [Routes.SLOPED_ROOF_2_ROOF_INSULATION, 2],
    [Routes.SLOPED_ROOF_3_OVERVIEW, 3],
    // VENTILATED WALL
    [Routes.VENTILATED_WALL_1_WALL_TYPE, 1],
    [Routes.VENTILATED_WALL_2_FACADE_CLADDING, 2],
    [Routes.VENTILATED_WALL_3_OVERVIEW, 3],
    // FLOOR
    [Routes.FLOOR_1_FLOOR_HEATING, 1],
    [Routes.FLOOR_2_FLOOR_INSULATION, 2],
    [Routes.FLOOR_3_FLOOR_SCREED, 3],
    [Routes.FLOOR_4_OVERVIEW, 4],
    // SOL
    [Routes.SOL_1_INSULATION, 1],
    [Routes.SOL_2_NOISE_LEVEL, 2],
    [Routes.SOL_3_ACOUSTIC_LAYER, 3],
    [Routes.SOL_2_CONFORMITY, 2],
    [Routes.SOL_3_BUILDING_TYPE, 3],
    [Routes.SOL_4_OVERVIEW, 4],
  ])

  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const currentStep = routeMap.get(pathname as string)!
  const { applicationType } = useApplication()
  const { solState } = useSol()

  // In case the current model is a roof detail, and the user clicks
  // on one of the tabs

  // FLAT ROOF
  function displayFlatRoof() {
    renderer.toggleAllLayers(applicationType, true)
    renderer.displayModel(Models.FLAT_ROOF)
  }

  function navigateToRoofType() {
    if (currentStep >= 1) {
      history.push(Routes.FLAT_ROOF_1_ROOF_TYPE)
      displayFlatRoof()
    }
  }

  function navigateToRoofFloor() {
    if (currentStep >= 2) {
      history.push(Routes.FLAT_ROOF_2_ROOF_FLOOR)
      displayFlatRoof()
    }
  }

  function navigateToWaterproofing() {
    if (currentStep >= 3) {
      history.push(Routes.FLAT_ROOF_3_WATERPROOFING)
      displayFlatRoof()
    }
  }

  function navigateToSecuringMethods() {
    if (currentStep >= 4) {
      history.push(Routes.FLAT_ROOF_4_SECURING_METHODS)
      displayFlatRoof()
    }
  }

  function navigateToFlatRoofOverview() {
    if (currentStep >= 5) {
      history.push(Routes.FLAT_ROOF_5_OVERVIEW)
      displayFlatRoof()
    }
  }

  // CAVITY WALL
  function displayCavityWall() {
    renderer.toggleAllLayers(applicationType, true)
    renderer.displayModel(Models.CAVITY_WALL)
  }

  function navigateToWallType() {
    if (currentStep >= 1) {
      history.push(Routes.CAVITY_WALL_1_WALL_TYPE)
      displayCavityWall()
    }
  }

  function navigateToWallInsulation() {
    if (currentStep >= 2) {
      history.push(Routes.CAVITY_WALL_2_WALL_INSULATION)
      displayCavityWall()
    }
  }

  function navigateToCavityWallOverview() {
    if (currentStep >= 3) {
      history.push(Routes.CAVITY_WALL_3_OVERVIEW)
      displayCavityWall()
    }
  }

  // SLOPED ROOF
  function displaySlopedRoof() {
    renderer.toggleAllLayers(applicationType, true)
    renderer.displayModel(Models.SLOPED_ROOF)
  }

  function navigateToSlopedRoofType() {
    if (currentStep >= 1) {
      history.push(Routes.SLOPED_ROOF_1_ROOF_TYPE)
      displaySlopedRoof()
    }
  }

  function navigateToSlopedRoofInsulation() {
    if (currentStep >= 2) {
      history.push(Routes.SLOPED_ROOF_2_ROOF_INSULATION)
      displaySlopedRoof()
    }
  }

  function navigateToSlopedRoofOverview() {
    if (currentStep >= 3) {
      history.push(Routes.SLOPED_ROOF_3_OVERVIEW)
      displaySlopedRoof()
    }
  }

  // VENTILATED WALL
  function displayVentilatedWall() {
    renderer.toggleAllLayers(applicationType, true)
    renderer.displayModel(Models.VENTILATED_WALL)
  }

  function navigateToVentilatedWallWallType() {
    if (currentStep >= 1) {
      history.push(Routes.VENTILATED_WALL_1_WALL_TYPE)
      renderer.toggleAllLayers(applicationType, true)
      displayVentilatedWall()
    }
  }

  function navigateToVentilatedWallFacadeCladding() {
    if (currentStep >= 2) {
      history.push(Routes.VENTILATED_WALL_2_FACADE_CLADDING)
      displayVentilatedWall()
    }
  }

  function navigateToVentilatedWallOverview() {
    if (currentStep >= 3) {
      history.push(Routes.VENTILATED_WALL_3_OVERVIEW)
      displayVentilatedWall()
    }
  }

  // FLOOR
  function displayFloor() {
    renderer.toggleAllLayers(applicationType, true)
    renderer.displayModel(Models.FLOOR)
  }

  function navigateToFloorHeating() {
    if (currentStep >= 1) {
      history.push(Routes.FLOOR_1_FLOOR_HEATING)
      displayFloor()
    }
  }

  function navigateToFloorInsulation() {
    if (currentStep >= 2) {
      history.push(Routes.FLOOR_2_FLOOR_INSULATION)
      displayFloor()
    }
  }

  function navigateToFloorScreed() {
    if (currentStep >= 2) {
      history.push(Routes.FLOOR_3_FLOOR_SCREED)
      displayFloor()
    }
  }

  function navigateToFloorOverview() {
    if (currentStep >= 3) {
      history.push(Routes.FLOOR_4_OVERVIEW)
      displayFloor()
    }
  }

  // SOL
  function displaySol() {
    renderer.toggleAllLayers(applicationType, true)
  }

  function navigateToSolInsulation() {
    if (currentStep >= 1) {
      history.push(Routes.SOL_1_INSULATION)
      displaySol()
    }
  }

  function navigateToSolNoiseLevel() {
    if (currentStep >= 2) {
      history.push(Routes.SOL_2_NOISE_LEVEL)
      displaySol()
    }
  }

  function navigateToSolAcousticLayer() {
    if (currentStep >= 3) {
      history.push(Routes.SOL_3_ACOUSTIC_LAYER)
      displaySol()
    }
  }

  function navigateToSolConformity() {
    if (currentStep >= 2) {
      history.push(Routes.SOL_2_CONFORMITY)
      displaySol()
    }
  }

  function navigateToSolBuildingType() {
    if (currentStep >= 3) {
      history.push(Routes.SOL_3_BUILDING_TYPE)
      displaySol()
    }
  }

  function navigateToSolOverview() {
    if (currentStep >= 4) {
      history.push(Routes.SOL_4_OVERVIEW)
      displaySol()
    }
  }

  // FLAT ROOF
  const flatRoofTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.flatRoof.roofType"),
      active: pathname === Routes.FLAT_ROOF_1_ROOF_TYPE,
      visited: 1 <= currentStep,
      onClick: navigateToRoofType,
    },
    {
      step: 2,
      component: RegularTab,
      text: t("navigation.flatRoof.roofFloor"),
      active: pathname === Routes.FLAT_ROOF_2_ROOF_FLOOR,
      visited: 2 <= currentStep,
      onClick: navigateToRoofFloor,
    },
    {
      step: 3,
      component: RegularTab,
      text: t("navigation.flatRoof.waterproofing"),
      active: pathname === Routes.FLAT_ROOF_3_WATERPROOFING,
      visited: 3 <= currentStep,
      onClick: navigateToWaterproofing,
    },
    {
      step: 4,
      component: RegularTab,
      text: t("navigation.flatRoof.securingMethods"),
      active: pathname === Routes.FLAT_ROOF_4_SECURING_METHODS,
      visited: 4 <= currentStep,
      onClick: navigateToSecuringMethods,
    },
    {
      step: 5,
      component: OverviewTab,
      text: t("navigation.flatRoof.overview"),
      active: pathname === Routes.FLAT_ROOF_5_OVERVIEW,
      visited: false,
      onClick: navigateToFlatRoofOverview,
    },
  ]

  // CAVITY WALL
  const cavityWallTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.cavityWall.wallType" as any),
      active: pathname === Routes.CAVITY_WALL_1_WALL_TYPE,
      visited: 1 <= currentStep,
      onClick: navigateToWallType,
    },
    {
      step: 2,
      component: RegularTab,
      text: t("navigation.cavityWall.wallInsulation" as any),
      active: pathname === Routes.CAVITY_WALL_2_WALL_INSULATION,
      visited: 2 <= currentStep,
      onClick: navigateToWallInsulation,
    },
    {
      step: 3,
      component: OverviewTab,
      text: t("navigation.cavityWall.overview" as any),
      active: pathname === Routes.CAVITY_WALL_3_OVERVIEW,
      visited: 3 <= currentStep,
      onClick: navigateToCavityWallOverview,
    },
  ]

  // SLOPED ROOF
  const slopedRoofTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.slopedRoof.roofType" as any),
      active: pathname === Routes.SLOPED_ROOF_1_ROOF_TYPE,
      visited: 1 <= currentStep,
      onClick: navigateToSlopedRoofType,
    },
    {
      step: 2,
      component: RegularTab,
      text: t("navigation.slopedRoof.roofInsulation" as any),
      active: pathname === Routes.SLOPED_ROOF_2_ROOF_INSULATION,
      visited: 2 <= currentStep,
      onClick: navigateToSlopedRoofInsulation,
    },
    {
      step: 3,
      component: OverviewTab,
      text: t("navigation.slopedRoof.overview" as any),
      active: pathname === Routes.SLOPED_ROOF_3_OVERVIEW,
      visited: 3 <= currentStep,
      onClick: navigateToSlopedRoofOverview,
    },
  ]

  // VENTILATED WALL
  const ventilatedWallTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.ventilatedWall.wallType" as any),
      active: pathname === Routes.VENTILATED_WALL_1_WALL_TYPE,
      visited: 1 <= currentStep,
      onClick: navigateToVentilatedWallWallType,
    },
    {
      step: 2,
      component: RegularTab,
      text: t("navigation.ventilatedWall.facadeCladding" as any),
      active: pathname === Routes.VENTILATED_WALL_2_FACADE_CLADDING,
      visited: 2 <= currentStep,
      onClick: navigateToVentilatedWallFacadeCladding,
    },
    {
      step: 3,
      component: OverviewTab,
      text: t("navigation.ventilatedWall.overview" as any),
      active: pathname === Routes.VENTILATED_WALL_3_OVERVIEW,
      visited: 3 <= currentStep,
      onClick: navigateToVentilatedWallOverview,
    },
  ]

  // FLOOR
  const floorTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.floor.floorHeating" as any),
      active: pathname === Routes.FLOOR_1_FLOOR_HEATING,
      visited: 1 <= currentStep,
      onClick: navigateToFloorHeating,
    },
    {
      step: 2,
      component: RegularTab,
      text: t("navigation.floor.floorInsulation" as any),
      active: pathname === Routes.FLOOR_2_FLOOR_INSULATION,
      visited: 2 <= currentStep,
      onClick: navigateToFloorInsulation,
    },
    {
      step: 3,
      component: RegularTab,
      text: t("navigation.floor.floorScreed" as any),
      active: pathname === Routes.FLOOR_3_FLOOR_SCREED,
      visited: 3 <= currentStep,
      onClick: navigateToFloorScreed,
    },
    {
      step: 4,
      component: OverviewTab,
      text: t("navigation.floor.overview" as any),
      active: pathname === Routes.FLOOR_4_OVERVIEW,
      visited: 4 <= currentStep,
      onClick: navigateToFloorOverview,
    },
  ]

  // SOL
  let solTabs: Tab[] = [
    {
      step: 1,
      component: RegularTab,
      text: t("navigation.sol.solInsulation" as any),
      active: pathname === Routes.SOL_1_INSULATION,
      visited: 1 <= currentStep,
      onClick: navigateToSolInsulation,
    },
    {
      step: 4,
      component: OverviewTab,
      text: t("navigation.sol.overview" as any),
      active: pathname === Routes.SOL_4_OVERVIEW,
      visited: 4 <= currentStep,
      onClick: navigateToSolOverview,
    },
  ]
  let solRoutes = [Routes.SOL_1_INSULATION, Routes.SOL_4_OVERVIEW]
  switch (solState.configuration.solInsulation.type) {
    case SolInsulationTypes.ENERTHERM_CHAPE_TG:
      solTabs = [
        {
          step: 1,
          component: RegularTab,
          text: t("navigation.sol.solInsulation" as any),
          active: pathname === Routes.SOL_1_INSULATION,
          visited: 1 <= currentStep,
          onClick: navigateToSolInsulation,
        },
        {
          step: 2,
          component: RegularTab,
          text: t("navigation.sol.solNoiseLevel" as any),
          active: pathname === Routes.SOL_2_NOISE_LEVEL,
          visited: 2 <= currentStep,
          onClick: navigateToSolNoiseLevel,
        },
        {
          step: 3,
          component: RegularTab,
          text: t("navigation.sol.solAcousticLayer" as any),
          active: pathname === Routes.SOL_3_ACOUSTIC_LAYER,
          visited: 3 <= currentStep,
          onClick: navigateToSolAcousticLayer,
        },
        {
          step: 4,
          component: OverviewTab,
          text: t("navigation.sol.overview" as any),
          active: pathname === Routes.SOL_4_OVERVIEW,
          visited: 4 <= currentStep,
          onClick: navigateToSolOverview,
        },
      ]
      solRoutes = [
        Routes.SOL_1_INSULATION,
        Routes.SOL_2_NOISE_LEVEL,
        Routes.SOL_3_ACOUSTIC_LAYER,
        Routes.SOL_4_OVERVIEW,
      ]
      break
    case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
      solTabs = [
        {
          step: 1,
          component: RegularTab,
          text: t("navigation.sol.solInsulation" as any),
          active: pathname === Routes.SOL_1_INSULATION,
          visited: 1 <= currentStep,
          onClick: navigateToSolInsulation,
        },
        {
          step: 2,
          component: RegularTab,
          text: t("navigation.sol.solConformity" as any),
          active: pathname === Routes.SOL_2_CONFORMITY,
          visited: 2 <= currentStep,
          onClick: navigateToSolConformity,
        },
        {
          step: 3,
          component: RegularTab,
          text: t("navigation.sol.solBuildingType" as any),
          active: pathname === Routes.SOL_3_BUILDING_TYPE,
          visited: 3 <= currentStep,
          onClick: navigateToSolBuildingType,
        },
        {
          step: 4,
          component: OverviewTab,
          text: t("navigation.sol.overview" as any),
          active: pathname === Routes.SOL_4_OVERVIEW,
          visited: 4 <= currentStep,
          onClick: navigateToSolOverview,
        },
      ]
      solRoutes = [
        Routes.SOL_1_INSULATION,
        Routes.SOL_2_CONFORMITY,
        Routes.SOL_3_BUILDING_TYPE,
        Routes.SOL_4_OVERVIEW,
      ]
      break
    case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
      solTabs = [
        {
          step: 1,
          component: RegularTab,
          text: t("navigation.sol.solInsulation" as any),
          active: pathname === Routes.SOL_1_INSULATION,
          visited: 1 <= currentStep,
          onClick: navigateToSolInsulation,
        },
        {
          step: 4,
          component: OverviewTab,
          text: t("navigation.sol.overview" as any),
          active: pathname === Routes.SOL_4_OVERVIEW,
          visited: 4 <= currentStep,
          onClick: navigateToSolOverview,
        },
      ]
      solRoutes = [Routes.SOL_1_INSULATION, Routes.SOL_4_OVERVIEW]
      break
  }

  let tabs: Tab[] = []

  // FLAT ROOF
  const flatRoofRoutes = [
    Routes.FLAT_ROOF_1_ROOF_TYPE,
    Routes.FLAT_ROOF_2_ROOF_FLOOR,
    Routes.FLAT_ROOF_3_WATERPROOFING,
    Routes.FLAT_ROOF_4_SECURING_METHODS,
    Routes.FLAT_ROOF_5_OVERVIEW,
  ]

  // CAVITY WALL
  const cavityWallRoutes = [
    Routes.CAVITY_WALL_1_WALL_TYPE,
    Routes.CAVITY_WALL_2_WALL_INSULATION,
    Routes.CAVITY_WALL_3_OVERVIEW,
  ]

  // SLOPED ROOF
  const slopedRoofRoutes = [
    Routes.SLOPED_ROOF_1_ROOF_TYPE,
    Routes.SLOPED_ROOF_2_ROOF_INSULATION,
    Routes.SLOPED_ROOF_3_OVERVIEW,
  ]

  // VENTILATED WALL
  const ventilatedWallRoutes = [
    Routes.VENTILATED_WALL_1_WALL_TYPE,
    Routes.VENTILATED_WALL_2_FACADE_CLADDING,
    Routes.VENTILATED_WALL_3_OVERVIEW,
  ]

  // FLOOR
  const floorRoutes = [
    Routes.FLOOR_1_FLOOR_HEATING,
    Routes.FLOOR_2_FLOOR_INSULATION,
    Routes.FLOOR_3_FLOOR_SCREED,
    Routes.FLOOR_4_OVERVIEW,
  ]

  if (flatRoofRoutes.find((x) => x === pathname)) {
    tabs = flatRoofTabs
  } else if (cavityWallRoutes.find((x) => x === pathname)) {
    tabs = cavityWallTabs
  } else if (slopedRoofRoutes.find((x) => x === pathname)) {
    tabs = slopedRoofTabs
  } else if (ventilatedWallRoutes.find((x) => x === pathname)) {
    tabs = ventilatedWallTabs
  } else if (floorRoutes.find((x) => x === pathname)) {
    tabs = floorTabs
  } else if (solRoutes.find((x) => x === pathname)) {
    tabs = solTabs
  }

  return {
    tabs,
    currentStep,
    navigateToSolNoiseLevel,
  }
}
