import { SyntheticEvent } from "react";
import styled from "styled-components";

/**
 * Used for declarative links via React-router.
 * Instead of empty or javascript:(void) anchor tags.
 */

export const UnstyledButton = styled.button<{
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}>`
  height: 100%;
  background-color: unset;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
`;
