import { SolNoiseLevelTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1 } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useSolNoiseLevel } from "./solNoiseLevel.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SolNoiseLevel = () => {
  const {
    t,
    solNoiseLevelType,
    setSolNoiseLevelType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useSolNoiseLevel();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("sol.solNoiseLevel.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1 lineHeightPx={33}>{t("sol.solNoiseLevel.title")}</H1>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={SolNoiseLevelTypes.DB_18}
          checked={solNoiseLevelType === SolNoiseLevelTypes.DB_18}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.DB_18)}
          text={t("sol.solNoiseLevel.18db")}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolNoiseLevelTypes.DB_19}
          checked={solNoiseLevelType === SolNoiseLevelTypes.DB_19}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.DB_19)}
          text={t("sol.solNoiseLevel.19db")}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolNoiseLevelTypes.DB_20}
          checked={solNoiseLevelType === SolNoiseLevelTypes.DB_20}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.DB_20)}
          text={t("sol.solNoiseLevel.20db")}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolNoiseLevelTypes.DB_22}
          checked={solNoiseLevelType === SolNoiseLevelTypes.DB_22}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.DB_22)}
          text={t("sol.solNoiseLevel.22db")}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolNoiseLevelTypes.DB_23}
          checked={solNoiseLevelType === SolNoiseLevelTypes.DB_23}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.DB_23)}
          text={t("sol.solNoiseLevel.23db")}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolNoiseLevelTypes.AUCUNE}
          checked={solNoiseLevelType === SolNoiseLevelTypes.AUCUNE}
          onClick={() => setSolNoiseLevelType(SolNoiseLevelTypes.AUCUNE)}
          text={t("sol.solNoiseLevel.aucune")}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
