import { SolNoiseLevelTypes, SolAcousticLayerTypes } from "@iko-design-center/shared";
import React, { useEffect } from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useSolAcousticLayer } from "./solAcousticLayer.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SolAcousticLayer = () => {
  const {
    t,
    solNoiseLevelType,
    solAcousticLayerType,
    setSolAcousticLayerType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useSolAcousticLayer();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  useEffect(() => {
    switch (solNoiseLevelType) {
      case SolNoiseLevelTypes.DB_18:
      case SolNoiseLevelTypes.DB_19:
      case SolNoiseLevelTypes.DB_20:
      case SolNoiseLevelTypes.DB_22:
        break
      case SolNoiseLevelTypes.DB_23:
        setSolAcousticLayerType(SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO)
        break
      case SolNoiseLevelTypes.AUCUNE:
        setSolAcousticLayerType(SolAcousticLayerTypes.AUCUNE)
        break
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const renderTitle = () => {
    switch (solNoiseLevelType) {
      case SolNoiseLevelTypes.DB_18:
      case SolNoiseLevelTypes.DB_19:
      case SolNoiseLevelTypes.DB_20:
      case SolNoiseLevelTypes.DB_22:
        return <H1 lineHeightPx={33}>{t("sol.solAcousticLayer.title")}</H1>
      case SolNoiseLevelTypes.DB_23:
        return <H1 lineHeightPx={33}>{t("navigation.sol.solAcousticLayer")}</H1>
      case SolNoiseLevelTypes.AUCUNE:
        return <H1 lineHeightPx={33}>{t("sol.solAcousticLayer.titleAucune")}</H1>
    }
  }

  const renderOptions = () => {
    switch (solNoiseLevelType) {
      case SolNoiseLevelTypes.DB_18:
      case SolNoiseLevelTypes.DB_19:
      case SolNoiseLevelTypes.DB_20:
        return (
          <>
            <RadioButton
              value={SolAcousticLayerTypes.AUCUNE}
              checked={solAcousticLayerType === SolAcousticLayerTypes.AUCUNE}
              onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.AUCUNE)}
              text={t("sol.solAcousticLayer.aucune")}
              disabled={false}
            />
            {!isMobile() && <Spacer heightPx={10} />}
            <RadioButton
              value={SolAcousticLayerTypes.ASSOURCHAPE_20}
              checked={solAcousticLayerType === SolAcousticLayerTypes.ASSOURCHAPE_20}
              onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.ASSOURCHAPE_20)}
              text={t("sol.solAcousticLayer.ASSOURCHAPE_20")}
              disabled={false}
            />
            {!isMobile() && <Spacer heightPx={10} />}
            <RadioButton
              value={SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
              checked={solAcousticLayerType === SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
              onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO)}
              text={t("sol.solAcousticLayer.TRAMICHAPE_ECO_PRO")}
              disabled={false}
            />
          </>
        )
      case SolNoiseLevelTypes.DB_22:
        return <>
          <RadioButton
            value={SolAcousticLayerTypes.ASSOURCHAPE_20}
            checked={solAcousticLayerType === SolAcousticLayerTypes.ASSOURCHAPE_20}
            onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.ASSOURCHAPE_20)}
            text={t("sol.solAcousticLayer.ASSOURCHAPE_20")}
            disabled={false}
          />
          {!isMobile() && <Spacer heightPx={10} />}
          <RadioButton
            value={SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
            checked={solAcousticLayerType === SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
            onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO)}
            text={t("sol.solAcousticLayer.TRAMICHAPE_ECO_PRO")}
            disabled={false}
          />
        </>
      case SolNoiseLevelTypes.DB_23:
        return <>
          {/* <P>
            {t('sol.solAcousticLayer.infoTramichapeEcoPro')}
          </P>
          <Spacer heightPx={16} /> */}
          <RadioButton
            value={SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
            checked={solAcousticLayerType === SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO}
            onClick={() => setSolAcousticLayerType(SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO)}
            text={t("sol.solAcousticLayer.TRAMICHAPE_ECO_PRO")}
            disabled={true}
          />
        </>
      case SolNoiseLevelTypes.AUCUNE:
        return <>
          <P>
            {t('sol.solAcousticLayer.infoAucune')}
          </P>
        </>
    }
  }

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("sol.solAcousticLayer.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        {renderTitle()}
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        {renderOptions()}
      </RadioButtonList>
    </Sidebar>
  );
};
