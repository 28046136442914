import {
  FloorConfigurationState,
  FloorThermalPerformanceState,
  FloorHeatingTypes,
  FloorInsulationTypes,
  FloorScreedTypes,
  FloorMaterialMap,
  FE_MATERIALS,
  FEMaterial,
} from "@iko-design-center/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadState } from "./download";
import { initialFloorState } from "./state";
import {
  getThermalPerformance,
  updateThermalLayer,
} from "./thermalPerformance";

export const floorSlice = createSlice({
  name: "floor",
  initialState: initialFloorState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialFloorState,
        download: state.download,
        screenshots: state.screenshots,
      };
    },
    resetConfigurationToHeating: (state) => {
      const draft = {
        ...initialFloorState,
        download: state.download,
        configuration: {
          ...initialFloorState.configuration,
          floorHeating: state.configuration.floorHeating,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    resetConfigurationToInsulation: (state) => {
      const draft = {
        ...initialFloorState,
        download: state.download,
        configuration: {
          ...initialFloorState.configuration,
          floorHeating: state.configuration.floorHeating,
          floorInsulation: state.configuration.floorInsulation,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    resetConfigurationToScreed: (state) => {
      const draft = {
        ...initialFloorState,
        download: state.download,
        configuration: {
          ...initialFloorState.configuration,
          floorHeating: state.configuration.floorHeating,
          floorInsulation: state.configuration.floorInsulation,
          floorScreed: state.configuration.floorScreed,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    setThermalPerformance: (
      state,
      action: PayloadAction<FloorThermalPerformanceState>
    ) => {
      state.thermalPerformance = action.payload;
    },
    setFloorHeatingType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id === FloorMaterialMap[action.payload as FloorHeatingTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          floorHeating: {
            ...state.configuration.floorHeating,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setFloorInsulationType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          FloorMaterialMap[action.payload as FloorInsulationTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          floorInsulation: {
            ...state.configuration.floorInsulation,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setFloorScreedType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id === FloorMaterialMap[action.payload as FloorScreedTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          floorScreed: {
            ...state.configuration.floorScreed,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },

    setFloorHeatingMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.floorHeating.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "floorHeating"
      );
    },
    setFloorInsulationMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.floorInsulation.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "floorInsulation"
      );
    },
    setFloorScreedMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.floorScreed.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "floorScreed"
      );
    },

    setConfiguration(state, action: PayloadAction<FloorConfigurationState>) {
      state.configuration = action.payload;
      state.thermalPerformance = getThermalPerformance(action.payload);
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload;
      state.thermalPerformance = getThermalPerformance(state.configuration);
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload;
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload];
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload;
    },
  },
});
