import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";

type PictureTileProps = {
  src: string;
  selected: boolean;
  text: string;
  onClick: () => void;
};

function getBorderColor(selected: boolean) {
  return selected ? styles.colors.primary : styles.colors.darkBlue30;
}

const Tile = styled.div`
  height: 100%;
  background-color: unset;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  cursor: pointer;

  @media screen and (pointer: coarse) {
    max-width: 200px;
    justify-self: center;

    div:first-of-type {
      display: flex;
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const ImageContainer = styled.div<{ selected: boolean }>`
  width: 100%;
  height: auto;
  padding: ${(props) => props.theme.designWidth(8.18)}px;
  border-radius: 5px;
  border: ${(props) =>
    props.selected
      ? `2px solid ${styles.colors.primary}`
      : `2px solid ${styles.colors.transparent}`};

  @media screen and (pointer: coarse) {
    border: 2px solid ${(props) => getBorderColor(props.selected)};
    padding: 8px;
  }
`;

const Image = styled.img<{ selected: boolean }>`
  width: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: ${(props) =>
    props.selected ? `none` : `0 2px 4px ${styles.colors.boxShadow}`};
  border-radius: 5px;
`;

const TextSpacer = styled.div`
  margin-top: ${(props) => props.theme.designHeight(8.06)}px;
`;

export const PictureTile = ({
  src,
  selected,
  text,
  onClick,
}: PictureTileProps) => {
  return (
    <Tile onClick={onClick}>
      <ImageContainer selected={selected}>
        <Image selected={selected} src={src} />
      </ImageContainer>
      <TextSpacer />
      {selected && <P fontWeight={700}>{text}</P>}
      {!selected && <P>{text}</P>}
    </Tile>
  );
};
