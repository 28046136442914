import { useEffect } from "react"
import { useApplication } from "../../../store/application/hooks"
import { Models } from "../../viewer/renderer.api"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFloor } from "../../../store/floor/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"

export function useFloorHeating() {
  const { currentModel, directLink, setDirectLink } = useApplication()
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setFloorHeatingType: setFloorHeatingTypeState,
  } = useFloor()

  const floorHeatingType = configurationState.floorHeating.type
  const nextButtonDisabled = !floorHeatingType

  useEffect(() => {
    if (renderer && currentModel === Models.DOLLHOUSE && directLink) {
      setDirectLink(false)
      renderer.displayModel(Models.FLOOR).then(() => renderer.hideMarkers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleNextButtonClick() {
    history.push(Routes.FLOOR_2_FLOOR_INSULATION)
  }

  function setFloorHeatingType(type: any) {
    setFloorHeatingTypeState(type)
    renderer.displayFloorHeatingType(type)
  }

  return {
    t,
    floorHeatingType,
    setFloorHeatingType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
