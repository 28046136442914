import {
  FEMaterial,
  ThermalPerformanceLayer,
  SolConfigurationState,
  SolThermalPerformanceState,
  initialSolThermalPerformanceState,
} from "@iko-design-center/shared"

export function updateThermalLayer(
  thermalPerformance: SolThermalPerformanceState,
  material: FEMaterial,
  type: string
) {
  const draft: SolThermalPerformanceState = {
    ...thermalPerformance,
  }
  // @ts-ignore
  draft[type] = getThermalPerformanceLayer(material)
  return calculateThermalPerformance(draft)
}

export function getThermalPerformance(
  state: SolConfigurationState
): SolThermalPerformanceState {
  const thermalPerformance = {
    ...initialSolThermalPerformanceState,
  }

  thermalPerformance.solInsulation = getThermalPerformanceLayer(
    state.solInsulation.material!
  )

  return calculateThermalPerformance(thermalPerformance)
}

function calculateThermalPerformance(
  state: SolThermalPerformanceState
): SolThermalPerformanceState {
  const draft = { ...state }
  let isComplete = true

  ;["solInsulation"].forEach((layer: string) => {
    // @ts-ignore
    const { thickness, lambda } = draft[layer] as ThermalPerformanceLayer

    if (thickness && lambda) {
      // @ts-ignore
      draft[layer] = {
        thickness,
        lambda,
        RValue: parseFloat(
          (Math.floor(thickness / 1000 / lambda / 0.05) * 0.05).toFixed(2)
        ),
      }
    } else {
      isComplete = false
    }
  })

  if (isComplete) {
    draft.RTValue = parseFloat(
      // RSI +
      (draft.solInsulation.RValue!)
      // RSE
      .toFixed(2)
    )
    draft.UCValue = parseFloat((1 / draft.RTValue).toFixed(2))
  }
  return draft
}

function getThermalPerformanceLayer(
  material: FEMaterial
): ThermalPerformanceLayer {
  const isVariable =
    material.lambdaThicknessThresholds &&
    material.lambdaThicknessThresholds.length > 0 &&
    material.lambdas.length > 1

  const thickness = material.thickness
  let lambda: number

  if (!isVariable) {
    lambda = material.lambdas[0]
  } else {
    material.lambdaThicknessThresholds!.forEach(
      (threshold: any, index: any) => {
        if (thickness > threshold) {
          lambda = material.lambdas[index + 1]
        }
      }
    )
  }

  return {
    thickness,
    lambda: lambda!,
    RValue: null,
  }
}
