import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useVentilatedWall } from "../../../store/ventilated-wall/hooks";
import { renderer } from "../../viewer/Renderer";
import { useRoutes } from "../../../store/routes/hooks";

export function useFacadeCladding() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any;
  const history = useHistory();

  const {
    configurationState,
    setVentilatedWallFacadeCladdingType: setStoreVentilatedWallFacadeCladdingType,
  } = useVentilatedWall();

  const ventilatedWallFacadeCladdingType = configurationState.facadeCladding.type;
  const nextButtonDisabled = !ventilatedWallFacadeCladdingType;

  function handleNextButtonClick() {
    history.push(Routes.VENTILATED_WALL_3_OVERVIEW);
  }

  function setVentilatedWallFacadeCladdingType(type: any) {
    setStoreVentilatedWallFacadeCladdingType(type);
    renderer.displayVentilatedWallFacadeType(type);
  }

  return {
    t,
    ventilatedWallFacadeCladdingType,
    setVentilatedWallFacadeCladdingType,
    handleNextButtonClick,
    nextButtonDisabled,
  };
}
