import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { applicationSlice, ApplicationTypes } from "./store"
import { Models } from "../../components/viewer/renderer.api"

export function useApplication() {
  const dispatch = useAppDispatch()
  const applicationType = useAppSelector((state) => state.application.type)
  const modelView = useAppSelector((state) => state.application.modelView)
  const currentModel = useAppSelector((state) => state.application.currentModel)
  const directLink = useAppSelector((state) => state.application.directLink)

  const setApplicationType = useCallback(
    (applicationType: ApplicationTypes | null) =>
      dispatch(applicationSlice.actions.setApplicationType(applicationType)),
    [dispatch]
  )

  const setCurrentModel = useCallback(
    (currentModel: Models | null) =>
      dispatch(applicationSlice.actions.setCurrentModel(currentModel)),
    [dispatch]
  )

  const setCurrentView = useCallback(
    (modelView: boolean) =>
      dispatch(applicationSlice.actions.setCurrentView(modelView)),
    [dispatch]
  )

  const setDirectLink = useCallback(
    (directLink: boolean) =>
      dispatch(applicationSlice.actions.setDirectLink(directLink)),
    [dispatch]
  )

  return {
    applicationType,
    setApplicationType,
    currentModel,
    setCurrentModel,
    modelView,
    setCurrentView,
    directLink,
    setDirectLink,
  }
}
