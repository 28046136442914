import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";
import { TabProps } from "./Tabs";

const Tab = styled.div<{ active: boolean }>`
  height: 100%;
  padding-left: ${(props) => props.theme.designWidth(30)}px;
  padding-right: ${(props) => props.theme.designWidth(30)}px;
  background-color: ${(props) =>
    props.active ? styles.colors.dark2 : styles.colors.darkBlue5};
  border-bottom: ${(props) =>
    props.active ? `4px solid ${styles.colors.primary}` : `none`};
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: ${(props) =>
      props.active ? styles.colors.white : styles.colors.darkBlue};
  }

  @media screen and (pointer: coarse) {
    transition: 0.2s all ease;
    border-bottom: ${(props) =>
      props.active
        ? `4px solid ${styles.colors.primary}`
        : `4px solid ${styles.colors.transparent}`};
    background-color: ${(props) =>
      props.active ? styles.colors.dark2 : styles.colors.transparent};

    p {
      color: ${(props) =>
        props.active ? styles.colors.white : styles.colors.darkBlue50};
    }
  }
`;

const Button = styled(UnstyledButton)<{ active: boolean; visited: boolean }>`
  cursor: ${(props) => (props.visited ? "pointer" : "unset")};

  @media screen and (pointer: coarse) {
    z-index: ${(props) => (props.active ? "10" : "unset")};
    min-width: ${(props) => (props.active ? "100%" : "unset")};
  }
`;

export const OverviewTab = ({ text, active, onClick, visited }: TabProps) => {
  return (
    <Button onClick={onClick} visited={visited} active={active}>
      <Tab active={active}>
        <P fontWeight={700}>{text}</P>
      </Tab>
    </Button>
  );
};
