import {
  VentilatedWallWallTypeTypes,
  VentilatedWallFacadeCladdingTypes,
  VentilatedWallInsulationTypes,
} from "@iko-design-center/shared";

export function getWallTypeTranslations(
  t: any,
  type: VentilatedWallWallTypeTypes
) {
  switch (type) {
    case VentilatedWallWallTypeTypes.CONCRETE_WALL:
      return t("ventilatedWall.wallType.concrete");
    case VentilatedWallWallTypeTypes.LIME_SAND_WALL:
      return t("ventilatedWall.wallType.limeSand");
    case VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL:
      return t("ventilatedWall.wallType.hollowStone");
    case VentilatedWallWallTypeTypes.CROSSWOOD_WALL:
      return t("ventilatedWall.wallType.crosswood");
  }
}

export function getFacadeCladdingTranslations(
  t: any,
  type: VentilatedWallFacadeCladdingTypes
) {
  let translation = "";

  switch (type) {
    case VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL:
      return t("ventilatedWall.facadeCladding.cedral");
    case VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE:
      return t("ventilatedWall.facadeCladding.equitone");
  }

  return translation;
}

export function getWallInsulationTranslations(
  t: any,
  type: VentilatedWallInsulationTypes
) {
  switch (type) {
    case VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE:
      return t("material.ENERTHERM_ALU_PURE");
  }
}
