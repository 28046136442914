import {
  WallInsulationTypes,
  WallTypeTypes,
  OuterCavityLeafTypes,
} from "@iko-design-center/shared";

export function getWallTypeTranslations(t: any, type: WallTypeTypes) {
  switch (type) {
    case WallTypeTypes.CONCRETE_WALL:
      return t("cavityWall.wallType.concreteWall");
    case WallTypeTypes.LIME_SAND_WALL:
      return t("cavityWall.wallType.limeSandWall");
    case WallTypeTypes.HOLLOW_STONE_WALL:
      return t("cavityWall.wallType.hollowStoneWall");
    case WallTypeTypes.CROSSWOOD_WALL:
      return t("cavityWall.wallType.crosswoodWall");
  }
}

export function getWallInsulationTranslations(
  t: any,
  type: WallInsulationTypes
) {
  let translation = "";

  switch (type) {
    case WallInsulationTypes.ALU_50:
      translation = t("material.ENERTHERM_ALU_50");
      break;
    case WallInsulationTypes.ALU_TG:
      translation = t("material.ENERTHERM_ALU_TG");
      break;
  }

  return translation;
}

export function getOuterCavityLeafTranslations(
  t: any,
  type: OuterCavityLeafTypes
) {
  switch (type) {
    case OuterCavityLeafTypes.FACADE_MASONRY:
      return t("cavityWall.outerCavityLeaf.facadeMasonry");
  }
}
