import { useEffect } from "react"
import { useApplication } from "../../../store/application/hooks"
import { Models } from "../../viewer/renderer.api"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useVentilatedWall } from "../../../store/ventilated-wall/hooks"
import { renderer } from "../../viewer/Renderer"
import { VentilatedWallInsulationTypes } from "@iko-design-center/shared"
import { useRoutes } from "../../../store/routes/hooks"

export function useWallType() {
  const { currentModel, directLink, setDirectLink } = useApplication()
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setVentilatedWallWallType: setVentilatedWallWallTypeTypeState,
    setVentilatedWallInsulationType: setVentilatedWallInsulationTypeState,
  } = useVentilatedWall()

  const ventilatedWallWallTypeType = configurationState.wallType.type
  const nextButtonDisabled = !ventilatedWallWallTypeType

  useEffect(() => {
    if (renderer && currentModel === Models.DOLLHOUSE && directLink) {
      setDirectLink(false)
      renderer.displayModel(Models.VENTILATED_WALL).then(() => renderer.hideMarkers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleNextButtonClick() {
    history.push(Routes.VENTILATED_WALL_2_FACADE_CLADDING)
  }

  function setVentilatedWallWallTypeType(type: any) {
    setVentilatedWallInsulationTypeState(
      VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE
    )
    setVentilatedWallWallTypeTypeState(type)
    renderer.displayVentilatedWallWallType(type)
  }

  return {
    t,
    ventilatedWallWallTypeType,
    setVentilatedWallWallTypeType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
