import { VentilatedWallWallTypeTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { Grid } from "../Grid";
import { PictureTile } from "../PictureTile";
import { useWallType } from "./wallType.hooks";
import { Sidebar } from "../Sidebar";
import { getWallTypeTranslations } from "./translations";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const WallType = () => {
  const {
    t,
    ventilatedWallWallTypeType,
    setVentilatedWallWallTypeType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useWallType();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("ventilatedWall.wallType.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("ventilatedWall.wallType.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("ventilatedWall.wallType.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <Grid rows={2} gaps={true} className={"tiles-grid"}>
        <PictureTile
          src={"/./assets/images/ventilated-wall/beton_texture.jpg"}
          selected={
            ventilatedWallWallTypeType ===
            VentilatedWallWallTypeTypes.CONCRETE_WALL
          }
          text={getWallTypeTranslations(
            t,
            VentilatedWallWallTypeTypes.CONCRETE_WALL
          )}
          onClick={() =>
            setVentilatedWallWallTypeType(
              VentilatedWallWallTypeTypes.CONCRETE_WALL
            )
          }
        />
        <PictureTile
          src={"/./assets/images/ventilated-wall/kalksteen_texture.jpg"}
          selected={
            ventilatedWallWallTypeType ===
            VentilatedWallWallTypeTypes.LIME_SAND_WALL
          }
          text={getWallTypeTranslations(
            t,
            VentilatedWallWallTypeTypes.LIME_SAND_WALL
          )}
          onClick={() =>
            setVentilatedWallWallTypeType(
              VentilatedWallWallTypeTypes.LIME_SAND_WALL
            )
          }
        />
        <PictureTile
          src={"/./assets/images/ventilated-wall/hollesteen_texture.jpg"}
          selected={
            ventilatedWallWallTypeType ===
            VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL
          }
          text={getWallTypeTranslations(
            t,
            VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL
          )}
          onClick={() =>
            setVentilatedWallWallTypeType(
              VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL
            )
          }
        />
        <PictureTile
          src={"/./assets/images/ventilated-wall/kruishoutlaag_texture.jpg"}
          selected={
            ventilatedWallWallTypeType ===
            VentilatedWallWallTypeTypes.CROSSWOOD_WALL
          }
          text={getWallTypeTranslations(
            t,
            VentilatedWallWallTypeTypes.CROSSWOOD_WALL
          )}
          onClick={() =>
            setVentilatedWallWallTypeType(
              VentilatedWallWallTypeTypes.CROSSWOOD_WALL
            )
          }
        />
      </Grid>
    </Sidebar>
  );
};
