import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { resizeImage } from "../../../services/screenshot.service"
import { useSol } from "../../../store/sol/hooks"
import { useModal } from "../../../store/modal/hooks"
import { renderer } from "../../viewer/Renderer"
import { useApplication } from "../../../store/application/hooks"
import { isMobile } from "../../../services/helpers.service"
import { useTabs } from "../../navigation/tabs.hook"
import {
  FEMaterial,
  SolAcousticLayerTypes,
  SolBuildingTypeTypes,
  SolConformityTypes,
  SolInsulationTypes,
  SolNoiseLevelTypes,
} from "@iko-design-center/shared"
import { updateThermalLayer } from "../../../store/sol/thermalPerformance"

type VisibleState = {
  solScreed: boolean
  solTopLayer: boolean
  solHeating: boolean

  solInsulation: boolean

  solBottomLayer: boolean
  solBearingFloor: boolean
  solInsulationEdge: boolean
  solInnerCavityLeaf: boolean
  solType: boolean

  solNoiseLevel: boolean
  solAcousticLayer: boolean

  solConformity: boolean
  solBuildingType: boolean

  details: boolean
}

export function useOverview() {
  const { t } = useTranslation() as any
  const {
    configurationState: {
      solInsulation,
      solNoiseLevel,
      solAcousticLayer,
      solConformity,
      solBuildingType,
    },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
    setThermalPerformance,
  } = useSol()

  const solInsulationType = solInsulation.type!
  const solNoiseLevelType = solNoiseLevel.type!
  const solAcousticLayerType = solAcousticLayer.type!
  const solConformityType = solConformity.type!
  const solBuildingTypeType = solBuildingType.type!

  const { navigateToSolNoiseLevel } = useTabs()

  const {
    displaySolDownloadModal,
    displaySolThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal()
  const { currentModel, applicationType } = useApplication()

  const [thermalCalculationUpdated, setThermalCalculationUpdated] = useState(
    false
  )

  useEffect(() => {
    if (!thermalCalculationUpdated && thermalPerformance.UCValue !== null) {
      const localMaterial = { ...solInsulation.material }

      switch (solInsulationType) {
        case SolInsulationTypes.ENERTHERM_CHAPE_TG:
          switch (solNoiseLevelType) {
            case SolNoiseLevelTypes.DB_18:
              switch (solAcousticLayerType) {
                case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                  localMaterial.thickness = 81
                  break
                case SolAcousticLayerTypes.ASSOURCHAPE_20:
                  localMaterial.thickness = 81
                  break
                default:
                  // AUCUNE
                  localMaterial.thickness = 30
                  break
              }
              break

            case SolNoiseLevelTypes.DB_19:
              switch (solAcousticLayerType) {
                case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                  localMaterial.thickness = 81
                  break
                case SolAcousticLayerTypes.ASSOURCHAPE_20:
                  localMaterial.thickness = 81
                  break
                default:
                  // AUCUNE
                  localMaterial.thickness = 50
                  break
              }
              break

            case SolNoiseLevelTypes.DB_20:
              switch (solAcousticLayerType) {
                case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                  localMaterial.thickness = 81
                  break
                case SolAcousticLayerTypes.ASSOURCHAPE_20:
                  localMaterial.thickness = 81
                  break
                default:
                  // AUCUNE
                  localMaterial.thickness = 54
                  break
              }
              break

            case SolNoiseLevelTypes.DB_22:
              switch (solAcousticLayerType) {
                case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                  localMaterial.thickness = 81
                  break
                default:
                  // ASSOURCHAPE_20
                  localMaterial.thickness = 81
                  break
              }
              break

            case SolNoiseLevelTypes.DB_23:
              localMaterial.thickness = 81
              break

            default:
              // AUCUNE
              localMaterial.thickness = 140
              break
          }
          break
        case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
          switch (solConformityType) {
            case SolConformityTypes.P1_1_1:
              switch (solBuildingTypeType) {
                case SolBuildingTypeTypes.OUI:
                  localMaterial.thickness = 120
                  break
                default:
                  // NON
                  localMaterial.thickness = 80
                  break
              }
              break
            default:
              // P1_1_2
              localMaterial.thickness = 140
              break
          }
          break
        case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
          localMaterial.thickness = 140
          break
      }

      setThermalCalculationUpdated(true)
      setThermalPerformance(
        updateThermalLayer(
          thermalPerformance,
          localMaterial as FEMaterial,
          "solInsulation"
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thermalPerformance])

  const initialVisibleState = useMemo(
    () => ({
      solScreed: true,
      solTopLayer: true,
      solHeating: true,

      solInsulation: true,

      solBottomLayer: true,
      solBearingFloor: true,
      solInsulationEdge: true,
      solInnerCavityLeaf: true,
      solType: true,

      solNoiseLevel: true,
      solAcousticLayer: true,

      solConformity: true,
      solBuildingType: true,

      details: true,
    }),
    []
  )
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  )
  let humanReadableName = `${solInsulation.type}`

  useEffect(() => {
    setHumanReadableName(humanReadableName)
  }, [humanReadableName, setHumanReadableName])

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers()
      const dataURL = renderer.createScreenshot()
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL)
      })

      return () => {
        renderer.hideMarkers()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  )

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] })
  }

  useEffect(() => {
    setVisibleState(initialVisibleState)
    if (!isMobile()) renderer.displayMarkers()
    renderer.toggleAllLayers(applicationType, true)
    renderer.displaySolAcousticLayerType(solAcousticLayerType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel, setVisibleState, initialVisibleState, applicationType])

  function handleToggleClick(toggle = "solInsulation", layer = "ISOLATIE") {
    toggleVisibleState(toggle as keyof VisibleState)
    renderer.toggleLayers(layer)
  }

  function handleDetailsClick() {
    toggleVisibleState("details")
    renderer.toggleDetails()
  }

  function handleEditNoiseLevel() {
    navigateToSolNoiseLevel()
  }

  function handleAdjustSpecificationsClick() {
    displaySolThermalCalculationModal()
  }

  function handleDownloadFilesClick() {
    displaySolDownloadModal()
  }

  function handleRequestAdviceClick() {
    displayRequestAdviceModal()
  }

  function getInsulationThickness() {
    switch (solInsulationType) {
      case SolInsulationTypes.ENERTHERM_CHAPE_TG:
        switch (solNoiseLevelType) {
          case SolNoiseLevelTypes.DB_18:
            switch (solAcousticLayerType) {
              case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                return "≥ 81 mm"
              case SolAcousticLayerTypes.ASSOURCHAPE_20:
                return "≥ 81 mm"
              default:
                // AUCUNE
                return "25 à 39 mm"
            }

          case SolNoiseLevelTypes.DB_19:
            switch (solAcousticLayerType) {
              case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                return "≥ 81 mm"
              case SolAcousticLayerTypes.ASSOURCHAPE_20:
                return "≥ 81 mm"
              default:
                // AUCUNE
                return "40 à 53 mm"
            }

          case SolNoiseLevelTypes.DB_20:
            switch (solAcousticLayerType) {
              case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                return "≥ 81 mm"
              case SolAcousticLayerTypes.ASSOURCHAPE_20:
                return "≥ 81 mm"
              default:
                // AUCUNE
                return "54 à 140 mm"
            }

          case SolNoiseLevelTypes.DB_22:
            switch (solAcousticLayerType) {
              case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
                return "≥ 81 mm"
              default:
                // ASSOURCHAPE_20
                return "≥ 81 mm"
            }

          case SolNoiseLevelTypes.DB_23:
            return "≥ 81 mm"
          default:
            // AUCUNE
            return "25 à 140 mm"
        }
      case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
        switch (solConformityType) {
          case SolConformityTypes.P1_1_1:
            switch (solBuildingTypeType) {
              case SolBuildingTypeTypes.OUI:
                return "30 à 120 mm"
              default:
                // NON
                return "30 à 92 mm"
            }
          default:
            // P1_1_2
            return "30 à 200 mm"
        }
      case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
        return "30 à 200 mm"
    }
  }

  return {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    solInsulationType,
    solNoiseLevelType,
    solAcousticLayerType,
    solConformityType,
    solBuildingTypeType,

    handleToggleClick,
    handleDetailsClick,

    handleEditNoiseLevel,

    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,

    getInsulationThickness,
  }
}
