import React from "react";
import styled from "styled-components";
import { AppBlocker } from "./AppBlocker";
import { CountryAndLanguage } from "../modal/CountryAndLanguage";
import { Instructions } from "./Instructions";
import { Loading } from "./Loading";
import { MemoRenderer } from "./Renderer";
import { useViewer } from "./viewer.hooks";
import { ViewerNavigation } from "./ViewerNavigation";
import { ViewerDetail } from "./ViewerDetail";
import { Screenshot } from "./Screenshot";
import { isMobile } from "../../services/helpers.service";
import { useRoutes } from "../../store/routes/hooks";
import { useLocation } from "react-router";

const Section = styled.section`
  width: ${(props) => props.theme.designWidth(448)}px;
  position: relative;
  flex-grow: 1;

  @media screen and (pointer: coarse) {
    min-width: 100%;
    
    canvas {
      height: 100% !important;
    }
  }
`;

export const Viewer = () => {
  const {
    handleLoadStart,
    handleProgressChange,
    handleLoaded,
    hideInstructions,
    displayInstructions,
    handleModelChange,
    displayLoader,
    loaderPercentage,
    displayAppBlocker,
    displayCountryAndLanguage,
    handleCountryAndLanguageChanged
  } = useViewer();

  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const isPreviewPage = location.pathname === Routes.PREVIEW
  const isModelPage = location.pathname !== Routes.HOME

  return (
    <Section>
      {displayCountryAndLanguage && <CountryAndLanguage handleCountryAndLanguageChanged={handleCountryAndLanguageChanged} />}
      {!displayCountryAndLanguage && displayAppBlocker && <AppBlocker />}
      {!displayCountryAndLanguage && displayLoader && <Loading percentage={loaderPercentage} />}
      {!displayCountryAndLanguage && displayInstructions && !isMobile() && !isPreviewPage && !isModelPage && <Instructions handleInput={hideInstructions} />}
      {!displayCountryAndLanguage && <ViewerNavigation />}
      {!displayCountryAndLanguage && !isMobile() && !isPreviewPage && <Screenshot />}
      {!displayCountryAndLanguage && <ViewerDetail />}
      {!displayCountryAndLanguage && <MemoRenderer
        handleLoadStart={handleLoadStart}
        handleLoaded={handleLoaded}
        handleProgressChange={handleProgressChange}
        handleModelChange={handleModelChange}
      />}
    </Section>
  );
};
