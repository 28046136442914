export function resizeImage(
  dataURL: string,
  width: number,
  height: number
): Promise<string> {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = dataURL;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(image, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}
