import { SolConfigurationState } from "@iko-design-center/shared";
import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useApplication } from "../../../store/application/hooks";
import { ApplicationTypes } from "../../../store/application/store";
import { Home } from "../Home";
import { useSol } from "../../../store/sol/hooks";
import { useRoutes } from "../../../store/routes/hooks";

export const Guard = (props: { children: ReactNode }) => {
  return (
    <>
      <ResetStateGuard>
        <ValidStateGuard>{props.children}</ValidStateGuard>
      </ResetStateGuard>
    </>
  );
};

// This makes sure, that when a user navigates backwards,
// either via the Navbar or via the browser back button,
// the state is reset. This so the user can reselect any options.
const ResetStateGuard = (props: { children: ReactNode }) => {
  const {
    resetConfiguration,
    resetConfigurationToInsulation,
    resetConfigurationToNoiseLevel,
    resetConfigurationToAcousticLayer,
    resetConfigurationToConformity,
    resetConfigurationToBuildingType,
  } = useSol();
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const routeHome = Routes.HOME;
  const routeSolInsulation = Routes.SOL_1_INSULATION
  const routeSolNoiseLevel = Routes.SOL_2_NOISE_LEVEL
  const routeSolAcousticLayer = Routes.SOL_3_ACOUSTIC_LAYER
  const routeSolConformity = Routes.SOL_2_CONFORMITY
  const routeSolBuildingType = Routes.SOL_3_BUILDING_TYPE
  const routeSolOverview = Routes.SOL_4_OVERVIEW

  useEffect(() => {
    switch (location.pathname) {
      case routeHome:
        resetConfiguration();
        break;
      case routeSolInsulation:
        resetConfigurationToInsulation();
        break;
      case routeSolNoiseLevel:
        resetConfigurationToNoiseLevel();
        break;
      case routeSolAcousticLayer:
        resetConfigurationToAcousticLayer();
        break;
      case routeSolConformity:
        resetConfigurationToConformity();
        break;
      case routeSolBuildingType:
        resetConfigurationToBuildingType();
        break;
      case routeSolOverview:
        break;
    }
  }, [
    resetConfiguration,
    resetConfigurationToInsulation,
    resetConfigurationToNoiseLevel,
    resetConfigurationToAcousticLayer,
    resetConfigurationToConformity,
    resetConfigurationToBuildingType,
    location.pathname,
    routeHome,
    routeSolInsulation,
    routeSolNoiseLevel,
    routeSolAcousticLayer,
    routeSolConformity,
    routeSolBuildingType,
    routeSolOverview,
  ]);

  return <>{props.children}</>;
};

// When a user refreshes the screen on let's say the OverviewPage,
// No state will be present in Redux, thus 'cannot read of undefined's will pop up.
// This prevents that.
const ValidStateGuard = (props: { children: ReactNode }) => {
  const { directLink, setDirectLink, setApplicationType, applicationType } = useApplication();
  const location = useLocation();
  const { configurationState } = useSol();
  const { Routes } = useRoutes() as any;
  const history = useHistory();

  const valid = checkValidStateForRoute(
    applicationType,
    configurationState,
    location.pathname,
    Routes
  );

  useEffect(() => {
    if (!valid) {
      if (!directLink) {
        setDirectLink(true)
        setApplicationType(ApplicationTypes.SOL);
        history.push(Routes.SOL_1_INSULATION);
      } else {
        history.push(Routes.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, setApplicationType, history, setDirectLink, directLink])

  return valid ? <>{props.children}</> : <Home />;
};

function checkValidStateForRoute(
  applicationType: ApplicationTypes | null,
  configurationState: SolConfigurationState,
  route: string,
  Routes: any
): boolean | undefined {
  const {
    solInsulation,
    solNoiseLevel,
    solConformity,
  } = configurationState;

  const applicationTypeValid = applicationType !== null;
  const solInsulationValid = solInsulation.type !== null;
  const solNoiseLevelValid = solNoiseLevel.type !== null;
  const solConformityValid = solConformity.type !== null;

  switch (route) {
    case Routes.SOL_4_OVERVIEW:
      return solInsulationValid

    case Routes.SOL_3_BUILDING_TYPE:
      return solInsulationValid && solConformityValid;

    case Routes.SOL_2_CONFORMITY:
      return solInsulationValid;

    case Routes.SOL_3_ACOUSTIC_LAYER:
      return solInsulationValid && solNoiseLevelValid;

    case Routes.SOL_2_NOISE_LEVEL:
      return solInsulationValid;

    case Routes.SOL_1_INSULATION:
      return applicationTypeValid;

    case Routes.HOME:
      return true;
  }
}
