import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useToast } from "../../store/toast/hooks";
import { styles } from "../../styles";
import { ScreenshotSavedCheckmark } from "../common/Icons";
import { Spacer } from "../common/Spacer";
import { H2, P } from "../common/Typography";

const Card = styled.div`
  width: ${430 / 16}rem;
  height: auto;
  box-shadow: 0 0 40px rgba(57, 40, 166, 0.15);
  border-radius: 5px;
  background: ${styles.colors.white};
  padding: ${24 / 16}rem;
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScreenshotSaved = () => {
  const { t } = useTranslation();
  const { closeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      closeToast();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [closeToast]);

  return (
    <Card>
      <div>
        <ScreenshotSavedCheckmark />
      </div>
      <Spacer widthPx={20} />
      <TextContainer>
        <H2 fontSizePx={18} lineHeightPx={21} color={styles.colors.dark3}>
          {t("viewer.screenshotSavedTitle")}
        </H2>
        <Spacer heightPx={4} />
        <P color={styles.colors.dark3} fontSizePx={14} lineHeightPx={22}>
          {t("viewer.screenshotSavedText")}
        </P>
      </TextContainer>
    </Card>
  );
};
