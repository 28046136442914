import React from "react";
import styled from "styled-components";
import { ScreenshotIcon } from "../common/Icons";
import { useApplication } from "../../store/application/hooks";
import { useAppDispatch } from "../../store/hooks";
import { useToast } from "../../store/toast/hooks";
import { P } from "../common/Typography";
import { styles } from "../../styles";
import { renderer } from "./Renderer";

// FLAT ROOF
import { useFlatRoof } from "../../store/flat-roof/hooks";
import { flatRoofSlice } from "../../store/flat-roof/store";

// CAVITY WALL
import { useCavityWall } from "../../store/cavity-wall/hooks";
import { cavityWallSlice } from "../../store/cavity-wall/store";

// SLOPED ROOF
import { useSlopedRoof } from "../../store/sloped-roof/hooks";
import { slopedRoofSlice } from "../../store/sloped-roof/store";

// VENTILATED WALL
import { useVentilatedWall } from "../../store/ventilated-wall/hooks";
import { ventilatedWallSlice } from "../../store/ventilated-wall/store";

// FLOOR
import { useFloor } from "../../store/floor/hooks";
import { floorSlice } from "../../store/floor/store";

// SOL
import { useSol } from "../../store/sol/hooks";
import { solSlice } from "../../store/sol/store";

const SectionRight = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
  display: flex;
  align-items: flex-start;
`;

export const Screenshot = () => {
  const dispatch = useAppDispatch();
  const { displayScreenshotSavedToast } = useToast();
  const { applicationType } = useApplication();

  // FLAT ROOF
  const { flatRoofScreenshots } = useFlatRoof();
  // CAVITY WALL
  const { cavityWallScreenshots } = useCavityWall();
  // SLOPED ROOF
  const { slopedRoofScreenshots } = useSlopedRoof();
  // VENTILATED WALL
  const { ventilatedWallScreenshots } = useVentilatedWall();
  // FLOOR
  const { floorScreenshots } = useFloor();
  // SOL
  const { solScreenshots } = useSol();

  function handleScreenshotClick() {
    const dataURL = renderer.createScreenshot();
    switch (applicationType) {
      // FLAT ROOF
      case "FLAT_ROOF":
        dispatch(flatRoofSlice.actions.addScreenshot(dataURL));
        break;
      // CAVITY WALL
      case "CAVITY_WALL":
        dispatch(cavityWallSlice.actions.addScreenshot(dataURL));
        break;
      // SLOPED ROOF
      case "SLOPED_ROOF":
        dispatch(slopedRoofSlice.actions.addScreenshot(dataURL));
        break;
      // VENTILATED WALL
      case "OUTER_WALL":
        dispatch(ventilatedWallSlice.actions.addScreenshot(dataURL));
        break;
      // FLOOR
      case "FLOOR":
        dispatch(floorSlice.actions.addScreenshot(dataURL));
        break;
      // SOL
      case "SOL":
        dispatch(solSlice.actions.addScreenshot(dataURL));
        break;
    }
    displayScreenshotSavedToast();
  }

  function renderScreenshot(): any {
    switch (applicationType) {
      // FLAT ROOF
      case "FLAT_ROOF":
        return (
          <Screenshotter
            count={flatRoofScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
      // CAVITY WALL
      case "CAVITY_WALL":
        return (
          <Screenshotter
            count={cavityWallScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
      // SLOPED ROOF
      case "SLOPED_ROOF":
        return (
          <Screenshotter
            count={slopedRoofScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
      // VENTILATED WALL
      case "OUTER_WALL":
        return (
          <Screenshotter
            count={ventilatedWallScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
      // FLOOR
      case "FLOOR":
        return (
          <Screenshotter
            count={floorScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
      // SOL
      case "SOL":
        return (
          <Screenshotter
            count={solScreenshots.length}
            handleClick={handleScreenshotClick}
          />
        );
    }
  }

  return <SectionRight>{renderScreenshot()}</SectionRight>;
};

const ScreenshotContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
`;

const ScreenshotCount = styled.div`
  position: absolute;
  right: -0.6rem;
  top: -0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: ${styles.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Screenshotter = (props: { count: number; handleClick: () => void }) => {
  return (
    <ScreenshotContainer>
      <ScreenshotIcon onClick={props.handleClick} />
      {props.count > 0 && (
        <ScreenshotCount>
          <P color={styles.colors.white} fontSizePx={10} fontWeight={500}>
            {props.count}
          </P>
        </ScreenshotCount>
      )}
    </ScreenshotContainer>
  );
};
