// import { Layers } from "@iko-design-center/shared";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../services/screenshot.service";
import { useCavityWall } from "../../../store/cavity-wall/hooks";
import { useModal } from "../../../store/modal/hooks";
import { renderer } from "../../viewer/Renderer";
import { useApplication } from "../../../store/application/hooks";
import { isMobile } from "../../../services/helpers.service";

type VisibleState = {
  wallType: boolean;
  wallInsulation: boolean;
  outerCavityLeaf: boolean;
  flatRoof: boolean;
  slopedRoof: boolean;
  slopedRoofInsulation: boolean;
  slopedRoofVaporShield: boolean;
  slopedRoofConstruction: boolean;
  details: boolean;
};

export function useOverview() {
  const { t } = useTranslation() as any;
  const {
    configurationState: { wallType, wallInsulation, outerCavityLeaf },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
  } = useCavityWall();

  const wallTypeType = wallType.type!;
  const wallInsulationType = wallInsulation.type!;
  const outerCavityLeafType = outerCavityLeaf.type!;
  const wallTypeMaterial = wallType.material!;
  const wallInsulationMaterial = wallInsulation.material!;
  const outerCavityLeafMaterial = outerCavityLeaf.material!;

  const {
    displayCavityWallDownloadModal,
    displayCavityWallThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal();
  const { currentModel, applicationType } = useApplication();

  const initialVisibleState = useMemo(
    () => ({
      wallType: true,
      wallInsulation: true,
      outerCavityLeaf: true,
      flatRoof: true,
      slopedRoof: true,
      slopedRoofInsulation: true,
      slopedRoofVaporShield: true,
      slopedRoofConstruction: true,
      details: true,
    }),
    []
  );
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  );
  const humanReadableName = `${wallType.type} / ${wallInsulation.type} / ${outerCavityLeaf.type}`;

  useEffect(() => {
    setHumanReadableName(humanReadableName);
  }, [humanReadableName, setHumanReadableName]);

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers();
      const dataURL = renderer.createScreenshot();
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL);
      });

      return () => {
        renderer.hideMarkers();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  );

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] });
  }

  useEffect(() => {
    setVisibleState(initialVisibleState);
    if (!isMobile()) renderer.displayMarkers();
    renderer.toggleAllLayers(applicationType, true);
  }, [currentModel, setVisibleState, initialVisibleState, applicationType]);

  function handleWallTypeClick() {
    toggleVisibleState("wallType");
    renderer.toggleLayers("BINNENSPOUWBLAD");
  }
  function handleWallInsulationClick() {
    toggleVisibleState("wallInsulation");
    renderer.toggleLayers("ISOLATIE");
  }

  function handleOuterCavityLeafClick() {
    toggleVisibleState("outerCavityLeaf");
    renderer.toggleLayers("GEVELMETSELWERK");
  }

  function handleFlatRoofClick() {
    toggleVisibleState("flatRoof");
    renderer.toggleLayers("PLAT_DAK");
  }

  function handleSlopedRoofClick(toggle = "slopedRoof", layer = "HELLEND_DAK") {
    toggleVisibleState(toggle as keyof VisibleState);
    renderer.toggleLayers(layer);
  }

  function handleAdjustSpecificationsClick() {
    displayCavityWallThermalCalculationModal();
  }

  function handleDownloadFilesClick() {
    displayCavityWallDownloadModal();
  }

  function handleRequestAdviceClick() {
    displayRequestAdviceModal();
  }

  function handleDetailsClick() {
    toggleVisibleState("details");
    renderer.toggleDetails();
  }

  return {
    t,
    currentModel,
    thermalPerformance,
    visibleState,
    wallTypeType,
    wallTypeMaterial,
    wallInsulationType,
    wallInsulationMaterial,
    outerCavityLeafType,
    outerCavityLeafMaterial,
    displayCavityWallDownloadModal,
    displayRequestAdviceModal,
    displayCavityWallThermalCalculationModal,
    handleWallTypeClick,
    handleWallInsulationClick,
    handleOuterCavityLeafClick,
    handleFlatRoofClick,
    handleSlopedRoofClick,
    handleDetailsClick,
    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,
  };
}
