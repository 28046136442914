import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { styles } from "../../styles";
import { MouseIcon, MouseScrollIcon } from "../common/Icons";
import { P } from "../common/Typography";

const Container = styled.div`
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Top = styled.div`
  padding-top: ${(props) => props.theme.designHeight(18)}px;
  padding-left: ${(props) => props.theme.designWidth(20)}px;
  padding-right: ${(props) => props.theme.designWidth(20)}px;
  padding-bottom: ${(props) => props.theme.designHeight(16)}px;

  border-radius: 5px 5px 0 0;
  background: ${styles.colors.dark2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  height: ${(props) => props.theme.designHeight(2)}px;
`;

const Bottom = styled(Top)`
  border-radius: 0 0 5px 5px;
`;

const Text = styled.span`
  margin-top: ${(props) => props.theme.designHeight(8)}px;
  max-width: ${(props) => props.theme.designWidth(120)}px;
  text-align: center;
`;

export type InstructionProps = {
  handleInput: () => void;
};

const Instructions = ({ handleInput }: InstructionProps) => {
  const { t } = useTranslation() as any;

  return (
    <Container
      onClick={handleInput}
      onScroll={handleInput}
      onKeyPress={handleInput}
    >
      <div>
        <Top>
          <MouseIcon />
          <Text>
            <P color={styles.colors.white}>{t("viewer.mouse")}</P>
          </Text>
        </Top>
        <Spacer />
        <Bottom>
          <MouseScrollIcon />
          <Text>
            <P color={styles.colors.white}>{t("viewer.mouseScroll")}</P>
          </Text>
        </Bottom>
      </div>
    </Container>
  );
};

export { Instructions };
