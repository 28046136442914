import React, { SyntheticEvent, useState } from "react";
import { styles } from "../../styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { P } from "../common/Typography";
import { CaretDownIcon, CaretUpIcon } from "../common/Icons";
import { useRoutes } from "../../store/routes/hooks";
import { useLocation } from "react-router";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 3px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  background: ${styles.colors.white};
  padding: 0 ${(props) => props.theme.designWidth(6)}px;
  position: relative;
  z-index: 3;

  p {
    display: flex;
    align-items: center;
    padding-left: 8px;
    margin-top: 0;
  }

  @media screen and (pointer: coarse) {
    z-index: 1;
  }
`;

const Dropdown = styled.div<{ open: boolean }>`
  padding-top: ${(props) => props.theme.designHeight(12)}px;
  padding-bottom: ${(props) => props.theme.designHeight(6)}px;
  z-index: 2;
  position: absolute;
  border-radius: 5px;
  border: 1px solid ${styles.colors.darkBlue};
  background: ${styles.colors.white};
  top: ${(props) => props.theme.designHeight(8)}px;
  right: -${(props) => props.theme.designHeight(12)}px;
  display: ${(props) => (props.open ? "block" : "none")};

  @media screen and (pointer: coarse) {
    padding: 16px 0 16px 0;
  }
`;

const DropdownItem = styled.div<{ active: boolean }>`
  padding: ${(props) => props.theme.designHeight(12)}px 24px;
  cursor: ${(props) => (props.active ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.active ? styles.colors.darkBlue5 : "transparent"};

  &:hover {
    background-color: ${styles.colors.darkBlue5};
  }

  &.country {
    background-repeat: no-repeat;
    background-position: center left 24px;
    background-size: 20px auto;
    padding-left: 52px;

    p {
      margin-top: 0;
      white-space: nowrap;
    }
  }

  &.be {
    background-image: url("/assets/images/flag-be.svg");
  }

  &.nl {
    background-image: url("/assets/images/flag-nl.svg");
  }

  &.fr {
    background-image: url("/assets/images/flag-fr.svg");
  }

  @media screen and (pointer: coarse) {
    padding-left: 64px !important;
   
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const LanguagePicker = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const [language, country] = i18n.language.split("_");
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const isPreviewPage = location.pathname === Routes.PREVIEW

  function openMenu() {
    setOpen(true);
  }

  function closeMenu() {
    setOpen(false);
  }

  function setCountryAndLanguage(e: SyntheticEvent) {
    e.preventDefault();
    const lang = e.currentTarget.getAttribute("lang") ?? 'nl_be';
    if (lang && lang !== i18n.language) {
      if (!isPreviewPage) {
        window.location.href = `/${lang}`
      } else {
        window.location.href = `${Routes.PREVIEW}?lang=${lang}`
      }
    }
  }

  function getLanguageLabel(lang: string) {
    switch (lang) {
      case "nl_be":
        return "Nederlands (BE)";
      case "fr_be":
        return "Français (BE)";
      case "nl_nl":
        return "Nederlands (NL)";
      case "fr_fr":
        return "Français (FR)";
    }
  }

  return (
    <Container onClick={openMenu} onMouseLeave={closeMenu}>
      <Label>
        <P>
          {!open && <CaretDownIcon color={styles.colors.dark2} />}
          {open && <CaretUpIcon color={styles.colors.dark2} />}
          <span style={{ marginLeft: "8px" }}>
            {language?.toUpperCase()} - {country?.toUpperCase()}
          </span>
        </P>
      </Label>
      <Dropdown open={open}>
        <DropdownItem
          active={i18n.language === "nl_be"}
          onClick={setCountryAndLanguage}
          lang={"nl_be"}
          className={"country be"}
        >
          <P>{getLanguageLabel("nl_be")}</P>
        </DropdownItem>
        <DropdownItem
          active={i18n.language === "fr_be"}
          onClick={setCountryAndLanguage}
          lang={"fr_be"}
          className={"country be"}
        >
          <P>{getLanguageLabel("fr_be")}</P>
        </DropdownItem>
        <DropdownItem
          active={i18n.language === "nl_nl"}
          onClick={setCountryAndLanguage}
          lang={"nl_nl"}
          className={"country nl"}
        >
          <P>{getLanguageLabel("nl_nl")}</P>
        </DropdownItem>
        <DropdownItem
          active={i18n.language === "fr_fr"}
          onClick={setCountryAndLanguage}
          lang={"fr_fr"}
          className={"country fr"}
        >
          <P>{getLanguageLabel("fr_fr")}</P>
        </DropdownItem>
      </Dropdown>
    </Container>
  );
};

export { LanguagePicker };
