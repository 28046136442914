import { SyntheticEvent } from "react";
import { CavityWallLayers } from "@iko-design-center/shared";
import { useTranslation } from "react-i18next";
import {
  allSelectedDownloadState,
  getLayerOptions,
} from "../../../store/cavity-wall/download";
import { useCavityWall } from "../../../store/cavity-wall/hooks";
import { useModal } from "../../../store/modal/hooks";

type ToggleAllOptions = "DOP" | "technicalFiche";

export function useDownload() {
  const { setDownloadState, downloadState } = useCavityWall();
  const { closeModal, displayCavityWallDownloadMailModal } = useModal();
  const { t } = useTranslation();

  function selectAll() {
    setDownloadState(allSelectedDownloadState);
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  const technicalFicheOptions = getLayerOptions({
    t,
    state: downloadState.technicalFiche,
  });
  const DOPOptions = getLayerOptions({
    t,
    state: downloadState.DOP,
  });

  function toggleAll(kind: ToggleAllOptions) {
    switch (kind) {
      case "technicalFiche":
        const technicalFicheChecked =
          downloadState.technicalFiche.wallType &&
          downloadState.technicalFiche.wallInsulation &&
          downloadState.technicalFiche.outerCavityLeaf;

        setDownloadState({
          ...downloadState,
          technicalFiche: {
            wallType: !technicalFicheChecked,
            wallInsulation: !technicalFicheChecked,
            outerCavityLeaf: !technicalFicheChecked,
          },
        });
        break;
      case "DOP":
        const DOPChecked =
          downloadState.DOP.wallType &&
          downloadState.DOP.wallInsulation &&
          downloadState.DOP.outerCavityLeaf;

        setDownloadState({
          ...downloadState,
          DOP: {
            wallType: !DOPChecked,
            wallInsulation: !DOPChecked,
            outerCavityLeaf: !DOPChecked,
          },
        });
        break;
    }
  }

  function toggleLayersOption(kind: ToggleAllOptions, value: CavityWallLayers) {
    switch (kind) {
      case "technicalFiche":
        setDownloadState({
          ...downloadState,
          technicalFiche: {
            ...downloadState.technicalFiche,
            [value]: !downloadState.technicalFiche[value],
          },
        });
        break;
      case "DOP":
        setDownloadState({
          ...downloadState,
          DOP: {
            ...downloadState.DOP,
            [value]: !downloadState.DOP[value],
          },
        });
        break;
    }
  }

  function toggle(
    kind:
      | "specifications"
      | "BIMFile"
      | "screenshots"
      | "thermalCalculation"
      | "processingGuideline"
  ) {
    setDownloadState({
      ...downloadState,
      [kind]: !downloadState[kind],
    });
  }

  function handleDownload() {
    displayCavityWallDownloadMailModal();
  }

  return {
    t,
    handleCardClick,
    closeModal,
    selectAll,
    technicalFicheOptions,
    toggleAll,
    toggleLayersOption,
    DOPOptions,
    downloadState,
    toggle,
    handleDownload,
  };
}
