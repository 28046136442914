import {
  CavityWallConfigurationState,
  FE_MATERIALS,
  cavityWallMaterialMap,
  WallTypeTypes,
  WallInsulationTypes,
  CavityWallThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadState } from "./download";
import { initialCavityWallState } from "./state";
import {
  getThermalPerformance,
  updateThermalLayer,
} from "./thermalPerformance";

export const cavityWallSlice = createSlice({
  name: "cavityWall",
  initialState: initialCavityWallState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialCavityWallState,
        download: state.download,
        screenshots: state.screenshots,
      };
    },
    resetConfigurationToWallType: (state) => {
      const draft = {
        ...initialCavityWallState,
        download: state.download,
        configuration: {
          ...initialCavityWallState.configuration,
          wallType: state.configuration.wallType,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    resetConfigurationToWallInsulation: (state) => {
      const draft = {
        ...initialCavityWallState,
        download: state.download,
        configuration: {
          ...initialCavityWallState.configuration,
          wallType: state.configuration.wallType,
          wallInsulation: state.configuration.wallInsulation,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    setThermalPerformance: (
      state,
      action: PayloadAction<CavityWallThermalPerformanceState>
    ) => {
      state.thermalPerformance = action.payload;
    },
    setWallTypeType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id === cavityWallMaterialMap[action.payload as WallTypeTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          wallType: {
            ...state.configuration.wallType,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setWallInsulationType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          cavityWallMaterialMap[action.payload as WallInsulationTypes]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          wallInsulation: {
            ...state.configuration.wallInsulation,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setWallTypeMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.wallType.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "wallType"
      );
    },
    setWallInsulationMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.wallInsulation.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "wallInsulation"
      );
    },
    setOuterCavityLeafMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload;
      state.configuration.outerCavityLeaf.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "outerCavityLeaf"
      );
    },
    setConfiguration(
      state,
      action: PayloadAction<CavityWallConfigurationState>
    ) {
      state.configuration = action.payload;
      state.thermalPerformance = getThermalPerformance(action.payload);
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload;
      state.thermalPerformance = getThermalPerformance(state.configuration);
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload;
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload];
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload;
    },
  },
});
