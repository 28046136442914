//@ts-ignore
import { Renderer as ThreeRenderer } from "@iko-design-center/renderer";
import "@iko-design-center/renderer/dist/style.css";
import React, { createRef, memo, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  createRendererAPI,
  initialModel,
  Models,
  RendererAPI,
  RendererModels,
  rendererModelsMap,
} from "./renderer.api";
import { useLocation } from "react-router";
import { useRoutes } from "../../store/routes/hooks";

const Container = styled.div`
  &:active {
    cursor: grabbing;
  }

  cursor: grab;
  width: 100%;
  height: 100%;
`;

export let renderer: RendererAPI;

type RendererProps = {
  handleLoadStart: () => void;
  handleModelChange: (model: Models) => void;
  handleProgressChange: (percentage: number) => void;
  handleLoaded: (id: string) => void;
};

const Renderer = ({
  handleLoadStart,
  handleModelChange,
  handleProgressChange,
  handleLoaded,
}: RendererProps) => {
  const ref = createRef<HTMLDivElement>();
  const { i18n } = useTranslation();
  const location = useLocation()
  const { Routes } = useRoutes() as any
  const isPreviewPage = location.pathname === Routes.PREVIEW

  useEffect(() => {
    const country = i18n.language.split('_')[1];
    const language = i18n.getResourceBundle(i18n.language, '');

    const rendererInstance = new ThreeRenderer({
      container: ref.current,
      dir: "/assets/",
      lang: language,
      country: country,
    });

    rendererInstance.events.on("LOAD", (rendererModel: RendererModels) => {
      handleModelChange(rendererModelsMap[rendererModel]);
      document.dispatchEvent(new CustomEvent('layerChange'));
    });

    rendererInstance.events.on("LOAD_PROGRESS", handleProgressChange);
    rendererInstance.events.on("LOAD_START", handleLoadStart);
    rendererInstance.events.on("LOADED", handleLoaded);

    rendererInstance.events.on("MARKER_CLICK", (rendererModel: RendererModels) => {
      handleModelChange(rendererModelsMap[rendererModel]);
      if (rendererModel !== 'dollhouse') {
        renderer.hideMarkers();
      }
      if (isPreviewPage) {
        renderer.displayMarkers();
      }
    });

    renderer = createRendererAPI(rendererInstance);

    switch (location.pathname) {
      // FLAT ROOF
      case Routes.FLAT_ROOF_1_ROOF_TYPE:
        renderer.displayModel(Models.FLAT_ROOF);
        break;
      // CAVITY WALL
      case Routes.CAVITY_WALL_1_WALL_TYPE:
        renderer.displayModel(Models.CAVITY_WALL);
        break;
      // SLOPED ROOF
      case Routes.SLOPED_ROOF_1_ROOF_TYPE:
        renderer.displayModel(Models.SLOPED_ROOF);
        break;
      // VENTILATED WALL
      case Routes.VENTILATED_WALL_1_WALL_TYPE:
        renderer.displayModel(Models.VENTILATED_WALL);
        break;
      // FLOOR
      case Routes.FLOOR_1_FLOOR_HEATING:
        renderer.displayModel(Models.FLOOR);
        break;
      // SOL
      case Routes.SOL_1_INSULATION:
        renderer.displayModel(Models.SOL_SOUSDALLAGE);
        break;
      default:
        renderer.displayModel(initialModel);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container ref={ref} />;
};

export const MemoRenderer = memo(Renderer, () => true);
