"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialSlopedRoofThermalPerformanceState = exports.SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS = exports.SLOPED_ROOF_R_VALUE_RSE = exports.SLOPED_ROOF_R_VALUE_RSI = void 0;
exports.SLOPED_ROOF_R_VALUE_RSI = 0.10;
exports.SLOPED_ROOF_R_VALUE_RSE = 0.04;
exports.SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS = 20;
exports.initialSlopedRoofThermalPerformanceState = {
    UCValue: null,
    RTValue: null,
    roofType: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    roofInsulation: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
};
