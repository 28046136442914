import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useCavityWall } from "../../../store/cavity-wall/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"

export function useWallInsulation() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setWallInsulationType: setStoreWallInsulationType,
  } = useCavityWall()

  const wallInsulationType = configurationState.wallInsulation.type
  const nextButtonDisabled = !wallInsulationType

  function handleNextButtonClick() {
    history.push(Routes.CAVITY_WALL_3_OVERVIEW)
  }

  function setWallInsulationType(type: any) {
    setStoreWallInsulationType(type)
    renderer.displayWallInsulation(type)
  }

  return {
    t,
    wallInsulationType,
    handleNextButtonClick,
    setWallInsulationType,
    nextButtonDisabled,
  }
}
