import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../services/screenshot.service";
import { useFloor } from "../../../store/floor/hooks";
import { useModal } from "../../../store/modal/hooks";
import { renderer } from "../../viewer/Renderer";
import { useApplication } from "../../../store/application/hooks";
import { isMobile } from "../../../services/helpers.service";

type VisibleState = {
  floorType: boolean;
  floorScreed: boolean;
  floorHeating: boolean;
  floorInsulationFoil: boolean;
  floorInsulation: boolean;
  floorInsulationEdge: boolean;
  bearingFloor: boolean;
  details: boolean;
};

export function useOverview() {
  const { t } = useTranslation() as any;
  const {
    configurationState: { floorHeating, floorInsulation, floorScreed },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
  } = useFloor();

  const floorHeatingType = floorHeating.type!;
  const floorInsulationType = floorInsulation.type!;
  const floorScreedType = floorScreed.type!;

  const {
    displayFloorDownloadModal,
    displayFloorThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal();
  const { currentModel, applicationType } = useApplication();

  const initialVisibleState = useMemo(
    () => ({
      floorType: true,
      floorScreed: true,
      floorHeating: true,
      floorInsulationFoil: true,
      floorInsulation: true,
      floorInsulationEdge: true,
      bearingFloor: true,
      details: true,
    }),
    []
  );
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  );
  const humanReadableName = `${floorInsulation.type} / ${floorScreed.type}`;

  useEffect(() => {
    setHumanReadableName(humanReadableName);
  }, [humanReadableName, setHumanReadableName]);

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers();
      const dataURL = renderer.createScreenshot();
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL);
      });

      return () => {
        renderer.hideMarkers();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  );

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] });
  }

  useEffect(() => {
    setVisibleState(initialVisibleState);
    if (!isMobile()) renderer.displayMarkers();
    renderer.toggleAllLayers(applicationType, true);
  }, [currentModel, setVisibleState, initialVisibleState, applicationType]);

  function handleToggleClick(
    toggle = "floorHeating",
    layer = "VLOERVERWARMING"
  ) {
    toggleVisibleState(toggle as keyof VisibleState);
    renderer.toggleLayers(layer);
  }

  function handleDetailsClick() {
    toggleVisibleState("details");
    renderer.toggleDetails();
  }

  function handleAdjustSpecificationsClick() {
    displayFloorThermalCalculationModal();
  }

  function handleDownloadFilesClick() {
    displayFloorDownloadModal();
  }

  function handleRequestAdviceClick() {
    displayRequestAdviceModal();
  }

  return {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    floorHeatingType,
    floorInsulationType,
    floorScreedType,

    handleToggleClick,
    handleDetailsClick,

    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,
  };
}
