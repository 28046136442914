import { RoofFloorTypes } from "@iko-design-center/shared";

export function getRoofFloorTranslations(t: any, type: RoofFloorTypes) {
  switch (type) {
    case RoofFloorTypes.WOOD:
      return t("flatRoof.types.roofFloor.wood");
    case RoofFloorTypes.CONCRETE:
      return t("flatRoof.types.roofFloor.concrete");
    case RoofFloorTypes.STEEL_DECK:
      return t("flatRoof.types.roofFloor.steelDeck");
  }
}
