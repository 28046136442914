import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";
import { TabProps } from "./Tabs";

const Tab = styled.div<{ active: boolean }>`
  height: 100%;
  padding-left: ${(props) => props.theme.designWidth(30)}px;
  padding-right: ${(props) => props.theme.designWidth(30)}px;
  border-bottom: ${(props) =>
    props.active
      ? `4px solid ${styles.colors.primary}`
      : `4px solid ${styles.colors.transparent}`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  margin-left: 5px;
`;

const Button = styled(UnstyledButton)<{ active: boolean; visited: boolean }>`
  cursor: ${(props) => (props.visited ? "pointer" : "unset")};

  @media screen and (pointer: coarse) {
    z-index: ${(props) => (props.active ? "10" : "unset")};
  }
`;

export const RegularTab = ({
  step,
  text,
  active,
  visited,
  onClick,
}: TabProps) => {
  return (
    <Button onClick={onClick} visited={visited} active={active}>
      <Tab active={active}>
        <P
          fontWeight={500}
          color={visited ? styles.colors.dark2 : styles.colors.darkBlue50}
        >{`${step}.`}</P>
        <Text>
          <P
            fontWeight={700}
            color={visited ? styles.colors.dark2 : styles.colors.darkBlue50}
          >
            {text}
          </P>
        </Text>
      </Tab>
    </Button>
  );
};
