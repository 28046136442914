import { useEffect } from "react"
import { useApplication } from "../../../store/application/hooks"
import { Models } from "../../viewer/renderer.api"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useCavityWall } from "../../../store/cavity-wall/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"

export function useWallType() {
  const { currentModel, directLink, setDirectLink } = useApplication()
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setWallTypeType: setWallTypeTypeState,
  } = useCavityWall()

  const wallTypeType = configurationState.wallType.type
  const nextButtonDisabled = !wallTypeType

  useEffect(() => {
    if (renderer && currentModel === Models.DOLLHOUSE && directLink) {
      setDirectLink(false)
      renderer.displayModel(Models.CAVITY_WALL).then(() => renderer.hideMarkers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleNextButtonClick() {
    history.push(Routes.CAVITY_WALL_2_WALL_INSULATION)
  }

  function setWallTypeType(type: any) {
    setWallTypeTypeState(type)
    renderer.displayWallType(type)
  }

  return {
    t,
    wallTypeType,
    handleNextButtonClick,
    setWallTypeType,
    nextButtonDisabled,
  }
}
