import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useSol } from "../../../store/sol/hooks"
import { useRoutes } from "../../../store/routes/hooks"
import { SolConformityTypes } from "@iko-design-center/shared"

export function useSolConformity() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()

  const {
    configurationState,
    setSolConformityType: setSolConformityTypeState,
  } = useSol()

  const solConformityType = configurationState.solConformity.type
  const nextButtonDisabled = !solConformityType

  function handleNextButtonClick() {
    switch (solConformityType) {
      case SolConformityTypes.P1_1_1:
        history.push(Routes.SOL_3_BUILDING_TYPE)
        break
      case SolConformityTypes.P1_1_2:
        history.push(Routes.SOL_4_OVERVIEW)
        break
    }
  }

  function setSolConformityType(type: any) {
    setSolConformityTypeState(type)
    // renderer.displaySolInsulationType(type)
  }

  return {
    t,
    solConformityType,
    setSolConformityType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
