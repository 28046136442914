import { FLAT_ROOF_MINIMUM_INSULATION_THICKNESS } from "@iko-design-center/shared";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { updateInsulationLayer } from "../../../store/flat-roof/thermalPerformance";
import { useModal } from "../../../store/modal/hooks";

export function useThermalCalculation() {
  const {
    configurationState,
    setRoofStructureInsulationMaterial,
    thermalPerformance,
  } = useFlatRoof();
  const { closeModal } = useModal();
  const { t } = useTranslation() as any;

  const [localThermalPerformance, setLocalThermalPerformance] = useState(
    thermalPerformance
  );
  const [localInsulationMaterial, setLocalInsulationMaterial] = useState(
    configurationState.roofStructure.insulationMaterial!
  );
  const [editableThicknessValue, setEditableThicknessValue] = useState(
    thermalPerformance.insulation.thickness!.toString()
  );
  const [editableThicknessValid, setEditableThicknessValid] = useState(true);

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  function handleEditableThicknessChange(
    event: SyntheticEvent<HTMLInputElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setEditableThicknessValue(value);

    const thickness = parseInt(value);
    if (thickness && thickness >= FLAT_ROOF_MINIMUM_INSULATION_THICKNESS) {
      const updatedMaterial = {
        ...localInsulationMaterial,
        thickness,
      };
      setLocalInsulationMaterial(updatedMaterial);
      setLocalThermalPerformance(
        updateInsulationLayer(localThermalPerformance, updatedMaterial)
      );
      setEditableThicknessValid(true);
    } else {
      setEditableThicknessValid(false);
    }
  }

  function handleCancel() {
    closeModal();
  }

  function handleApply() {
    setRoofStructureInsulationMaterial(localInsulationMaterial);
    closeModal();
  }

  return {
    topLayerMaterial: configurationState.roofStructure.topLayerMaterial!,
    topLayerSecuringMethod: configurationState.securingMethods.topLayer!,
    bottomLayerMaterial: configurationState.roofStructure.bottomLayerMaterial!,
    bottomLayerSecuringMethod: configurationState.securingMethods.bottomLayer!,
    insulationMaterial: localInsulationMaterial,
    insulationSecuringMethod: configurationState.securingMethods.insulation!,
    vaporShieldMaterial: configurationState.roofStructure.vaporShieldMaterial!,
    vaporShieldSecuringMethod: configurationState.securingMethods.vaporShield!,
    roofFloorType: configurationState.roofFloor.type!,
    thermalPerformance: localThermalPerformance,
    t,
    handleCardClick,
    handleCancel,
    handleApply,
    editableThicknessValue,
    editableThicknessValid,
    handleEditableThicknessChange,
  };
}
