const colors = {
  primary: "#D52B1E",
  primaryNonHover: "rgba(217, 55, 64, 1)",
  secondaryHover: "rgba(253, 241, 241, 1)",
  white: "#FFFFFF",
  white65: "rgba(255,255,255, .65)",
  black: "#000000",
  darkBlue: "#6E7188",
  darkBlue10: "rgba(110, 113, 136, 0.1)",
  darkBlue10Hex: "#F0F1F3",
  darkBlue30: "rgba(110, 113, 136, 0.3)",
  darkBlue75: "rgba(110, 113, 136, 0.75)",
  darkBlue5: "rgba(110, 113, 136, 0.05)",
  darkBlue50: "rgba(110, 113, 136, 0.50)",
  dark100: "#231F20",
  dark2: "#2E3341",
  dark2Opacity50: "rgba(46, 51, 65, .5)",
  dark3: "#2E3341",
  renderer: "rgba(0, 0, 0, 0.5)",
  formBorder: "rgba(110, 113, 136, 0.2)",
  transparent: "rgba(255, 255, 255, 0)",
  modal: "rgba(0, 0, 0, 0.5)",
  boxShadow: "rgba(0, 0, 0, 0.15)",
  blurModal: "rgba(86, 89, 101, 0.8)",
  viewerNavigationDropdown: "rgba(46, 51, 65, 0.9)",
  viewerNavigationDropdownBorder: "rgba(255, 255, 255, 0.05)",
  buttonHover: "rgba(67, 71, 84)",
};

export { colors };
