import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 999;
  height: 100%;
  width: 100%;
  position: fixed;
  display: block;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(0,0,0,0.2);
`;

const AppBlocker = () => {

  return (
    <Container id="app-blocker">
      <div></div>
    </Container>
  );
};

export { AppBlocker };
