"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialSolThermalPerformanceState = exports.SOL_MINIMUM_INSULATION_THICKNESS = exports.SOL_R_VALUE_RSE = exports.SOL_R_VALUE_RSI = void 0;
exports.SOL_R_VALUE_RSI = 0.17;
exports.SOL_R_VALUE_RSE = 0.04;
exports.SOL_MINIMUM_INSULATION_THICKNESS = 25;
exports.initialSolThermalPerformanceState = {
    UCValue: null,
    RTValue: null,
    solInsulation: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
};
