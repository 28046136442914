import React from "react";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { styles } from "../../styles";
import { Spacer } from "./Spacer";
import { H2 } from "./Typography";

type InfoListProps = {
  title: string;
  info: string[];
};

const InfoText = styled.div`
  display: flex;
  font-size: ${13 / 16}rem;
  color: ${styles.colors.darkBlue};

  @media screen and (pointer: coarse) {
    font-size: 15px;
    line-height: 22px;
    color: #2e3341;
  }
`;

export const InfoList = ({ title, info }: InfoListProps) => {
  return (
    <>
      <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
        {title}
      </H2>
      {!isMobile() && <Spacer heightPx={12} />}
      {info.map((infoText, index) => (
        <div key={index}>
          <InfoText>
            {`\u2022`}
            <Spacer widthPx={!isMobile() ? 4 : 12} />
            {infoText}
          </InfoText>
          {index !== info.length - 1 && <Spacer heightPx={8} />}
        </div>
      ))}
    </>
  );
};
