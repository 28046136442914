import {
  SlopedRoofConfigurationState,
  SlopedRoofThermalPerformanceState,
  initialSlopedRoofConfigurationState,
  initialSlopedRoofThermalPerformanceState,
} from "@iko-design-center/shared";
import { DownloadState, initialDownloadState } from "./download";

export type SlopedRoofState = {
  configuration: SlopedRoofConfigurationState;
  download: DownloadState;
  thermalPerformance: SlopedRoofThermalPerformanceState;
  screenshots: string[];
  overviewScreenshot: string | null;
};

export const initialSlopedRoofState: SlopedRoofState = {
  configuration: initialSlopedRoofConfigurationState,
  download: initialDownloadState,
  thermalPerformance: initialSlopedRoofThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
};
