import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import {
  CalculatorEuroIcon,
  DownloadIcon,
  EyeClosedIcon,
  EyeOpenIcon,
} from "../../common/Icons";
import { OverviewItem, OverviewItemProps } from "../../common/OverviewItem";
import { Spacer } from "../../common/Spacer";
import { ThermalPerformance } from "../../common/ThermalPerformance";
import { AcousticPerformance } from "../../common/AcousticPerformance";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useOverview } from "./overview.hooks";
import {
  SolInsulationTypes,
} from "@iko-design-center/shared";
import { Section } from "../Sidebar";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import {
  getSolAcousticLayerTranslations,
  getSolInsulationMaterialTranslations,
} from "./translations";
import { SolAcousticLayerTypes } from "@iko-design-center/shared";
import { Models } from "../../viewer/renderer.api";
import { SolNoiseLevelTypes } from "@iko-design-center/shared";

const Container = styled.div`
  height: 100%;
  display: block;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (pointer: coarse) {
    padding-bottom: 128px;
  }
`;

const Padding = styled.div`
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${styles.colors.darkBlue10};
`;

const AdjustSpecificationsContainer = styled.div`
  height: ${(props) => props.theme.designHeight(48)}px;
  width: 100%;
  background: ${styles.colors.darkBlue10};
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    text-decoration: underline;
  }
`;

const ButtonIcon = styled.div`
  width: 18px;
`;

const AcousticPerformaceBlock = styled.div`
  @media screen and (pointer: coarse) {
    h2 {
      font-size: 14px;
      line-height: 20px;
    }

    button p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const ThermalPerformaceBlock = styled.div`
  @media screen and (pointer: coarse) {
    h2 {
      font-size: 14px;
      line-height: 20px;
    }

    button p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const Overview = () => {
  const {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    solInsulationType,
    solNoiseLevelType,
    solAcousticLayerType,
    // solConformityType,
    // solBuildingTypeType,

    handleToggleClick,
    handleDetailsClick,

    handleEditNoiseLevel,

    handleDownloadFilesClick,
    handleRequestAdviceClick,
    handleAdjustSpecificationsClick,

    getInsulationThickness,
  } = useOverview();

  let shouldShow = currentModel !== "DOLLHOUSE" && currentModel !== "SOL_SOUSDALLAGE" && currentModel !== "SOL_SOUSCHAPE" && currentModel !== "SOL_DALLEPORTEE";

  // @ts-ignore
  return (
    <Section>
      <Container>
        {!isMobile() ? (
          <Intro>
            <Spacer heightPx={40} />
            <Padding>
              <H1>{t("sol.overview.title")}</H1>
            </Padding>
            <Spacer heightPx={12} />
            <Padding>
              <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
                {t("sol.overview.subtitle")}
              </P>
            </Padding>
            <Spacer heightPx={20} />
          </Intro>
        ) : (
          <Intro>
            <H1>{t("sol.overview.title")}</H1>
            <Spacer heightPx={12} />
            <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
              {t("sol.overview.mobile.subtitle")}
            </P>
          </Intro>
        )}

        <Line />

        {/* Inner cavity leaf */}
        {currentModel !== Models.SOL_PLANE_SOUSCHAPE && currentModel !== Models.SOL_PLANE_SOUSDALLAGE && currentModel !== Models.SOL_PLANE_DALLEPORTEE && <>
          < InteractiveOverviewItem
            onClick={() => handleToggleClick("solInnerCavityLeaf", "BINNENSPOUWBLAD")}
            visible={visibleState.solInnerCavityLeaf}
            title={t("sol.overview.innerCavityLeaf").toUpperCase()}
            text={t("sol.overview.innerCavityLeafValue")}
          />

          <Line />
        </>}

        {/* Screed */}
        <InteractiveOverviewItem
          onClick={() => handleToggleClick("solScreed", "DEKVLOER")}
          visible={visibleState.solScreed}
          title={t("sol.overview.screed").toUpperCase()}
          text={solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE || solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE ? t("sol.overview.dalleportee.screedValue") : t("sol.overview.souschape.screedValue")}
        />

        {/* Top layer */}
        {(solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE || solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE) && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("solTopLayer", "TOPLAAG")}
            visible={visibleState.solTopLayer}
            title={t("sol.overview.topLayer").toUpperCase()}
            text={t("sol.overview.topLayerValue")}
          />

          <Line />
        </>}

        {/* Heating */}
        {solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("solHeating", "VLOERVERWARMING")}
            visible={visibleState.solHeating}
            title={t("sol.overview.solHeating").toUpperCase()}
            text={t("sol.overview.souschape.solHeatingValue")}
          />

          <Line />
        </>}

        {/* Insulation */}
        <InteractiveOverviewItem
          onClick={() => handleToggleClick("solInsulation", "ISOLATIE")}
          visible={visibleState.solInsulation}
          title={t("sol.overview.solInsulation").toUpperCase()}
          text={getSolInsulationMaterialTranslations(t, solInsulationType)}
          suffix={getInsulationThickness()}
        />

        <Line />

        {/* Insulation edge */}
        {solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("solInsulationEdge", "RANDISOLATIE")}
            visible={visibleState.solInsulationEdge}
            title={t("sol.overview.solInsulationEdge").toUpperCase()}
            text={t("sol.overview.souschape.solInsulationEdgeValue")}
          />

          <Line />
        </>}

        {/* Acoustic layer */}
        {(solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG && solAcousticLayerType !== SolAcousticLayerTypes.AUCUNE) && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("solAcousticLayer", "AKOESTISCHE_LAAG")}
            visible={visibleState.solAcousticLayer}
            title={t("sol.overview.solAcousticLayer").toUpperCase()}
            text={getSolAcousticLayerTranslations(t, solAcousticLayerType)}
          />

          <Line />
        </>}

        {/* Bottom layer */}
        <InteractiveOverviewItem
          onClick={() => handleToggleClick("solBottomLayer", "ONDERLAAG")}
          visible={visibleState.solBottomLayer}
          title={t("sol.overview.bottomLayer").toUpperCase()}
          text={t("sol.overview.bottomLayerValue")}
        />

        <Line />

        {/* Floor type */}
        <InteractiveOverviewItem
          onClick={() => handleToggleClick("solType", "ONDERGROND")}
          visible={visibleState.solType}
          title={solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG ? t("sol.overview.bearingFloor").toUpperCase() : t("sol.overview.solType").toUpperCase()}
          text={solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG ? t("sol.overview.souschape.bearingFloorValue") : t("sol.overview.dalleportee.solTypeValue")}
        />

        <Line />

        {/* Bearing floor */}
        {solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG && currentModel !== Models.SOL_PLANE_SOUSCHAPE && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("solBearingFloor", "DRAAGVLOER")}
            visible={visibleState.solBearingFloor}
            title={solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG ? t("sol.overview.solType").toUpperCase() : t("sol.overview.bearingFloor").toUpperCase()}
            text={solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG ? t("sol.overview.souschape.solTypeValue") : t("sol.overview.dalleportee.bearingFloorValue")}
          />

          <Line />
        </>}

        {/* Details */}
        {!shouldShow && (
          <InteractiveOverviewItem
            onClick={handleDetailsClick}
            visible={visibleState.details}
            title={t("sol.overview.detailsTitle").toUpperCase()}
            text={t("sol.overview.detailsText")}
          />
        )}
        <Line />

        {(solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG && solNoiseLevelType !== SolNoiseLevelTypes.AUCUNE) && <>
          <Spacer heightPx={24} />
          <Padding>
            <AcousticPerformaceBlock>
              <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
                {t("sol.overview.acousticPerformance.title")}
              </H2>
              <Spacer heightPx={8} />
              <AcousticPerformance noiseLevelType={solNoiseLevelType} acousticLayer={solAcousticLayerType} thickness={thermalPerformance.solInsulation.thickness} />
              <AdjustSpecificationsContainer
                onClick={handleEditNoiseLevel}
              >
                <UnstyledButton>
                  <P fontWeight={500} fontSizePx={13}>
                    {t("sol.overview.acousticPerformance.edit")}
                  </P>
                </UnstyledButton>
              </AdjustSpecificationsContainer>
            </AcousticPerformaceBlock>
          </Padding>
        </>}

        <Spacer heightPx={24} />
        <Padding>
          <ThermalPerformaceBlock>
            <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
              {t("flatRoof.overview.thermalPerformance")}
            </H2>
            <Spacer heightPx={8} />
            <ThermalPerformance
              insulationThickness={
                thermalPerformance.solInsulation.thickness!
              }
              UCValue={thermalPerformance.UCValue!}
              RTValue={thermalPerformance.RTValue!}
              background={true}
            />
            <AdjustSpecificationsContainer
              onClick={handleAdjustSpecificationsClick}
            >
              <UnstyledButton>
                <P fontWeight={500} fontSizePx={13}>
                  {t("flatRoof.overview.adjustSpecifications")}
                </P>
              </UnstyledButton>
            </AdjustSpecificationsContainer>
          </ThermalPerformaceBlock>
          <Spacer heightPx={24} />
          <DownloadButton
            onClick={handleDownloadFilesClick}
            className={"download-button"}
          >
            <P fontSizePx={14} color={styles.colors.white}>
              {t("flatRoof.overview.downloadFiles")}
            </P>
            <ButtonIcon>
              <DownloadIcon />
            </ButtonIcon>
          </DownloadButton>
          <Spacer heightPx={8} />
          <AdviceButton
            onClick={handleRequestAdviceClick}
            className={"advice-button"}
          >
            <P fontSizePx={14} color={styles.colors.primary}>
              {t("flatRoof.overview.requestAdvice")}
            </P>
            <ButtonIcon>
              <CalculatorEuroIcon />
            </ButtonIcon>
          </AdviceButton>
          <Spacer heightPx={40} />
        </Padding>
      </Container>
    </Section>
  );
};

const DownloadButton = styled.button`
  &:hover {
    background: ${styles.colors.primaryNonHover};
  }

  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  padding-left: ${(props) => props.theme.designWidth(16)}px;
  padding-right: ${(props) => props.theme.designWidth(29.2)}px;
  width: 100%;
  background: ${styles.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border-width: 0;

  @media screen and (pointer: coarse) {
    &.download-button {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${styles.colors.primary};
      border: none;
      border-radius: unset;
      padding: 24px 0;
      gap: 32px;

      * {
        font-size: 20px;
        line-height: 23px;
      }

      svg {
        margin-top: 6px;
      }
    }

    &.advice-button {
      padding: 16px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      * {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`;

const AdviceButton = styled(DownloadButton)`
  &:hover {
    border: 1px solid ${styles.colors.primaryNonHover};
    background: ${styles.colors.primaryNonHover};

    p {
      color: ${styles.colors.white};
    }

    svg > path {
      fill: ${styles.colors.white};
    }
  }

  border: 1px solid ${styles.colors.primaryNonHover};
  background: ${styles.colors.white};
`;

type OverviewMaterialItemProps = {
  visible: boolean;
  onClick: () => void;
  disabled?: boolean;
} & OverviewItemProps;

const OverviewItemContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};

  @media screen and (pointer: coarse) {
    pointer-events: none;
  }
`;

const WidthContainer = styled.div<{ suffix: string | undefined }>`
  width: 85%;

  @media screen and (pointer: coarse) {
    padding: 0 16px;

    * {
      font-size: 16px;
      line-height: 24px;

      p {
        margin-top: 0;

        &:first-of-type {
          padding-right: 6px;
          margin-right: 6px;
          border-right: ${(props) =>
    props.suffix ? `1px solid ${styles.colors.primary}` : ""};
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    h2 {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const InteractiveOverviewItem = ({
  visible,
  onClick,
  title,
  text,
  suffix,
  disabled = false,
}: OverviewMaterialItemProps) => {
  return (
    <OverviewItemContainer
      onClick={() => (!disabled ? onClick() : null)}
      disabled={disabled}
    >
      {!isMobile() && (!visible ? <EyeClosedIcon /> : <EyeOpenIcon />)}
      {!isMobile() && <Spacer widthPx={16} />}
      <WidthContainer suffix={suffix}>
        <OverviewItem title={title} text={text} suffix={suffix} />
      </WidthContainer>
    </OverviewItemContainer>
  );
};
