import {
  FEMaterial,
  FlatRoofConfigurationState,
  initialFlatRoofThermalPerformanceState,
  Layers,
  FLAT_ROOF_R_VALUE_RSI,
  FLAT_ROOF_R_VALUE_RSE,
  ThermalPerformanceLayer,
  FlatRoofThermalPerformanceState,
} from "@iko-design-center/shared";

export function updateInsulationLayer(
  thermalPerformance: FlatRoofThermalPerformanceState,
  insulationMaterial: FEMaterial
) {
  const draft: FlatRoofThermalPerformanceState = { ...thermalPerformance };
  draft.insulation = getThermalPerformanceLayer(insulationMaterial);
  return calculateThermalPerformance(draft);
}

export function getThermalPerformance(
  state: FlatRoofConfigurationState
): FlatRoofThermalPerformanceState {
  const thermalPerformance = { ...initialFlatRoofThermalPerformanceState };

  thermalPerformance.topLayer = getThermalPerformanceLayer(
    state.roofStructure.topLayerMaterial!
  );
  thermalPerformance.bottomLayer = getThermalPerformanceLayer(
    state.roofStructure.bottomLayerMaterial!
  );
  thermalPerformance.insulation = getThermalPerformanceLayer(
    state.roofStructure.insulationMaterial!
  );
  thermalPerformance.vaporShield = getThermalPerformanceLayer(
    state.roofStructure.vaporShieldMaterial!
  );
  thermalPerformance.roofFloor = getThermalPerformanceLayer(
    state.roofStructure.roofFloorMaterial!
  );

  return calculateThermalPerformance(thermalPerformance);
}

function calculateThermalPerformance(
  state: FlatRoofThermalPerformanceState
): FlatRoofThermalPerformanceState {
  const draft = { ...state };
  let isComplete = true;

  for (let l in Layers) {
    // @ts-ignore
    const { thickness, lambda } = draft[l] as ThermalPerformanceLayer;

    if (thickness && lambda) {
      // @ts-ignore
      draft[l] = {
        thickness,
        lambda,
        RValue: parseFloat((thickness / 1000 / lambda).toFixed(2)),
      };
    } else {
      isComplete = false;
    }
  }

  if (isComplete) {
    draft.RTValue = parseFloat(
      (
        draft.topLayer.RValue! +
        draft.bottomLayer.RValue! +
        draft.insulation.RValue! +
        draft.vaporShield.RValue! +
        draft.roofFloor.RValue! +
        FLAT_ROOF_R_VALUE_RSE +
        FLAT_ROOF_R_VALUE_RSI  -
        0.14
      ).toFixed(1)
    );
    draft.UCValue = parseFloat((1 / draft.RTValue).toFixed(2));
  }
  return draft;
}

function getThermalPerformanceLayer(
  material: FEMaterial
): ThermalPerformanceLayer {
  const isVariable =
    material.lambdaThicknessThresholds &&
    material.lambdaThicknessThresholds.length > 0 &&
    material.lambdas.length > 1;

  const thickness = material.thickness;
  let lambda: number;

  if (!isVariable) {
    lambda = material.lambdas[0];
  } else {
    material.lambdaThicknessThresholds!.forEach((threshold, index) => {
      if (thickness > threshold) {
        lambda = material.lambdas[index + 1];
      }
    });
  }

  return {
    thickness,
    lambda: lambda!,
    RValue: null,
  };
}
