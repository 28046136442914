import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useFloor } from "../../../store/floor/hooks";
import { renderer } from "../../viewer/Renderer";
import { useRoutes } from "../../../store/routes/hooks";

export function useFloorInsulation() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any;
  const history = useHistory();

  const {
    configurationState,
    setFloorInsulationType: setFloorInsulationTypeState,
  } = useFloor();

  const floorInsulationType = configurationState.floorInsulation.type;
  const nextButtonDisabled = !floorInsulationType;

  function handleNextButtonClick() {
    history.push(Routes.FLOOR_3_FLOOR_SCREED);
  }

  function setFloorInsulationType(type: any) {
    setFloorInsulationTypeState(type);
    renderer.displayFloorInsulationType(type);
  }

  return {
    t,
    floorInsulationType,
    setFloorInsulationType,
    handleNextButtonClick,
    nextButtonDisabled,
  };
}
