import React from "react";
import styled from "styled-components";
import { useToast } from "../../store/toast/hooks";
import { ToastDisplayTypes } from "../../store/toast/store";
import { ScreenshotSaved } from "./ScreenshotSaved";

const Container = styled.div`
  z-index: 3;
  position: fixed;
  left: 20px;
  bottom: 20px;
`;

const map = new Map();
map.set(ToastDisplayTypes.SCREENSHOT_SAVED, ScreenshotSaved);

export const Toast = () => {
  const { display } = useToast();

  const Component = map.get(display);

  if (display !== ToastDisplayTypes.NONE) {
    return (
      <Container>
        <Component />
      </Container>
    );
  }

  return null;
};
