import {
  SolConfigurationState,
  SolThermalPerformanceState,
  SolInsulationTypes,
  SolMaterialMap,
  FE_MATERIALS,
  FEMaterial,
} from "@iko-design-center/shared"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DownloadState } from "./download"
import { initialSolState } from "./state"
import { getThermalPerformance, updateThermalLayer } from "./thermalPerformance"

export const solSlice = createSlice({
  name: "sol",
  initialState: initialSolState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialSolState,
        download: state.download,
        screenshots: state.screenshots,
      }
    },
    resetConfigurationToInsulation: (state) => {
      const draft = {
        ...initialSolState,
        download: state.download,
        configuration: {
          ...initialSolState.configuration,
          solInsulation: state.configuration.solInsulation,
        },
        screenshots: state.screenshots,
      }
      return draft
    },
    resetConfigurationToNoiseLevel: (state) => {
      const draft = {
        ...initialSolState,
        download: state.download,
        configuration: {
          ...initialSolState.configuration,
          solInsulation: state.configuration.solInsulation,
          solNoiseLevel: state.configuration.solNoiseLevel,
        },
        screenshots: state.screenshots,
      }
      return draft
    },
    resetConfigurationToAcousticLayer: (state) => {
      const draft = {
        ...initialSolState,
        download: state.download,
        configuration: {
          ...initialSolState.configuration,
          solInsulation: state.configuration.solInsulation,
          solNoiseLevel: state.configuration.solNoiseLevel,
          solAcousticLayer: state.configuration.solAcousticLayer,
        },
        screenshots: state.screenshots,
      }
      return draft
    },
    resetConfigurationToConformity: (state) => {
      const draft = {
        ...initialSolState,
        download: state.download,
        configuration: {
          ...initialSolState.configuration,
          solInsulation: state.configuration.solInsulation,
          solConformity: state.configuration.solConformity,
        },
        screenshots: state.screenshots,
      }
      return draft
    },
    resetConfigurationToBuildingType: (state) => {
      const draft = {
        ...initialSolState,
        download: state.download,
        configuration: {
          ...initialSolState.configuration,
          solInsulation: state.configuration.solInsulation,
          solConformity: state.configuration.solConformity,
          solBuildingType: state.configuration.solBuildingType,
        },
        screenshots: state.screenshots,
      }
      return draft
    },

    setThermalPerformance: (
      state,
      action: PayloadAction<SolThermalPerformanceState>
    ) => {
      state.thermalPerformance = action.payload
    },
    setSolInsulationType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id === SolMaterialMap[action.payload as SolInsulationTypes]
      )!
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          solInsulation: {
            ...state.configuration.solInsulation,
            type: action.payload,
            material: material,
          },
        },
      }
      return draft
    },
    setSolNoiseLevelType: (state, action: PayloadAction<any>) => {
      // const material = FE_MATERIALS.find(
      //   (material) =>
      //     material.id === SolMaterialMap[action.payload as SolNoiseLevelTypes]
      // )!
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          solNoiseLevel: {
            ...state.configuration.solNoiseLevel,
            type: action.payload,
            // material: material,
            material: null,
          },
        },
      }
      return draft
    },
    setSolAcousticLayerType: (state, action: PayloadAction<any>) => {
      // const material = FE_MATERIALS.find(
      //   (material) =>
      //     material.id === SolMaterialMap[action.payload as SolNoiseLevelTypes]
      // )!
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          solAcousticLayer: {
            ...state.configuration.solAcousticLayer,
            type: action.payload,
            // material: material,
            material: null,
          },
        },
      }
      return draft
    },
    setSolConformityType: (state, action: PayloadAction<any>) => {
      // const material = FE_MATERIALS.find(
      //   (material) =>
      //     material.id === SolMaterialMap[action.payload as SolNoiseLevelTypes]
      // )!
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          solConformity: {
            ...state.configuration.solConformity,
            type: action.payload,
            // material: material,
            material: null,
          },
        },
      }
      return draft
    },
    setSolBuildingTypeType: (state, action: PayloadAction<any>) => {
      // const material = FE_MATERIALS.find(
      //   (material) =>
      //     material.id === SolMaterialMap[action.payload as SolNoiseLevelTypes]
      // )!
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          solBuildingType: {
            ...state.configuration.solBuildingType,
            type: action.payload,
            // material: material,
            material: null,
          },
        },
      }
      return draft
    },

    setSolInsulationMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.solInsulation.material = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "solInsulation"
      )
    },

    setConfiguration(state, action: PayloadAction<SolConfigurationState>) {
      state.configuration = action.payload
      state.thermalPerformance = getThermalPerformance(action.payload)
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload
      state.thermalPerformance = getThermalPerformance(state.configuration)
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload]
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload
    },
  },
})
