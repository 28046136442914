import { Layers, RoofDetails } from "@iko-design-center/shared";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  allSelectedDownloadState,
  getLayerOptions,
  getRoofDetailOptions,
} from "../../../store/flat-roof/download";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { useModal } from "../../../store/modal/hooks";

type ToggleAllOptions = "DOP" | "technicalFiche" | "roofDetails";

export function useDownload() {
  const { setDownloadState, downloadState } = useFlatRoof();
  const { closeModal, displayFlatRoofDownloadMailModal } = useModal();
  const { t } = useTranslation();

  function selectAll() {
    setDownloadState(allSelectedDownloadState);
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  const technicalFicheOptions = getLayerOptions({
    t,
    state: downloadState.technicalFiche,
  });
  const DOPOptions = getLayerOptions({
    t,
    state: downloadState.DOP,
  });
  const roofDetailOptions = getRoofDetailOptions({
    t,
    state: downloadState.roofDetails,
  });

  function toggleAll(kind: ToggleAllOptions) {
    switch (kind) {
      case "technicalFiche":
        const technicalFicheChecked =
          downloadState.technicalFiche.topLayer &&
          downloadState.technicalFiche.bottomLayer &&
          downloadState.technicalFiche.insulation &&
          downloadState.technicalFiche.vaporShield &&
          downloadState.technicalFiche.roofFloor;

        setDownloadState({
          ...downloadState,
          technicalFiche: {
            topLayer: !technicalFicheChecked,
            bottomLayer: !technicalFicheChecked,
            insulation: !technicalFicheChecked,
            vaporShield: !technicalFicheChecked,
            roofFloor: !technicalFicheChecked,
          },
        });
        break;
      case "DOP":
        const DOPChecked =
          downloadState.DOP.topLayer &&
          downloadState.DOP.bottomLayer &&
          downloadState.DOP.insulation &&
          downloadState.DOP.vaporShield &&
          downloadState.DOP.roofFloor;

        setDownloadState({
          ...downloadState,
          DOP: {
            topLayer: !DOPChecked,
            bottomLayer: !DOPChecked,
            insulation: !DOPChecked,
            vaporShield: !DOPChecked,
            roofFloor: !DOPChecked,
          },
        });
        break;
      case "roofDetails":
        const roofDetailsChecked =
          downloadState.roofDetails.roofSurface &&
          downloadState.roofDetails.roofUpstand &&
          downloadState.roofDetails.drainagePipe &&
          downloadState.roofDetails.dome &&
          downloadState.roofDetails.angleConnection &&
          downloadState.roofDetails.settlementJoint &&
          downloadState.roofDetails.verticalWall;

        setDownloadState({
          ...downloadState,
          roofDetails: {
            roofSurface: !roofDetailsChecked,
            roofUpstand: !roofDetailsChecked,
            drainagePipe: !roofDetailsChecked,
            dome: !roofDetailsChecked,
            angleConnection: !roofDetailsChecked,
            settlementJoint: !roofDetailsChecked,
            verticalWall: !roofDetailsChecked,
          },
        });
        break;
    }
  }

  function toggleLayersOption(kind: ToggleAllOptions, value: Layers) {
    switch (kind) {
      case "technicalFiche":
        setDownloadState({
          ...downloadState,
          technicalFiche: {
            ...downloadState.technicalFiche,
            [value]: !downloadState.technicalFiche[value],
          },
        });
        break;
      case "DOP":
        setDownloadState({
          ...downloadState,
          DOP: {
            ...downloadState.DOP,
            [value]: !downloadState.DOP[value],
          },
        });
        break;
    }
  }

  function toggleRoofDetailsOption(value: RoofDetails) {
    setDownloadState({
      ...downloadState,
      roofDetails: {
        ...downloadState.roofDetails,
        [value]: !downloadState.roofDetails[value],
      },
    });
  }

  function toggle(
    kind: "specifications" | "BIMFile" | "screenshots" | "thermalCalculation" | "processingGuideline"
  ) {
    setDownloadState({
      ...downloadState,
      [kind]: !downloadState[kind],
    });
  }

  function handleDownload() {
    displayFlatRoofDownloadMailModal();
  }

  return {
    t,
    handleCardClick,
    closeModal,
    selectAll,
    technicalFicheOptions,
    toggleAll,
    toggleLayersOption,
    DOPOptions,
    downloadState,
    toggle,
    roofDetailOptions,
    toggleRoofDetailsOption,
    handleDownload,
  };
}
