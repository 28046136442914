import {
  VentilatedWallConfigurationState,
  VentilatedWallThermalPerformanceState,
  ventilatedWallMaterialMap,
  VentilatedWallWallTypeTypes,
  VentilatedWallFacadeCladdingTypes,
  VentilatedWallInsulationTypes,
  FE_MATERIALS,
  FEMaterial,
} from "@iko-design-center/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadState } from "./download";
import { initialVentilatedWallState } from "./state";
import {
  getThermalPerformance,
  updateThermalLayer,
} from "./thermalPerformance";

export const ventilatedWallSlice = createSlice({
  name: "ventilatedWall",
  initialState: initialVentilatedWallState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialVentilatedWallState,
        download: state.download,
        screenshots: state.screenshots,
      };
    },
    resetConfigurationToWallType: (state) => {
      const draft = {
        ...initialVentilatedWallState,
        download: state.download,
        configuration: {
          ...initialVentilatedWallState.configuration,
          wallType: state.configuration.wallType,
          wallInsulation: state.configuration.wallInsulation,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    resetConfigurationToFacadeCladding: (state) => {
      const draft = {
        ...initialVentilatedWallState,
        download: state.download,
        configuration: {
          ...initialVentilatedWallState.configuration,
          wallType: state.configuration.wallType,
          wallInsulation: state.configuration.wallInsulation,
          facadeCladding: state.configuration.facadeCladding,
        },
        screenshots: state.screenshots,
      };
      return draft;
    },
    setThermalPerformance: (
      state,
      action: PayloadAction<VentilatedWallThermalPerformanceState>
    ) => {
      state.thermalPerformance = action.payload;
    },
    setVentilatedWallWallType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          ventilatedWallMaterialMap[
            action.payload as VentilatedWallWallTypeTypes
          ]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          wallType: {
            ...state.configuration.wallType,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setVentilatedWallFacadeCladdingType: (
      state,
      action: PayloadAction<any>
    ) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          ventilatedWallMaterialMap[
            action.payload as VentilatedWallFacadeCladdingTypes
          ]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          facadeCladding: {
            ...state.configuration.facadeCladding,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setVentilatedWallInsulationType: (state, action: PayloadAction<any>) => {
      const material = FE_MATERIALS.find(
        (material) =>
          material.id ===
          ventilatedWallMaterialMap[
            action.payload as VentilatedWallInsulationTypes
          ]
      )!;
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          wallInsulation: {
            ...state.configuration.wallInsulation,
            type: action.payload,
            material: material,
          },
        },
      };
      return draft;
    },
    setVentilatedWallWallTypeMaterial: (
      state,
      action: PayloadAction<FEMaterial>
    ) => {
      const material = action.payload;
      state.configuration.wallType.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "wallType"
      );
    },
    setVentilatedWallFacadeCladdingMaterial: (
      state,
      action: PayloadAction<FEMaterial>
    ) => {
      const material = action.payload;
      state.configuration.facadeCladding.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "facadeCladding"
      );
    },
    setVentilatedWallInsulationMaterial: (
      state,
      action: PayloadAction<FEMaterial>
    ) => {
      const material = action.payload;
      state.configuration.wallInsulation.material = material;
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "wallInsulation"
      );
    },
    setConfiguration(
      state,
      action: PayloadAction<VentilatedWallConfigurationState>
    ) {
      state.configuration = action.payload;
      state.thermalPerformance = getThermalPerformance(action.payload);
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload;
      state.thermalPerformance = getThermalPerformance(state.configuration);
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload;
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload];
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload;
    },
  },
});
