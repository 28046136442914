import {
  SolInsulationTypes,
  SolNoiseLevelTypes,
  SolAcousticLayerTypes,
  SolConformityTypes,
  SolBuildingTypeTypes,
} from "@iko-design-center/shared"

export function getSolInsulationTranslations(t: any, type: SolInsulationTypes) {
  switch (type) {
    case SolInsulationTypes.ENERTHERM_CHAPE_TG:
      return t("sol.solInsulation.CHAPETG")
    case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
      return t("sol.solInsulation.KRALU_SOUSDALLAGE")
    case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
      return t("sol.solInsulation.KRALU_DALLEPORTEE")
  }
}
export function getSolInsulationMaterialTranslations(t: any, type: SolInsulationTypes) {
  switch (type) {
    case SolInsulationTypes.ENERTHERM_CHAPE_TG:
      return t("material.ENERTHERM_CHAPE_TG")
    case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
      return t("material.ENERTHERM_KR_ALU_SOUSDALLAGE")
    case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
      return t("material.ENERTHERM_KR_ALU_DALLEPORTEE")
  }
}

export function getSolNoiseLevelTranslations(t: any, type: SolNoiseLevelTypes) {
  switch (type) {
    case SolNoiseLevelTypes.DB_18:
      return t("sol.solNoiseLevel.18db")
    case SolNoiseLevelTypes.DB_19:
      return t("sol.solNoiseLevel.19db")
    case SolNoiseLevelTypes.DB_20:
      return t("sol.solNoiseLevel.20db")
    case SolNoiseLevelTypes.DB_22:
      return t("sol.solNoiseLevel.22db")
    case SolNoiseLevelTypes.DB_23:
      return t("sol.solNoiseLevel.23db")
    case SolNoiseLevelTypes.AUCUNE:
      return t("sol.solNoiseLevel.aucune")
  }
}

export function getSolAcousticLayerTranslations(
  t: any,
  type: SolAcousticLayerTypes
) {
  switch (type) {
    case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
      return t("sol.solAcousticLayer.TRAMICHAPE_ECO_PRO")
    case SolAcousticLayerTypes.ASSOURCHAPE_20:
      return t("sol.solAcousticLayer.ASSOURCHAPE_20")
    case SolAcousticLayerTypes.AUCUNE:
      return t("sol.solAcousticLayer.aucune")
  }
}

export function getSolConformityTranslations(t: any, type: SolConformityTypes) {
  switch (type) {
    case SolConformityTypes.P1_1_1:
      return SolConformityTypes.P1_1_1
    case SolConformityTypes.P1_1_2:
      return SolConformityTypes.P1_1_2
  }
}

export function getSolBuildingTypeTranslations(
  t: any,
  type: SolBuildingTypeTypes
) {
  switch (type) {
    case SolBuildingTypeTypes.OUI:
      return t("sol.solBuildingType.oui")
    case SolBuildingTypeTypes.NON:
      return t("sol.solBuildingType.non")
  }
}
