import { createSlice } from "@reduxjs/toolkit";

export enum ModalDisplayTypes {
  NONE = "NONE",
  COUNTRY_AND_LANGUAGE = "LANGUAGE",
  SUPPORT = "SUPPORT",
  SUPPORT_SUCCESS = "SUPPORT_SUCCESS",
  CLIMATE_CLASS = "CLIMATE_CLASS",
  DETAIL_INFO = "DETAIL_INFO",
  REQUEST_ADVICE = "REQUEST_ADVICE",
  REQUEST_ADVICE_SUCCESS = "REQUEST_ADVICE_SUCCESS",

  // FLAT ROOF
  FLAT_ROOF_SPECIFICATIONS = "FLAT_ROOF_SPECIFICATIONS",
  DOWNLOAD_FLAT_ROOF = "DOWNLOAD_FLAT_ROOF",
  DOWNLOAD_MAIL_FLAT_ROOF = "DOWNLOAD_MAIL_FLAT_ROOF",
  DOWNLOAD_SUCCESS_FLAT_ROOF = "DOWNLOAD_SUCCESS_FLAT_ROOF",

  // CAVITY WALL
  CAVITY_WALL_SPECIFICATIONS = "CAVITY_WALL_SPECIFICATIONS",
  DOWNLOAD_CAVITY_WALL = "DOWNLOAD_CAVITY_WALL",
  DOWNLOAD_MAIL_CAVITY_WALL = "DOWNLOAD_MAIL_CAVITY_WALL",
  DOWNLOAD_SUCCESS_CAVITY_WALL = "DOWNLOAD_SUCCESS_CAVITY_WALL",

  // SLOPED ROOF
  SLOPED_ROOF_SPECIFICATIONS = "SLOPED_ROOF_SPECIFICATIONS",
  DOWNLOAD_SLOPED_ROOF = "DOWNLOAD_SLOPED_ROOF",
  DOWNLOAD_MAIL_SLOPED_ROOF = "DOWNLOAD_MAIL_SLOPED_ROOF",
  DOWNLOAD_SUCCESS_SLOPED_ROOF = "DOWNLOAD_SUCCESS_SLOPED_ROOF",

  // VENTILATED WALL
  VENTILATED_WALL_SPECIFICATIONS = "VENTILATED_WALL_SPECIFICATIONS",
  DOWNLOAD_VENTILATED_WALL = "DOWNLOAD_VENTILATED_WALL",
  DOWNLOAD_MAIL_VENTILATED_WALL = "DOWNLOAD_MAIL_VENTILATED_WALL",
  DOWNLOAD_SUCCESS_VENTILATED_WALL = "DOWNLOAD_SUCCESS_VENTILATED_WALL",

  // FLOOR
  FLOOR_SPECIFICATIONS = "FLOOR_SPECIFICATIONS",
  DOWNLOAD_FLOOR = "DOWNLOAD_FLOOR",
  DOWNLOAD_MAIL_FLOOR = "DOWNLOAD_MAIL_FLOOR",
  DOWNLOAD_SUCCESS_FLOOR = "DOWNLOAD_SUCCESS_FLOOR",

  // SOL
  SOL_SPECIFICATIONS = "SOL_SPECIFICATIONS",
  DOWNLOAD_SOL = "DOWNLOAD_SOL",
  DOWNLOAD_MAIL_SOL = "DOWNLOAD_MAIL_SOL",
  DOWNLOAD_SUCCESS_SOL = "DOWNLOAD_SUCCESS_SOL",
}

export type ModalState = {
  display: ModalDisplayTypes;
};

const initialState: ModalState = {
  display: ModalDisplayTypes.NONE,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.display = ModalDisplayTypes.NONE;
    },
    displayCountryAndLanguageModal: (state) => {
      state.display = ModalDisplayTypes.COUNTRY_AND_LANGUAGE;
    },
    displaySupportModal: (state) => {
      state.display = ModalDisplayTypes.SUPPORT;
    },
    displaySupportSuccessModal: (state) => {
      state.display = ModalDisplayTypes.SUPPORT_SUCCESS;
    },
    displayClimateClassModal: (state) => {
      state.display = ModalDisplayTypes.CLIMATE_CLASS;
    },
    displayDetailInfoModal: (state) => {
      state.display = ModalDisplayTypes.DETAIL_INFO;
    },
    displayRequestAdviceModal: (state) => {
      state.display = ModalDisplayTypes.REQUEST_ADVICE;
    },
    displayRequestAdviceSuccessModal: (state) => {
      state.display = ModalDisplayTypes.REQUEST_ADVICE_SUCCESS;
    },

    // FLAT ROOF
    displayFlatRoofThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.FLAT_ROOF_SPECIFICATIONS;
    },
    displayFlatRoofDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_FLAT_ROOF;
    },
    displayFlatRoofDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_FLAT_ROOF;
    },
    displayFlatRoofDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_FLAT_ROOF;
    },

    // CAVITY WALL
    displayCavityWallThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.CAVITY_WALL_SPECIFICATIONS;
    },
    displayCavityWallDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_CAVITY_WALL;
    },
    displayCavityWallDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_CAVITY_WALL;
    },
    displayCavityWallDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_CAVITY_WALL;
    },

    // SLOPED ROOF
    displaySlopedRoofThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.SLOPED_ROOF_SPECIFICATIONS;
    },
    displaySlopedRoofDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SLOPED_ROOF;
    },
    displaySlopedRoofDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_SLOPED_ROOF;
    },
    displaySlopedRoofDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_SLOPED_ROOF;
    },

    // VENTILATED WALL
    displayVentilatedWallThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.VENTILATED_WALL_SPECIFICATIONS;
    },
    displayVentilatedWallDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_VENTILATED_WALL;
    },
    displayVentilatedWallDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_VENTILATED_WALL;
    },
    displayVentilatedWallDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_VENTILATED_WALL;
    },

    // FLOOR
    displayFloorThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.FLOOR_SPECIFICATIONS;
    },
    displayFloorDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_FLOOR;
    },
    displayFloorDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_FLOOR;
    },
    displayFloorDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_FLOOR;
    },

    // SOL
    displaySolThermalCalculationModal: (state) => {
      state.display = ModalDisplayTypes.SOL_SPECIFICATIONS;
    },
    displaySolDownloadModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SOL;
    },
    displaySolDownloadMailModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_MAIL_SOL;
    },
    displaySolDownloadSuccessModal: (state) => {
      state.display = ModalDisplayTypes.DOWNLOAD_SUCCESS_SOL;
    },
  },
});
