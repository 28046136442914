import React from "react";
import { usePreview, } from "./preview.hooks";
import { EyeClosedIcon, EyeOpenIcon, } from "../common/Icons";
import { Intro } from "../viewer/DesignCenter";
import { H1, H2 } from "../common/Typography";
import { Spacer } from "../common/Spacer";
import styled from "styled-components";
import { styles } from "../../styles";
import { OverviewItem } from "../common/OverviewItem";

const Preview = () => {
    const {
        t,
        toggles,
        currentApplicationInfo,
        currentDetailInfo,
        globalRenderer,
        handleToggle,
        handleToggleMaterial
    } = usePreview();
    // console.log('defaultSettings', globalRenderer?.defaultSettings)
    // console.log('settings', globalRenderer?.settings)
    // console.log('toggles', globalRenderer?.toggles)
    // console.log('layersTypes', globalRenderer?.layersTypes)
    // console.log('toggles', toggles)
    // console.log(globalRenderer)

    return (
        <SideSection>
            <Container>
                <Intro>
                    <Spacer heightPx={40} />
                    <H1>{currentApplicationInfo ? t(currentApplicationInfo?.translationKey) : 'Dollhouse'}</H1>
                    <Spacer heightPx={12} />
                    <H2>{currentDetailInfo ? t(currentDetailInfo?.translationKey) : ''}</H2>
                    <Spacer heightPx={64} />
                </Intro>
            </Container>

            {toggles.length > 0 && (
                <>
                    <Container>
                        <H1>Toggles</H1>
                        <Spacer heightPx={12} />
                    </Container>

                    {globalRenderer && toggles.map((toggle: any, index: number) => (
                        <div key={toggle.name}>
                            <Line />
                            <ToggleItem>
                                <InteractiveToggle
                                    onClick={() => handleToggle(index)}
                                    visible={toggle.visible}
                                    title={toggle.name}
                                    text={toggle.active ?? '/'}
                                />
                                {(globalRenderer.layersTypes[toggle.name] && globalRenderer.layersTypes[toggle.name].length > 1) &&
                                    <select name="layerType" id="layerType" value={toggle.active} onChange={({ target }) => handleToggleMaterial(toggle.name, target.value)}>
                                        {globalRenderer.layersTypes[toggle.name].map((option: any) => (
                                            <option value={option} key={option}>{option}</option>
                                        ))}
                                    </select>
                                }
                            </ToggleItem>
                            <Line />
                        </div>
                    ))}
                </>
            )}
        </SideSection>
    );
};

export const SideSection = styled.section`
  z-index: 1;
  width: ${(props) => props.theme.designWidth(448)}px;
  min-width: 340px;
  height: 100%;
  background: ${styles.colors.white};
  border-top: 1px solid ${styles.colors.darkBlue5};
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (pointer: coarse) {
    min-width: 100%;
  }
`;

const Container = styled.div`
  display: block;
  flex-direction: column;
  overflow-y: auto;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${styles.colors.darkBlue10};
`;

const ToggleItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
`;

const InteractiveToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  opacity: 1;

  @media screen and (pointer: coarse) {
    pointer-events: none;
  }
`;


const WidthContainer = styled.div`
  width: 85%;
  }
`;

const InteractiveToggle = ({ visible, onClick, title, text }: any) => {
    return (
        <InteractiveToggleContainer onClick={onClick}>
            {!visible ? <EyeClosedIcon /> : <EyeOpenIcon />}
            <Spacer widthPx={16} />
            <WidthContainer >
                <OverviewItem title={title} text={text} />
            </WidthContainer>
        </InteractiveToggleContainer >
    );
};

export { Preview };
