import { FLOOR_MINIMUM_INSULATION_THICKNESS } from "@iko-design-center/shared";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateThermalLayer } from "../../../store/floor/thermalPerformance";
import { useFloor } from "../../../store/floor/hooks";
import { useModal } from "../../../store/modal/hooks";
import { FEMaterial } from "@iko-design-center/shared";

export function useThermalCalculation() {
  const {
    configurationState,
    thermalPerformance,
    setFloorInsulationMaterial,
    setFloorScreedMaterial,
  } = useFloor();
  const { closeModal } = useModal();
  const { t } = useTranslation() as any;

  const [localThermalPerformance, setLocalThermalPerformance] = useState(
    thermalPerformance
  );

  const [
    localFloorInsulationMaterial,
    setLocalFloorInsulationMaterial,
  ] = useState({
    material: configurationState.floorInsulation.material!,
    valid: true,
  });
  const [localFloorScreedMaterial, setLocalFloorScreedMaterial] = useState({
    material: configurationState.floorScreed.material!,
    valid: true,
  });

  const [editableThicknessValue, setEditableThicknessValue] = useState(
    thermalPerformance.floorInsulation.thickness!.toString()
  );
  const [editableThicknessValid, setEditableThicknessValid] = useState(true);

  function handleEditableThicknessChange(
    event: SyntheticEvent<HTMLInputElement>
  ) {
    const input = event.target as HTMLInputElement;
    const type = input.dataset.type!;
    let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

    let validThickness = true;
    if (!thickness) {
      validThickness = false;
    }

    if (type && thickness.toString().length <= 3) {
      const updatedLocalMaterial = {
        material: {
          // @ts-ignore
          ...(configurationState[type].material as FEMaterial),
          thickness,
        },
        valid: validThickness,
      };

      switch (type) {
        case "floorInsulation":
          setLocalFloorInsulationMaterial(updatedLocalMaterial);
          break;
        case "floorScreed":
          setLocalFloorScreedMaterial(updatedLocalMaterial);
          break;
      }
      setLocalThermalPerformance(
        updateThermalLayer(
          localThermalPerformance,
          updatedLocalMaterial.material,
          type
        )
      );
    } else {
      const value = (event.target as HTMLInputElement).value;
      setEditableThicknessValue(value);

      thickness = parseInt(value);
      if (
        thickness &&
        thickness >= FLOOR_MINIMUM_INSULATION_THICKNESS &&
        thickness.toString().length <= 3
      ) {
        const updatedLocalMaterial = {
          material: {
            // @ts-ignore
            ...(configurationState["floorInsulation"].material as FEMaterial),
            thickness,
          },
          valid: validThickness,
        };

        setLocalFloorInsulationMaterial(updatedLocalMaterial);
        setLocalThermalPerformance(
          updateThermalLayer(
            localThermalPerformance,
            updatedLocalMaterial.material,
            "floorInsulation"
          )
        );
        setEditableThicknessValid(true);
      } else {
        setEditableThicknessValid(false);
      }
    }
  }

  function handleCancel() {
    closeModal();
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  function handleApply() {
    const allValid =
      localFloorInsulationMaterial.valid &&
      localFloorScreedMaterial.valid &&
      editableThicknessValid;

    if (allValid) {
      setFloorInsulationMaterial(localFloorInsulationMaterial.material);
      setFloorScreedMaterial(localFloorScreedMaterial.material);
      closeModal();
    }
  }

  return {
    t,
    thermalPerformance: localThermalPerformance,
    floorInsulationType: configurationState.floorInsulation.type!,
    floorScreedType: configurationState.floorScreed.type!,

    handleCardClick,
    handleCancel,
    handleApply,

    localFloorInsulationMaterial,
    localFloorScreedMaterial,

    editableThicknessValue,
    editableThicknessValid,
    handleEditableThicknessChange,
  };
}
