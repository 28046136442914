import styled from "styled-components";
import { styles } from "../../styles";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-top: ${(props) => props.theme.designHeight(18)}px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: ${12 / 16}rem;
  color: ${styles.colors.white};
`;

export const Input = styled.input<{ invalid: boolean }>`
  &:focus {
    outline: none;
  }
  margin-top: ${(props) => props.theme.designHeight(6)}px;
  padding: ${(props) => props.theme.designWidth(8)}px;
  border: ${(props) =>
    props.invalid
      ? `1.5px solid ${styles.colors.primary}`
      : `1.5px solid ${styles.colors.darkBlue10}`};
  border-radius: 4px;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: ${14 / 16}rem;
  
  &[type="checkbox"] {
    margin-top: ${(props) => props.theme.designHeight(19)}px;
    margin-right: ${(props) => props.theme.designHeight(8)}px;
  }
`;

export const TextArea = styled.textarea<{ invalid: boolean }>`
  min-height: ${(props) => props.theme.designHeight(128)}px;
  margin-top: ${(props) => props.theme.designHeight(6)}px;
  padding: ${(props) => props.theme.designWidth(8)}px;
  border: ${(props) =>
    props.invalid
        ? `1.5px solid ${styles.colors.primary}`
        : `1.5px solid ${styles.colors.darkBlue10}`};
  border-radius: 4px;
  resize: none;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: ${14 / 16}rem;
`;

export const Required = styled(Label)`
  color: ${styles.colors.primary};
  margin-left: 2px;
`;
