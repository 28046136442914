import { FloorScreedTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useFloorScreed } from "./floorScreed.hooks";
import { Sidebar } from "../Sidebar";
import { getFloorScreedTranslations } from "./translations";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const FloorScreed = () => {
  const {
    t,
    floorScreedType,
    setFloorScreedType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useFloorScreed();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("floor.floorScreed.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("floor.floorScreed.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("floor.floorScreed.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={FloorScreedTypes.ANHYDRITE_FLOOR}
          checked={floorScreedType === FloorScreedTypes.ANHYDRITE_FLOOR}
          onClick={() => setFloorScreedType(FloorScreedTypes.ANHYDRITE_FLOOR)}
          text={getFloorScreedTranslations(t, FloorScreedTypes.ANHYDRITE_FLOOR)}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={FloorScreedTypes.CHAPE_FLOOR}
          checked={floorScreedType === FloorScreedTypes.CHAPE_FLOOR}
          onClick={() => setFloorScreedType(FloorScreedTypes.CHAPE_FLOOR)}
          text={getFloorScreedTranslations(t, FloorScreedTypes.CHAPE_FLOOR)}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
