import {
  SolConfigurationState,
  SolThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared"
import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { DownloadState } from "./download"
import { solSlice } from "./store"

export function useSol() {
  const dispatch = useAppDispatch()
  const solState = useAppSelector((state) => state.sol)
  const configurationState = useAppSelector((state) => state.sol.configuration)
  const downloadState = useAppSelector((state) => state.sol.download)
  const thermalPerformance = useAppSelector(
    (state) => state.sol.thermalPerformance
  )
  const solScreenshots = useAppSelector((state) => state.sol.screenshots)

  const setSolInsulationType = useCallback(
    (type: any) => {
      dispatch(solSlice.actions.setSolInsulationType(type))
    },
    [dispatch]
  )
  const setSolNoiseLevelType = useCallback(
    (type: any) => {
      dispatch(solSlice.actions.setSolNoiseLevelType(type))
    },
    [dispatch]
  )
  const setSolAcousticLayerType = useCallback(
    (type: any) => {
      dispatch(solSlice.actions.setSolAcousticLayerType(type))
    },
    [dispatch]
  )
  const setSolConformityType = useCallback(
    (type: any) => {
      dispatch(solSlice.actions.setSolConformityType(type))
    },
    [dispatch]
  )
  const setSolBuildingTypeType = useCallback(
    (type: any) => {
      dispatch(solSlice.actions.setSolBuildingTypeType(type))
    },
    [dispatch]
  )

  const setSolInsulationMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(solSlice.actions.setSolInsulationMaterial(material))
    },
    [dispatch]
  )

  const setThermalPerformance = useCallback(
    (thermalPerformance: SolThermalPerformanceState) => {
      dispatch(solSlice.actions.setThermalPerformance(thermalPerformance))
    },
    [dispatch]
  )

  const resetConfigurationToInsulation = useCallback(
    () => dispatch(solSlice.actions.resetConfigurationToInsulation()),
    [dispatch]
  )
  const resetConfigurationToNoiseLevel = useCallback(
    () => dispatch(solSlice.actions.resetConfigurationToNoiseLevel()),
    [dispatch]
  )
  const resetConfigurationToAcousticLayer = useCallback(
    () => dispatch(solSlice.actions.resetConfigurationToAcousticLayer()),
    [dispatch]
  )
  const resetConfigurationToConformity = useCallback(
    () => dispatch(solSlice.actions.resetConfigurationToConformity()),
    [dispatch]
  )
  const resetConfigurationToBuildingType = useCallback(
    () => dispatch(solSlice.actions.resetConfigurationToBuildingType()),
    [dispatch]
  )

  const setConfiguration = useCallback(
    (configuration: SolConfigurationState) =>
      dispatch(solSlice.actions.setConfiguration(configuration)),
    [dispatch]
  )

  const setHumanReadableName = useCallback(
    (name: string) => dispatch(solSlice.actions.setHumanReadableName(name)),
    [dispatch]
  )

  const resetConfiguration = useCallback(
    () => dispatch(solSlice.actions.resetConfiguration()),
    [dispatch]
  )

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(solSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  )

  const addScreenshot = useCallback(
    (dataURL: string) => dispatch(solSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  )

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(solSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  )

  return {
    solState,
    addScreenshot,
    setOverviewScreenshot,
    solScreenshots,
    thermalPerformance,
    setThermalPerformance,
    downloadState,
    configurationState,
    setSolInsulationType,
    setSolNoiseLevelType,
    setSolAcousticLayerType,
    setSolConformityType,
    setSolBuildingTypeType,
    setSolInsulationMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToInsulation,
    resetConfigurationToNoiseLevel,
    resetConfigurationToAcousticLayer,
    resetConfigurationToConformity,
    resetConfigurationToBuildingType,
    resetConfiguration,
  }
}
