import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";

const Container = styled.div`
  z-index: 1001;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (pointer: coarse) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.4);
  }
`;

const Card = styled.div`
  z-index: 1;
  width: ${160 / 16}rem;
  height: ${196 / 16}rem;
  background: ${styles.colors.dark2};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (pointer: coarse) {
    padding: 32px 32px 18px 32px;
    width: auto;
    height: auto;

    & > p {
      margin-top: 24px;
    }

    p {
      font-size: 18px;
      line-height: 16px;
    }
  }
`;

const SVG_WIDTH = 100;
const SVG_HEIGHT = 100;
const STROKE_WIDTH = 4;

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SVG_WIDTH}px;
  height: ${SVG_HEIGHT}px;
  margin-bottom: 15px;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: ${SVG_WIDTH}px;
  height: ${SVG_HEIGHT}px;
  transform: rotate(-90deg);
`;

const Circle = styled.circle<{ stroke: string; strokeWidth: number }>`
  fill: none;
  stroke: ${(props) => props.stroke};
  stroke-width: ${(props) => props.strokeWidth}px;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.2s ease 0s;
`;

const Loading = ({ percentage }: { percentage: number }) => {
  const { t } = useTranslation();

  const radius = SVG_WIDTH / 2 - STROKE_WIDTH / 2;
  const strokeDasharray = Math.PI * radius * 2;
  const strokeDashoffset =
    strokeDasharray - (strokeDasharray * percentage) / 100;

  return (
    <Container>
      <Card>
        <LoaderContainer>
          <Svg viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
            <Circle
              cx={SVG_WIDTH / 2}
              cy={SVG_HEIGHT / 2}
              r={radius}
              stroke={styles.colors.white}
              strokeWidth={STROKE_WIDTH - 1}
            />
            <Circle
              cx={SVG_WIDTH / 2}
              cy={SVG_HEIGHT / 2}
              r={radius}
              stroke={styles.colors.primary}
              strokeWidth={STROKE_WIDTH}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
            />
          </Svg>
          <P color={styles.colors.white} fontSizePx={18} fontWeight={700}>
            {`${percentage}%`}
          </P>
        </LoaderContainer>
        <P color={styles.colors.white} fontSizePx={18} fontWeight={700}>
          {t("viewer.loading")}
        </P>
      </Card>
    </Container>
  );
};

export { Loading };
