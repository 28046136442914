import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RequestAdviceDTO } from "../../../../shared";
import { api } from "../../services/api.service";
import { useModal } from "../../store/modal/hooks";
import { styles } from "../../styles";
import { CalculatorIcon } from "../common/Icons";
import { Form, Input, Label, Required, TextArea } from "../common/Input";
import { P } from "../common/Typography";
import { StandardModal } from "./StandardModal";
import { validationRegex } from "./validationRegex";
// FLAT ROOF
import { useFlatRoof } from "../../store/flat-roof/hooks";
// CAVITY WALL
import { useCavityWall } from "../../store/cavity-wall/hooks";
// SLOPED ROOF
import { useSlopedRoof } from "../../store/sloped-roof/hooks";
// VENTILATED WALL
import { useVentilatedWall } from "../../store/ventilated-wall/hooks";
// FLOOR
import { useFloor } from "../../store/floor/hooks";
// SOL
import { useSol } from "../../store/sol/hooks";

const PhoneRow = styled.div`
  width: 100%;
  display: flex;
`;
const PhoneContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;
const SpacerContainer = styled.div`
  width: 20px;
`;
const PostalCodeContainer = styled.div`
  flex: 1;
  width: ${(props) => props.theme.designWidth(120)}px;
  display: flex;
  flex-direction: column;
`;

export const RequestAdvice = () => {
  const { t, i18n } = useTranslation();
  const { closeModal, displayRequestAdviceSuccessModal } = useModal();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const language = i18n.language;

  // FLAT ROOF
  const { configurationState: flatRoofConfig } = useFlatRoof();
  const flatRoofConfigName = flatRoofConfig.humanReadableName ?? undefined;

  // CAVITY WALL
  const { configurationState: cavityWallConfig } = useCavityWall();
  const cavityWallConfigName = cavityWallConfig.humanReadableName ?? undefined;

  // SLOPED ROOF
  const { configurationState: slopedRoofConfig } = useSlopedRoof();
  const slopedRoofConfigName = slopedRoofConfig.humanReadableName ?? undefined;

  // VENTILATED WALL
  const { configurationState: ventilatedWallConfig } = useVentilatedWall();
  const ventilatedWallConfigName = ventilatedWallConfig.humanReadableName ?? undefined;

  // FLOOR
  const { configurationState: floorConfig } = useFloor();
  const floorConfigName = floorConfig.humanReadableName ?? undefined;

  // SOL
  const { configurationState: solConfig } = useSol();
  const solConfigName = solConfig.humanReadableName ?? undefined;

  const humanReadableName = determineCofiguration();

  function determineCofiguration() {
    // FLAT ROOF
    if (flatRoofConfigName) {
      return flatRoofConfigName;
    }
    // CAVITY WALL
    if (cavityWallConfigName) {
      return cavityWallConfigName;
    }
    // SLOPED ROOF
    if (slopedRoofConfigName) {
      return slopedRoofConfigName;
    }
    // VENTILATED WALL
    if (ventilatedWallConfigName) {
      return ventilatedWallConfigName;
    }
    // FLOOR
    if (floorConfigName) {
      return floorConfigName;
    }
    // SOL
    if (solConfigName) {
      return solConfigName;
    }
  }

  function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidName(false);
    setName(e.target.value);
  }

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidEmail(false);
    setEmail(e.target.value);
  }

  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPhone(false);
    setPhone(e.target.value);
  }

  function handlePostalCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPostalCode(false);
    setPostalCode(e.target.value);
  }

  function validate(): boolean {
    const validMail = validationRegex.email.test(String(email).toLowerCase());
    const validName = name !== "";
    const validPhone = validationRegex.phone.test(phone);
    const validPostalCode = postalCode !== "";

    if (!validName) {
      setInvalidName(true);
    }

    if (!validMail) {
      setInvalidEmail(true);
    }

    if (!validPhone) {
      setInvalidPhone(true);
    }

    if (!validPostalCode) {
      setInvalidPostalCode(true);
    }

    return validName && validMail && validPhone && validPostalCode;
  }

  function submitRequest() {
    const dto: RequestAdviceDTO = {
      name,
      email,
      phone,
      language,
      humanReadableName,
    };

    if (postalCode !== "") {
      dto.postalCode = postalCode;
    }
    if (company !== "") {
      dto.company = company;
    }
    if (message !== "") {
      dto.message = message;
    }

    api
      .postRequestAdvice(dto)
      .then((_) => {
        displayRequestAdviceSuccessModal();
      })
      .catch((_) => {
        closeModal();
      });
  }

  function handleSubmit(e: SyntheticEvent) {
    if (validate()) {
      e.currentTarget.className += " clicked";
      e.currentTarget.setAttribute("disabled", "disabled");

      submitRequest();
    }
  }

  return (
    <StandardModal
      titleIcon={CalculatorIcon}
      title={t("modal.requestAdvice.title")}
      subtitle={t("modal.requestAdvice.subtitle")}
      buttonText={t("modal.requestAdvice.button")}
      handleSubmit={handleSubmit}
      classnames={"advice standard-modal"}
    >
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">
          {t("modal.requestAdvice.nameLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidName}
          type="text"
          value={name}
          onChange={handleNameChange}
        />
        {invalidName && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.requestAdvice.invalidName")}
          </P>
        )}
        <Label htmlFor="email">
          {t("modal.requestAdvice.emailLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidEmail}
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {invalidEmail && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.requestAdvice.invalidEmail")}
          </P>
        )}
        <PhoneRow>
          <PhoneContainer>
            <Label htmlFor="phone">
              {t("modal.requestAdvice.phoneLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPhone}
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              required
            />
            {invalidPhone && (
              <P
                fontSizePx={12}
                color={styles.colors.white65}
                lineHeightPx={20}
              >
                {t("modal.requestAdvice.invalidPhone")}
              </P>
            )}
          </PhoneContainer>
          <SpacerContainer />
          <PostalCodeContainer>
            <Label htmlFor="postalCode">
              {t("modal.requestAdvice.postalCodeLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPostalCode}
              type="text"
              value={postalCode}
              onChange={handlePostalCodeChange}
            />
          </PostalCodeContainer>
        </PhoneRow>
        <Label htmlFor="company">{t("modal.requestAdvice.companyLabel")}</Label>
        <Input
          invalid={false}
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <Label htmlFor="message">{t("modal.requestAdvice.messageLabel")}</Label>
        <TextArea
          invalid={false}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Form>
    </StandardModal>
  );
};
