import { SyntheticEvent } from "react"
import { SolInsulationTypes, SolLayers } from "@iko-design-center/shared"
import { useTranslation } from "react-i18next"
import {
  allSelectedDownloadStateChapeTG,
  allSelectedDownloadStateKrAlu,
  getLayerOptions,
} from "../../../store/sol/download"
import { useSol } from "../../../store/sol/hooks"
import { useModal } from "../../../store/modal/hooks"
import { useOverview } from "./overview.hooks"

type ToggleAllOptions = "DOP" | "technicalFiche"

export function useDownload() {
  const { setDownloadState, downloadState } = useSol()
  const { solInsulationType } = useOverview()
  const { closeModal, displaySolDownloadMailModal } = useModal()
  const { t } = useTranslation()

  function selectAll() {
    if (solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG) {
      setDownloadState(allSelectedDownloadStateChapeTG)
    }
    if (solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE || solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE) {
      setDownloadState(allSelectedDownloadStateKrAlu)
    }
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation()
  }

  const technicalFicheOptions = getLayerOptions({
    t,
    state: downloadState.technicalFiche,
  })
  const DOPOptions = getLayerOptions({
    t,
    state: downloadState.DOP,
  })

  function toggleAll(kind: ToggleAllOptions) {
    switch (kind) {
      case "technicalFiche":
        const technicalFicheChecked = downloadState.technicalFiche.solInsulation
        // && downloadState.technicalFiche.floorScreed

        setDownloadState({
          ...downloadState,
          technicalFiche: {
            solInsulation: !technicalFicheChecked,
            // floorScreed: !technicalFicheChecked,
          },
        })
        break
      case "DOP":
        const DOPChecked = downloadState.DOP.solInsulation
        // && downloadState.DOP.floorScreed

        setDownloadState({
          ...downloadState,
          DOP: {
            solInsulation: !DOPChecked,
            // floorScreed: !DOPChecked,
          },
        })
        break
    }
  }

  function toggleLayersOption(kind: ToggleAllOptions, value: SolLayers) {
    switch (kind) {
      case "technicalFiche":
        setDownloadState({
          ...downloadState,
          technicalFiche: {
            ...downloadState.technicalFiche,
            [value]: !downloadState.technicalFiche[value],
          },
        })
        break
      case "DOP":
        setDownloadState({
          ...downloadState,
          DOP: {
            ...downloadState.DOP,
            [value]: !downloadState.DOP[value],
          },
        })
        break
    }
  }

  function toggle(
    kind:
      | "specifications"
      | "BIMFile"
      | "screenshots"
      | "thermalCalculation"
      | "processingGuideline"
  ) {
    setDownloadState({
      ...downloadState,
      [kind]: !downloadState[kind],
    })
  }

  function handleDownload() {
    displaySolDownloadMailModal()
  }

  return {
    t,
    handleCardClick,
    closeModal,
    selectAll,
    technicalFicheOptions,
    toggleAll,
    toggleLayersOption,
    DOPOptions,
    downloadState,
    toggle,
    handleDownload,
  }
}
