import { Modal } from "../modal/Modal";
import { Navigation } from "../navigation/Navigation";
import { SwiperToggle } from "../navigation/SwiperToggle";
import { Router } from "../sidebar/Router";
import { Toast } from "../toast/Toast";
import { Viewer } from "../viewer/Viewer";
import { Spacer } from "../common/Spacer";
import { H1, P } from "../common/Typography";
import styled from "styled-components";
import { DESIGN_DEVICE_HEIGHT } from "../../styles/SizeProvider";
import { isMobile } from "../../services/helpers.service";
import { useTranslation } from "react-i18next";
import { useApplication } from "../../store/application/hooks";
import { useTabs } from "../navigation/tabs.hook";

const AppViewport = styled.div`
  height: ${(props) => props.theme.innerHeight}px;
  width: ${(props) => props.theme.innerWidth}px;

  @media screen and (pointer: coarse) {
    display: flex;
    flex-direction: column;
  }
`;

const Main = styled.main`
  height: ${(props) => props.theme.designHeight(DESIGN_DEVICE_HEIGHT - 64)}px;
  width: 100%;
  display: flex;

  @media screen and (pointer: coarse) {
    overflow: hidden;
    height: 100%;
  }
`;

const Swiper = styled.div`
  @media screen and (pointer: coarse) {
    position: relative;
    width: 200%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    flex-grow: 1;
    transition: all 0.3s ease;
    display: flex;
    padding-bottom: 74px;

    &.model-visible {
      left: 0%;
    }

    &.config-visible {
      left: -100%;
    }

    > * {
      min-width: 100%;
      min-height: 100%;
    }

    .application-grid,
    .tiles-grid {
      position: relative;
      padding: 16px;
      display: grid;
      gap: 16px;
      overflow-x: hidden;
      overflow-y: auto;
      height: auto;
      width: 100%;
      max-width: 100vw;
    }

    .application-grid {
      grid-template: auto / repeat(2, 1fr);
    }

    .tiles-grid {
      grid-template: auto / repeat(3, 1fr);

      &.-no-overflow {
        overflow: unset;
      }
    }
  }
`;

export const Intro = styled.div`
  padding: 0;

  @media screen and (pointer: coarse) {
    padding: 16px;

    h1 {
      font-size: 24px;
      line-height: 30px;
    }

    p {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const IntroHeader = styled.div`
  @media screen and (pointer: coarse) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const RadioButtonList = styled.div`
  @media screen and (pointer: coarse) {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .radio {
      height: fit-content;
      padding: 16px 24px;

      button {
        height: fit-content;
      }

      * {
        font-size: 14px;
        line-height: 20px;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const DesignCenter = () => {
  const { t } = useTranslation();
  const { modelView, setCurrentView } = useApplication();
  const { tabs } = useTabs();
  const hasApplicationSelected = tabs.length > 0;

  return (
    <AppViewport>
      <Toast />
      <Modal />
      <Navigation />
      {isMobile() && !hasApplicationSelected && (
        <Intro>
          <IntroHeader>
            <H1>{t("home.title")}</H1>
            <SwiperToggle onClick={() => setCurrentView(!modelView)} />
          </IntroHeader>
          <Spacer heightPx={12} />
          <P lineHeightPx={22}>{t("home.subtitle")}</P>
          <Spacer heightPx={isMobile() ? 12 : 32} />
        </Intro>
      )}
      <Main>
        {isMobile() ? (
          <Swiper className={modelView ? "model-visible" : "config-visible"}>
            <Viewer />
            <Router />
          </Swiper>
        ) : (
          <>
            <Viewer />
            <Router />
          </>
        )}
      </Main>
    </AppViewport>
  );
};
