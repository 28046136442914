import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationTypes } from "../../store/application/store"
import { useApplication } from "../../store/application/hooks"
import { Models } from "../viewer/renderer.api"
import { renderer } from "../viewer/Renderer"

const applicationList: any = [
  // FLAT ROOF
  {
    type: ApplicationTypes.FLAT_ROOF,
    translationKey: "home.flatRoof",
    details: [
      {
        model: Models.ANGLE_CONNECTION,
        translationKey: "roofDetails.angleConnectionInside",
      },
      {
        model: Models.ANGLE_CONNECTION_OUTSIDE,
        translationKey: "roofDetails.angleConnectionOutside",
      },
      {
        model: Models.SETTLEMENT_JOINT,
        translationKey: "roofDetails.settlementJoint",
      },
      {
        model: Models.ROOF_TERMINAL,
        translationKey: "roofDetails.roofTerminal",
      },
      {
        model: Models.ROOF_UPSTAND,
        translationKey: "roofDetails.roofUpstandBoth",
      },
      {
        model: Models.ROOF_UPSTAND_VERTICAL,
        translationKey: "roofDetails.roofUpstandVertical",
      },
      {
        model: Models.ROOF_UPSTAND_HORIZONTAL,
        translationKey: "roofDetails.roofUpstandHorizontal",
      },
      {
        model: Models.ROOF_SURFACE,
        translationKey: "roofDetails.roofSurface",
      },
      {
        model: Models.DOME,
        translationKey: "roofDetails.dome",
      },
      {
        model: Models.VERTICAL_WALL_BRICK,
        translationKey: "roofDetails.verticalWallBrick",
      },
      {
        model: Models.VERTICAL_WALL_CONCRETE,
        translationKey: "roofDetails.verticalWallConcrete",
      },
      {
        model: Models.DRAINAGE_PIPE_HORIZONTAL,
        translationKey: "roofDetails.drainagePipeHorizontal",
      },
      {
        model: Models.DRAINAGE_PIPE_VERTICAL,
        translationKey: "roofDetails.drainagePipeVertical",
      },
    ],
  },
  // SLOPED ROOF
  {
    type: ApplicationTypes.SLOPED_ROOF,
    translationKey: "home.slopedRoof",
    details: [
      {
        model: Models.SLOPED_ROOF_ROOF_SURFACE,
        translationKey: "slopedRoof.detail.roofSurface",
      },
      {
        model: Models.SLOPED_ROOF_VALLEY_GUTTER,
        translationKey: "slopedRoof.detail.valleyGutter",
      },
      {
        model: Models.SLOPED_ROOF_FOOTING_BEAM,
        translationKey: "slopedRoof.detail.footingBeam",
      },
      {
        model: Models.SLOPED_ROOF_CHIMNEY,
        translationKey: "slopedRoof.detail.chimney",
      },
      {
        model: Models.SLOPED_ROOF_SKYLIGHT,
        translationKey: "slopedRoof.detail.skylight",
      },
      {
        model: Models.SLOPED_ROOF_RIDGE,
        translationKey: "slopedRoof.detail.ridge",
      },
    ],
  },
  // CAVITY WALL
  {
    type: ApplicationTypes.CAVITY_WALL,
    translationKey: "home.cavityWall",
    details: [
      {
        model: Models.CAVITY_WALL_FLAT_ROOF,
        translationKey: "cavityWall.detail.flatRoof",
      },
      {
        model: Models.CAVITY_WALL_SLOPED_ROOF,
        translationKey: "cavityWall.detail.slopedRoof",
      },
      {
        model: Models.CAVITY_WALL_WINDOW_CONNECTION,
        translationKey: "cavityWall.detail.windowConnection",
      },
      {
        model: Models.CAVITY_WALL_FOOTING,
        translationKey: "cavityWall.detail.footing",
      },
      {
        model: Models.CAVITY_WALL_FACADE,
        translationKey: "cavityWall.detail.facade",
      },
    ],
  },
  // VENTILATED WALL
  {
    type: ApplicationTypes.OUTER_WALL,
    translationKey: "home.outerWall",
    details: [
      {
        model: Models.VENTILATED_WALL_FACADE,
        translationKey: "ventilatedWall.detail.facade",
      },
      {
        model: Models.VENTILATED_WALL_WINDOW_CONNECTION,
        translationKey: "ventilatedWall.detail.windowConnection",
      },
      {
        model: Models.VENTILATED_WALL_FOOTING,
        translationKey: "ventilatedWall.detail.footing",
      },
      {
        model: Models.VENTILATED_WALL_WALL_CONNECTION,
        translationKey: "ventilatedWall.detail.wallConnection",
      },
    ],
  },
  // FLOOR
  {
    type: ApplicationTypes.FLOOR,
    translationKey: "home.floor",
    details: [
      {
        model: Models.FLOOR_PLANE,
        translationKey: "floor.detail.floorPlane",
      },
      {
        model: Models.FLOOR_WALL_CONNECTION,
        translationKey: "floor.detail.wallConnection",
      },
    ],
  },
  // SOL
  {
    type: ApplicationTypes.SOL,
    translationKey: "home.sol",
    details: [
      {
        model: Models.SOL_PLANE_DALLEPORTEE,
        translationKey: "sol.detail.solPlane",
      },
      {
        model: Models.SOL_WALL_CONNECTION_DALLEPORTEE,
        translationKey: "sol.detail.wallConnection",
      },
      {
        model: Models.SOL_PLANE_SOUSCHAPE,
        translationKey: "sol.detail.solPlane",
      },
      {
        model: Models.SOL_WALL_CONNECTION_SOUSCHAPE,
        translationKey: "sol.detail.wallConnection",
      },
      {
        model: Models.SOL_PLANE_SOUSDALLAGE,
        translationKey: "sol.detail.solPlane",
      },
      {
        model: Models.SOL_WALL_CONNECTION_SOUSDALLAGE,
        translationKey: "sol.detail.wallConnection",
      },
    ],
  },
]

export function usePreview() {
  const { t } = useTranslation() as any
  const { currentModel, applicationType } = useApplication()
  const global = window as any
  const [toggles, setToggles] = useState([]) as any

  const globalRenderer = useMemo(() => global.renderer, [global.renderer])

  const currentApplicationInfo = useMemo(() => {
    const applicationTypeInfo = applicationList.find(
      (x: any) => x.type === applicationType
    )
    if (applicationTypeInfo && globalRenderer.toggles) {
      const toggles = globalRenderer.toggles.map((toggle: any) => ({
        name: toggle,
        visible: globalRenderer.settings.toggles.find(
          (t: any) => t.name === toggle
        )?.default,
        active: globalRenderer.settings.layers.find(
          (l: any) => l.name === toggle
        )?.default,
      }))
      setToggles(toggles)
    }
    return applicationTypeInfo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationType])

  const currentDetailInfo = useMemo(() => {
    if (currentModel) {
      return currentApplicationInfo?.details.find(
        (x: any) => x.model === currentModel
      )
    } else return null
  }, [currentApplicationInfo, currentModel])

  function handleToggle(index: number) {
    const toggle = toggles[index]
    let newToggles = [...toggles]
    newToggles[index] = { ...toggle, visible: !toggle.visible }
    setToggles(newToggles)
    renderer.toggleLayer(toggle.name, !toggle.visible)
  }

  function handleToggleMaterial(layerType: string, value: string) {
    renderer.setLayer(layerType, value)

    const toggleIndex = toggles.findIndex((t: any) => t.name === layerType)
    if (toggleIndex !== -1) {
      let newToggles = [...toggles]
      newToggles[toggleIndex] = { ...newToggles[toggleIndex], active: value }
      setToggles(newToggles)
    }
  }

  return {
    t,
    toggles,
    currentApplicationInfo,
    currentDetailInfo,
    globalRenderer,
    handleToggle,
    handleToggleMaterial,
  }
}
