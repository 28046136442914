import {
  SlopedRoofTypeTypes,
  SlopedRoofInsulationTypeTypes,
} from "@iko-design-center/shared";

export function getRoofTypeTranslations(t: any, type: SlopedRoofTypeTypes) {
  switch (type) {
    case SlopedRoofTypeTypes.TILED_ROOF:
      return t("slopedRoof.roofType.tiledRoof");
    case SlopedRoofTypeTypes.SLATED_ROOF:
      return t("slopedRoof.roofType.slatedRoof");
  }
}

export function getRoofInsulationTranslations(
  t: any,
  type: SlopedRoofInsulationTypeTypes
) {
  let translation = "";

  switch (type) {
    case SlopedRoofInsulationTypeTypes.ALU_NF:
      translation = t("material.ENERTHERM_ALU_NF");
      break;
    case SlopedRoofInsulationTypeTypes.ALU_NF_PRO:
      translation = t("material.ENERTHERM_ALU_NF_PRO");
      break;
  }

  return translation;
}
