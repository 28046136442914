import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { CheckedIcon, UncheckedIcon } from "./Icons";
import { Spacer } from "./Spacer";

const Card = styled.div<{ checked: boolean }>`
  background: ${(props) =>
    props.checked ? styles.colors.darkBlue10Hex : `unset`};
  width: 100%;
  border: ${(props) =>
    props.checked
      ? `1px solid ${styles.colors.darkBlue10Hex}`
      : `1px solid ${styles.colors.white}`};
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.designHeight(16)}px;
  padding-bottom: ${(props) => props.theme.designHeight(16)}px;
  padding-left: ${(props) => props.theme.designWidth(23)}px;
  padding-right: ${(props) => props.theme.designWidth(23)}px;
  cursor: pointer;
  user-select: none;

  p {
    font-size: ${14 / 16}rem;
    line-height: ${20 / 16}rem;
    color: ${(props) =>
      props.checked ? styles.colors.dark3 : styles.colors.white};
  }

  @media screen and (pointer: coarse) {
    padding: 16px 24px;

    p {
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
    }

    svg {
      position: relative !important;
    }
  }
`;

type SingleSelectProps = {
  checked: boolean;
  toggle: () => void;
  label: string;
};

export const SingleSelect = ({ checked, toggle, label }: SingleSelectProps) => {
  return (
    <Card onClick={toggle} checked={checked}>
      {checked && <CheckedIcon />}
      {!checked && <UncheckedIcon />}
      <Spacer widthPx={12} />
      <p>{label}</p>
    </Card>
  );
};
