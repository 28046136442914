import {
  FEMaterial,
  CavityWallConfigurationState,
  initialCavityWallThermalPerformanceState,
  // Layers,
  // CAVITY_WALL_R_VALUE_RSE,
  // CAVITY_WALL_R_VALUE_RSI,
  CAVITY_WALL_R_VALUE_AIR_CAVITY,
  ThermalPerformanceLayer,
  CavityWallThermalPerformanceState,
} from "@iko-design-center/shared";

export function updateThermalLayer(
  thermalPerformance: CavityWallThermalPerformanceState,
  material: FEMaterial,
  type: string
) {
  const draft: CavityWallThermalPerformanceState = { ...thermalPerformance };
  // @ts-ignore
  draft[type] = getThermalPerformanceLayer(material);
  return calculateThermalPerformance(draft);
}

export function getThermalPerformance(
  state: CavityWallConfigurationState
): CavityWallThermalPerformanceState {
  const thermalPerformance = { ...initialCavityWallThermalPerformanceState };

  thermalPerformance.wallType = getThermalPerformanceLayer(
    state.wallType.material!
  );
  thermalPerformance.wallInsulation = getThermalPerformanceLayer(
    state.wallInsulation.material!
  );
  thermalPerformance.outerCavityLeaf = getThermalPerformanceLayer(
    state.outerCavityLeaf.material!
  );

  return calculateThermalPerformance(thermalPerformance);
}

function calculateThermalPerformance(
  state: CavityWallThermalPerformanceState
): CavityWallThermalPerformanceState {
  const draft = { ...state };
  let isComplete = true;

  ["wallType", "wallInsulation", "outerCavityLeaf"].forEach((layer: string) => {
    // @ts-ignore
    const { thickness, lambda } = draft[layer] as ThermalPerformanceLayer;

    if (thickness && lambda) {
      // @ts-ignore
      draft[layer] = {
        thickness,
        lambda,
        RValue: parseFloat((thickness / 1000 / lambda).toFixed(2)),
      };
    } else {
      isComplete = false;
    }
  });

  if (isComplete) {
    draft.RTValue = parseFloat(
      (
        // CAVITY_WALL_R_VALUE_RSI +
        draft.wallType.RValue! +
        draft.wallInsulation.RValue! +
        CAVITY_WALL_R_VALUE_AIR_CAVITY +
        draft.outerCavityLeaf.RValue!
        // CAVITY_WALL_R_VALUE_RSE
      ).toFixed(2)
    );
    draft.UCValue = parseFloat((1 / draft.RTValue).toFixed(2));
  }
  return draft;
}

function getThermalPerformanceLayer(
  material: FEMaterial
): ThermalPerformanceLayer {
  const isVariable =
    material.lambdaThicknessThresholds &&
    material.lambdaThicknessThresholds.length > 0 &&
    material.lambdas.length > 1;

  const thickness = material.thickness;
  let lambda: number;

  if (!isVariable) {
    lambda = material.lambdas[0];
  } else {
    material.lambdaThicknessThresholds!.forEach((threshold, index) => {
      if (thickness > threshold) {
        lambda = material.lambdas[index + 1];
      }
    });
  }

  return {
    thickness,
    lambda: lambda!,
    RValue: null,
  };
}
