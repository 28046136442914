import React from "react";
import { Route, Switch } from "react-router";
import { Home } from "./Home";
import { HomeGuard } from "./HomeGuard";
import { Preview } from "./Preview";
import * as FlatRoof from "./flat-roof";
import * as CavityWall from "./cavity-wall";
import * as SlopedRoof from "./sloped-roof";
import * as VentilatedWall from "./ventilated-wall";
import * as Floor from "./floor";
import * as Sol from "./sol";
import { useRoutes } from "../../store/routes/hooks";

export const Router = () => {
  const { Routes } = useRoutes() as any;

  return (
    <Switch>
      {/* GENERAL */}
      <Route exact path={Routes.HOME}>
        <HomeGuard>
          <Home />
        </HomeGuard>
      </Route>

      <Route exact path={Routes.PREVIEW}>
        <Preview />
      </Route>

      {/* FLAT ROOF */}
      <Route exact path={Routes.FLAT_ROOF_1_ROOF_TYPE}>
        <FlatRoof.Guard>
          <FlatRoof.RoofType />
        </FlatRoof.Guard>
      </Route>
      <Route exact path={Routes.FLAT_ROOF_2_ROOF_FLOOR}>
        <FlatRoof.Guard>
          <FlatRoof.RoofFloor />
        </FlatRoof.Guard>
      </Route>
      <Route exact path={Routes.FLAT_ROOF_3_WATERPROOFING}>
        <FlatRoof.Guard>
          <FlatRoof.Waterproofing />
        </FlatRoof.Guard>
      </Route>
      <Route exact path={Routes.FLAT_ROOF_4_SECURING_METHODS}>
        <FlatRoof.Guard>
          <FlatRoof.SecuringMethods />
        </FlatRoof.Guard>
      </Route>
      <Route exact path={Routes.FLAT_ROOF_5_OVERVIEW}>
        <FlatRoof.Guard>
          <FlatRoof.Overview />
        </FlatRoof.Guard>
      </Route>

      {/* CAVITY WALL */}
      <Route exact path={Routes.CAVITY_WALL_1_WALL_TYPE}>
        <CavityWall.Guard>
          <CavityWall.WallType />
        </CavityWall.Guard>
      </Route>
      <Route exact path={Routes.CAVITY_WALL_2_WALL_INSULATION}>
        <CavityWall.Guard>
          <CavityWall.WallInsulation />
        </CavityWall.Guard>
      </Route>
      <Route exact path={Routes.CAVITY_WALL_3_OVERVIEW}>
        <CavityWall.Guard>
          <CavityWall.Overview />
        </CavityWall.Guard>
      </Route>

      {/* SLOPED ROOF */}
      <Route exact path={Routes.SLOPED_ROOF_1_ROOF_TYPE}>
        <SlopedRoof.Guard>
          <SlopedRoof.RoofType />
        </SlopedRoof.Guard>
      </Route>
      <Route exact path={Routes.SLOPED_ROOF_2_ROOF_INSULATION}>
        <SlopedRoof.Guard>
          <SlopedRoof.RoofInsulation />
        </SlopedRoof.Guard>
      </Route>
      <Route exact path={Routes.SLOPED_ROOF_3_OVERVIEW}>
        <SlopedRoof.Guard>
          <SlopedRoof.Overview />
        </SlopedRoof.Guard>
      </Route>

      {/* VENTILATED WALL */}
      <Route exact path={Routes.VENTILATED_WALL_1_WALL_TYPE}>
        <VentilatedWall.Guard>
          <VentilatedWall.WallType />
        </VentilatedWall.Guard>
      </Route>
      <Route exact path={Routes.VENTILATED_WALL_2_FACADE_CLADDING}>
        <VentilatedWall.Guard>
          <VentilatedWall.FacadeCladding />
        </VentilatedWall.Guard>
      </Route>
      <Route exact path={Routes.VENTILATED_WALL_3_OVERVIEW}>
        <VentilatedWall.Guard>
          <VentilatedWall.Overview />
        </VentilatedWall.Guard>
      </Route>

      {/* FLOOR */}
      <Route exact path={Routes.FLOOR_1_FLOOR_HEATING}>
        <Floor.Guard>
          <Floor.FloorHeating />
        </Floor.Guard>
      </Route>
      <Route exact path={Routes.FLOOR_2_FLOOR_INSULATION}>
        <Floor.Guard>
          <Floor.FloorInsulation />
        </Floor.Guard>
      </Route>
      <Route exact path={Routes.FLOOR_3_FLOOR_SCREED}>
        <Floor.Guard>
          <Floor.FloorScreed />
        </Floor.Guard>
      </Route>
      <Route exact path={Routes.FLOOR_4_OVERVIEW}>
        <Floor.Guard>
          <Floor.Overview />
        </Floor.Guard>
      </Route>

      {/* SOL */}
      <Route exact path={Routes.SOL_1_INSULATION}>
        <Sol.Guard>
          <Sol.SolInsulation />
        </Sol.Guard>
      </Route>
      <Route exact path={Routes.SOL_2_NOISE_LEVEL}>
        <Sol.Guard>
          <Sol.SolNoiseLevel />
        </Sol.Guard>
      </Route>
      <Route exact path={Routes.SOL_3_ACOUSTIC_LAYER}>
        <Sol.Guard>
          <Sol.SolAcousticLayer />
        </Sol.Guard>
      </Route>
      <Route exact path={Routes.SOL_2_CONFORMITY}>
        <Sol.Guard>
          <Sol.SolConformity />
        </Sol.Guard>
      </Route>
      <Route exact path={Routes.SOL_3_BUILDING_TYPE}>
        <Sol.Guard>
          <Sol.SolBuildingType />
        </Sol.Guard>
      </Route>
      <Route exact path={Routes.SOL_4_OVERVIEW}>
        <Sol.Guard>
          <Sol.Overview />
        </Sol.Guard>
      </Route>
    </Switch>
  );
};
