import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useFloor } from "../../../store/floor/hooks";
import { renderer } from "../../viewer/Renderer";
import { useRoutes } from "../../../store/routes/hooks";

export function useFloorScreed() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any;
  const history = useHistory();

  const {
    configurationState,
    setFloorScreedType: setFloorScreedTypeState,
  } = useFloor();

  const floorScreedType = configurationState.floorScreed.type;
  const nextButtonDisabled = !floorScreedType;

  function handleNextButtonClick() {
    history.push(Routes.FLOOR_4_OVERVIEW);
  }

  function setFloorScreedType(type: any) {
    setFloorScreedTypeState(type);
    renderer.displayFloorScreedType(type);
  }

  return {
    t,
    floorScreedType,
    setFloorScreedType,
    handleNextButtonClick,
    nextButtonDisabled,
  };
}
