import {
  FloorHeatingTypes,
  FloorInsulationTypes,
  FloorScreedTypes,
} from "@iko-design-center/shared";

export function getFloorHeatingTranslations(t: any, type: FloorHeatingTypes) {
  switch (type) {
    case FloorHeatingTypes.FLOOR_HEATING:
      return t("floor.floorHeating.floorHeating");
    case FloorHeatingTypes.NO_FLOOR_HEATING:
      return t("floor.floorHeating.noFloorHeating");
  }
}

export function getFloorInsulationTranslations(
  t: any,
  type: FloorInsulationTypes
) {
  switch (type) {
    case FloorInsulationTypes.ENERTHERM_ALU:
      return t("floor.floorInsulation.ALU");
    case FloorInsulationTypes.ENERTHERM_KR_ALU:
      return t("floor.floorInsulation.KRALU");
  }
}

export function getFloorScreedTranslations(t: any, type: FloorScreedTypes) {
  switch (type) {
    case FloorScreedTypes.ANHYDRITE_FLOOR:
      return t("floor.floorScreed.anhydrite");
    case FloorScreedTypes.CHAPE_FLOOR:
      return t("floor.floorScreed.chape");
  }
}
