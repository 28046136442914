"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialFloorThermalPerformanceState = exports.FLOOR_MINIMUM_INSULATION_THICKNESS = exports.FLOOR_R_VALUE_RSE = exports.FLOOR_R_VALUE_RSI = void 0;
exports.FLOOR_R_VALUE_RSI = 0.13;
exports.FLOOR_R_VALUE_RSE = 0.04;
exports.FLOOR_MINIMUM_INSULATION_THICKNESS = 20;
exports.initialFloorThermalPerformanceState = {
    UCValue: null,
    RTValue: null,
    floorHeating: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    floorInsulation: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    floorScreed: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
};
