import { Models } from "./renderer.api"
import { useApplication } from "../../store/application/hooks"
import { useTranslation } from "react-i18next"

export function useViewerDetail() {
  const { currentModel } = useApplication()
  const { i18n } = useTranslation()

  function detailTitle(): string | undefined {
    let key = null
    if (currentModel === Models.DOME) {
      key = "modal.roofDetails.domeTitle"
    } else if (currentModel === Models.ANGLE_CONNECTION) {
      key = "modal.roofDetails.angleConnectionTitle"
    } else if (currentModel === Models.ANGLE_CONNECTION_OUTSIDE) {
      key = "modal.roofDetails.angleConnectionOutsideTitle"
    } else if (currentModel === Models.SETTLEMENT_JOINT) {
      key = "modal.roofDetails.settlementJointTitle"
    } else if (currentModel === Models.ROOF_TERMINAL) {
      key = "modal.roofDetails.roofTerminalTitle"
    } else if (currentModel === Models.ROOF_UPSTAND) {
      key = "modal.roofDetails.roofUpstandTitle"
    } else if (currentModel === Models.ROOF_UPSTAND_HORIZONTAL) {
      key = "modal.roofDetails.roofUpstandHorizontalTitle"
    } else if (currentModel === Models.ROOF_UPSTAND_VERTICAL) {
      key = "modal.roofDetails.roofUpstandVerticalTitle"
    } else if (currentModel === Models.ROOF_SURFACE) {
      key = "modal.roofDetails.roofSurfaceTitle"
    } else if (currentModel === Models.VERTICAL_WALL_BRICK) {
      key = "modal.roofDetails.verticalWallBrickTitle"
    } else if (currentModel === Models.VERTICAL_WALL_CONCRETE) {
      key = "modal.roofDetails.verticalWallConcreteTitle"
    } else if (currentModel === Models.DRAINAGE_PIPE_HORIZONTAL) {
      key = "modal.roofDetails.drainagePipeHorizontalTitle"
    } else if (currentModel === Models.DRAINAGE_PIPE_VERTICAL) {
      key = "modal.roofDetails.drainagePipeVerticalTitle"
    }

    if (
      currentModel === Models.SOL_SOUSCHAPE ||
      currentModel === Models.SOL_PLANE_SOUSCHAPE ||
      currentModel === Models.SOL_WALL_CONNECTION_SOUSCHAPE
    ) {
      key = "sol.modal.details.souschape.title"
    }

    if (
      currentModel === Models.SOL_DALLEPORTEE ||
      currentModel === Models.SOL_PLANE_DALLEPORTEE ||
      currentModel === Models.SOL_WALL_CONNECTION_DALLEPORTEE
    ) {
      key = "sol.modal.details.dalleportee.title"
    }

    return translateOrUndefined(key)
  }

  function detailDescription(): string | undefined {
    let key = null
    if (currentModel === Models.DOME) {
      key = "modal.roofDetails.domeDescription"
    } else if (currentModel === Models.ANGLE_CONNECTION) {
      key = "modal.roofDetails.angleConnectionDescription"
    } else if (currentModel === Models.ANGLE_CONNECTION_OUTSIDE) {
      key = "modal.roofDetails.angleConnectionOutsideDescription"
    } else if (currentModel === Models.SETTLEMENT_JOINT) {
      key = "modal.roofDetails.settlementJointDescription"
    } else if (currentModel === Models.ROOF_TERMINAL) {
      key = "modal.roofDetails.roofTerminalDescription"
    } else if (currentModel === Models.ROOF_UPSTAND) {
      key = "modal.roofDetails.roofUpstandDescription"
    } else if (currentModel === Models.ROOF_UPSTAND_HORIZONTAL) {
      key = "modal.roofDetails.roofUpstandHorizontalDescription"
    } else if (currentModel === Models.ROOF_UPSTAND_VERTICAL) {
      key = "modal.roofDetails.roofUpstandVerticalDescription"
    } else if (currentModel === Models.ROOF_SURFACE) {
      key = "modal.roofDetails.roofSurfaceDescription"
    } else if (currentModel === Models.VERTICAL_WALL_BRICK) {
      key = "modal.roofDetails.verticalWallBrickDescription"
    } else if (currentModel === Models.VERTICAL_WALL_CONCRETE) {
      key = "modal.roofDetails.verticalWallConcreteDescription"
    } else if (currentModel === Models.DRAINAGE_PIPE_HORIZONTAL) {
      key = "modal.roofDetails.drainagePipeHorizontalDescription"
    } else if (currentModel === Models.DRAINAGE_PIPE_VERTICAL) {
      key = "modal.roofDetails.drainagePipeVerticalDescription"
    }

    if (
      currentModel === Models.SOL_SOUSCHAPE ||
      currentModel === Models.SOL_PLANE_SOUSCHAPE ||
      currentModel === Models.SOL_WALL_CONNECTION_SOUSCHAPE
    ) {
      key = "sol.modal.details.souschape.description"
    }

    if (
      currentModel === Models.SOL_DALLEPORTEE ||
      currentModel === Models.SOL_PLANE_DALLEPORTEE ||
      currentModel === Models.SOL_WALL_CONNECTION_DALLEPORTEE
    ) {
      key = "sol.modal.details.dalleportee.description"
    }

    return translateOrUndefined(key)
  }

  function translateOrUndefined(key: string | null): string | undefined {
    if (key === null) {
      return undefined
    }
    if (!i18n.exists(key)) {
      return undefined
    }

    return i18n.t(key)
  }

  return {
    detailTitle,
    detailDescription,
  }
}
