import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialModel, Models } from "../../components/viewer/renderer.api";

export enum ApplicationTypes {
  FLAT_ROOF = "FLAT_ROOF",
  SLOPED_ROOF = "SLOPED_ROOF",
  OUTER_WALL = "OUTER_WALL",
  CAVITY_WALL = "CAVITY_WALL",
  INNER_WALL = "INNER_WALL",
  FLOOR = "FLOOR",
  ATTIC_FLOOR = "ATTIC_FLOOR",
  BASEMENT = "BASEMENT",
  ATTIC_SLOPED_ROOF = "ATTIC_SLOPED_ROOF",
  
  SOL = "SOL",
}

export type ApplicationState = {
  type: ApplicationTypes | null;
  currentModel: Models;
  modelView: boolean;
  directLink: boolean;
};

const initialState: ApplicationState = {
  type: null,
  currentModel: initialModel,
  modelView: false,
  directLink: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplicationType: (
      state,
      action: PayloadAction<ApplicationTypes | null>
    ) => {
      state.type = action.payload;
    },
    setCurrentModel: (state, action: PayloadAction<Models | null>) => {
      state.currentModel = action.payload || initialModel;
    },
    setCurrentView: (state, action: PayloadAction<boolean>) => {
      state.modelView = action.payload;
    },
    setDirectLink: (state, action: PayloadAction<boolean>) => {
      state.directLink = action.payload;
    },
  },
});
