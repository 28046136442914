import React, { SyntheticEvent, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { CaretDownIcon, CaretUpIcon } from "./Icons";
import { P } from "./Typography";

export type DropdownOption<T> = {
  value: T;
  label: string;
};

type DropdownProps<T> = {
  selectedValue: T | null;
  label: string;
  helperLabel: string;
  options: DropdownOption<T>[];
  setSelectedValue: (value: T) => void;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.theme.designHeight(52)}px;
  min-height: 44px;
  user-select: none;
`;

const Selector = styled.div<{ open: boolean }>`
  z-index: ${(props) => (props.open ? 3 : 1)};
  background: ${styles.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border: 2px solid ${styles.colors.darkBlue};
  border-radius: 5px;
`;

const Label = styled.label<{ open: boolean }>`
  z-index: ${(props) => (props.open ? 4 : 2)};
  position: absolute;
  left: ${(props) => props.theme.designWidth(8)}px;
  top: -${(props) => props.theme.designHeight(8)}px;
  background: ${styles.colors.white};
  padding-left: ${(props) => props.theme.designWidth(8)}px;
  padding-right: ${(props) => props.theme.designWidth(8)}px;
  padding-top: ${(props) => props.theme.designHeight(2)}px;
  padding-bottom: ${(props) => props.theme.designHeight(2)}px;

  @media screen and (pointer: coarse) {
    padding: 0 4px;
    margin: -4px 0 0 12px;

    * {
      font-size: 12px;
      line-height: 19px;
      margin: 0;
    }
  }
`;

const Option = styled.div<{
  selectable: boolean;
  open: boolean;
  disabled: boolean;
}>`
  &:hover {
    background: ${(props) =>
      props.selectable ? styles.colors.darkBlue5 : styles.colors.white};
  }
  background: ${styles.colors.white};
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  width: 100%;
  height: ${(props) => props.theme.designHeight(48)}px;
  min-height: 44px;
  padding-left: ${(props) => props.theme.designWidth(16)}px;
  padding-right: ${(props) => props.theme.designWidth(16)}px;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (pointer: coarse) {
    padding: 16px;

    * {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export function Dropdown<T>({
  label,
  helperLabel,
  setSelectedValue,
  selectedValue,
  options,
}: DropdownProps<T>) {
  const [open, setOpen] = useState(false);

  function openMenu() {
    if (!disabled) {
      setOpen(true);
    }
  }

  function closeMenu() {
    setOpen(false);
  }

  function handleValueChange(value: T) {
    closeMenu();
    setSelectedValue(value);
  }

  const disabled = options.length <= 1 || options === null;
  const selectedOption =
    options.find((option) => option.value === selectedValue) || null;

  return (
    <Container onClick={openMenu} onMouseLeave={closeMenu}>
      <Label open={open}>
        <P fontSizePx={12} lineHeightPx={14} color={styles.colors.darkBlue}>
          {label}
        </P>
      </Label>
      <Selector open={open}>
        {open ? (
          <OpenMenu
            helperLabel={helperLabel}
            open={true}
            options={options}
            onValueChange={handleValueChange}
          />
        ) : (
          <ClosedMenu
            disabled={disabled}
            helperLabel={helperLabel}
            open={false}
            selectedOption={selectedOption}
          />
        )}
      </Selector>
    </Container>
  );
}

const HelperLabelText = ({ helperLabel }: { helperLabel: string }) => (
  <P fontSizePx={14} lineHeightPx={19} color={styles.colors.darkBlue75}>
    {helperLabel}
  </P>
);

const LabelText = ({ label }: { label: string }) => (
  <P fontSizePx={14} lineHeightPx={19} color={styles.colors.dark2}>
    {label}
  </P>
);

type ClosedMenuProps<T> = {
  helperLabel: string;
  open: boolean;
  selectedOption: DropdownOption<T> | null;
  disabled: boolean;
};

function ClosedMenu<T>({
  selectedOption,
  helperLabel,
  open,
  disabled,
}: ClosedMenuProps<T>) {
  return (
    <Option selectable={false} open={open} disabled={disabled}>
      {selectedOption === null ? (
        <HelperLabelText helperLabel={helperLabel} />
      ) : (
        <LabelText label={selectedOption.label} />
      )}
      {!disabled && <CaretDownIcon color={styles.colors.dark2} />}
    </Option>
  );
}

type OpenMenuProps<T> = {
  helperLabel: string;
  open: boolean;
  options: DropdownOption<T>[];
  onValueChange: (value: T) => void;
};

function OpenMenu<T>({
  onValueChange,
  options,
  helperLabel,
  open,
}: OpenMenuProps<T>) {
  function handleOptionClick(e: SyntheticEvent, value: T) {
    e.stopPropagation();
    onValueChange(value);
  }

  return (
    <>
      <Option selectable={false} open={open} disabled={false}>
        <HelperLabelText helperLabel={helperLabel} />
        <CaretUpIcon color={styles.colors.dark2} />
      </Option>
      {options.map((option) => (
        <Option
          disabled={false}
          open={open}
          selectable={true}
          key={option.label}
          onClick={(e) => handleOptionClick(e, option.value)}
        >
          <LabelText label={option.label} />
        </Option>
      ))}
    </>
  );
}
