import {
  SlopedRoofConfigurationState,
  SlopedRoofThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { DownloadState } from "./download";
import { slopedRoofSlice } from "./store";

export function useSlopedRoof() {
  const dispatch = useAppDispatch();
  const slopedRoofState = useAppSelector((state) => state.slopedRoof);
  const configurationState = useAppSelector(
    (state) => state.slopedRoof.configuration
  );
  const downloadState = useAppSelector((state) => state.slopedRoof.download);
  const thermalPerformance = useAppSelector(
    (state) => state.slopedRoof.thermalPerformance
  );
  const slopedRoofScreenshots = useAppSelector(
    (state) => state.slopedRoof.screenshots
  );

  const setSlopedRoofTypeType = useCallback(
    (slopedRoofTypeType: any) => {
      dispatch(
        slopedRoofSlice.actions.setSlopedRoofTypeType(slopedRoofTypeType)
      );
    },
    [dispatch]
  );

  const setSlopedRoofInsulationType = useCallback(
    (slopedRoofInsulationType: any) => {
      dispatch(
        slopedRoofSlice.actions.setSlopedRoofInsulationType(
          slopedRoofInsulationType
        )
      );
    },
    [dispatch]
  );

  const setSlopedRoofTypeMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(slopedRoofSlice.actions.setSlopedRoofTypeMaterial(material));
    },
    [dispatch]
  );

  const setSlopedRoofInsulationMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(
        slopedRoofSlice.actions.setSlopedRoofInsulationMaterial(material)
      );
    },
    [dispatch]
  );

  const setThermalPerformance = useCallback(
    (thermalPerformance: SlopedRoofThermalPerformanceState) => {
      dispatch(
        slopedRoofSlice.actions.setThermalPerformance(thermalPerformance)
      );
    },
    [dispatch]
  );

  const resetConfigurationToRoofType = useCallback(
    () => dispatch(slopedRoofSlice.actions.resetConfigurationToRoofType()),
    [dispatch]
  );

  const resetConfigurationToRoofInsulation = useCallback(
    () =>
      dispatch(slopedRoofSlice.actions.resetConfigurationToRoofInsulation()),
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration: SlopedRoofConfigurationState) =>
      dispatch(slopedRoofSlice.actions.setConfiguration(configuration)),
    [dispatch]
  );

  const setHumanReadableName = useCallback(
    (name: string) =>
      dispatch(slopedRoofSlice.actions.setHumanReadableName(name)),
    [dispatch]
  );

  const resetConfiguration = useCallback(
    () => dispatch(slopedRoofSlice.actions.resetConfiguration()),
    [dispatch]
  );

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(slopedRoofSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  );

  const addScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(slopedRoofSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  );

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(slopedRoofSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  );

  return {
    slopedRoofState,
    addScreenshot,
    setOverviewScreenshot,
    slopedRoofScreenshots,
    thermalPerformance,
    setThermalPerformance,
    downloadState,
    configurationState,
    setSlopedRoofTypeType,
    setSlopedRoofInsulationType,
    setSlopedRoofTypeMaterial,
    setSlopedRoofInsulationMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToRoofType,
    resetConfigurationToRoofInsulation,
    resetConfiguration,
  };
}
