import { SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS } from "@iko-design-center/shared";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateThermalLayer } from "../../../store/sloped-roof/thermalPerformance";
import { useSlopedRoof } from "../../../store/sloped-roof/hooks";
import { useModal } from "../../../store/modal/hooks";
import { FEMaterial } from "@iko-design-center/shared";

export function useThermalCalculation() {
  const {
    configurationState,
    thermalPerformance,
    setSlopedRoofTypeMaterial,
    setSlopedRoofInsulationMaterial,
  } = useSlopedRoof();
  const { closeModal } = useModal();
  const { t } = useTranslation() as any;

  const [localThermalPerformance, setLocalThermalPerformance] = useState(
    thermalPerformance
  );

  const [localRoofTypeMaterial, setLocalRoofTypeMaterial] = useState({
    material: configurationState.roofType.material!,
    valid: true,
  });
  const [
    localRoofInsulationMaterial,
    setLocalRoofInsulationMaterial,
  ] = useState({
    material: configurationState.roofInsulation.material!,
    valid: true,
  });

  const [editableThicknessValue, setEditableThicknessValue] = useState(
    thermalPerformance.roofInsulation.thickness!.toString()
  );
  const [editableThicknessValid, setEditableThicknessValid] = useState(true);

  function handleEditableThicknessChange(
    event: SyntheticEvent<HTMLInputElement>
  ) {
    const input = event.target as HTMLInputElement;
    const type = input.dataset.type!;
    let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

    let validThickness = true;
    if (!thickness) {
      validThickness = false;
    }

    if (type && thickness.toString().length <= 3) {
      const updatedLocalMaterial = {
        material: {
          // @ts-ignore
          ...(configurationState[type].material as FEMaterial),
          thickness,
        },
        valid: validThickness,
      };

      switch (type) {
        case "roofType":
          setLocalRoofTypeMaterial(updatedLocalMaterial);
          break;
        case "roofInsulation":
          setLocalRoofInsulationMaterial(updatedLocalMaterial);
          break;
      }
      setLocalThermalPerformance(
        updateThermalLayer(
          localThermalPerformance,
          updatedLocalMaterial.material,
          type
        )
      );
    } else {
      const value = (event.target as HTMLInputElement).value;
      setEditableThicknessValue(value);

      thickness = parseInt(value);
      if (
        thickness &&
        thickness >= SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS &&
        thickness.toString().length <= 3
      ) {
        const updatedLocalMaterial = {
          material: {
            // @ts-ignore
            ...(configurationState["roofInsulation"].material as FEMaterial),
            thickness,
          },
          valid: validThickness,
        };

        setLocalRoofInsulationMaterial(updatedLocalMaterial);
        setLocalThermalPerformance(
          updateThermalLayer(
            localThermalPerformance,
            updatedLocalMaterial.material,
            "roofInsulation"
          )
        );
        setEditableThicknessValid(true);
      } else {
        setEditableThicknessValid(false);
      }
    }
  }

  function handleCancel() {
    closeModal();
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  function handleApply() {
    const allValid =
      localRoofTypeMaterial.valid &&
      localRoofInsulationMaterial.valid &&
      editableThicknessValid;

    if (allValid) {
      setSlopedRoofTypeMaterial(localRoofTypeMaterial.material);
      setSlopedRoofInsulationMaterial(localRoofInsulationMaterial.material);
      closeModal();
    }
  }

  return {
    t,
    thermalPerformance: localThermalPerformance,
    roofTypeType: configurationState.roofType.type!,
    roofInsulationType: configurationState.roofInsulation.type!,

    handleCardClick,
    handleCancel,
    handleApply,

    localRoofTypeMaterial,
    localRoofInsulationMaterial,

    editableThicknessValue,
    editableThicknessValid,
    handleEditableThicknessChange,
  };
}
