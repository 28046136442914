import { SolBuildingTypeTypes } from "@iko-design-center/shared";
import { useSolBuildingType } from "./solBuildingType.hooks";
import { getSolBuildingTypeTranslations } from "./translations";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SolBuildingType = () => {
  const {
    t,
    solBuildingType,
    optionsDisabled,
    setSolBuildingTypeType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useSolBuildingType();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("sol.solBuildingType.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled && !optionsDisabled}
    >
      <Intro>
        <H1 lineHeightPx={33}>{t("sol.solBuildingType.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={26} fontSizePx={14} innerHTML={t("sol.solBuildingType.subtitle")}> </P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={SolBuildingTypeTypes.OUI}
          checked={solBuildingType === SolBuildingTypeTypes.OUI}
          onClick={() => setSolBuildingTypeType(SolBuildingTypeTypes.OUI)}
          text={getSolBuildingTypeTranslations(t, SolBuildingTypeTypes.OUI)}
          disabled={optionsDisabled}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolBuildingTypeTypes.NON}
          checked={solBuildingType === SolBuildingTypeTypes.NON}
          onClick={() => setSolBuildingTypeType(SolBuildingTypeTypes.NON)}
          text={getSolBuildingTypeTranslations(t, SolBuildingTypeTypes.NON)}
          disabled={optionsDisabled}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
