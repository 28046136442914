import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useSlopedRoof } from "../../../store/sloped-roof/hooks";
import { renderer } from "../../viewer/Renderer";
import { useRoutes } from "../../../store/routes/hooks";

export function useRoofInsulation() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any;
  const history = useHistory();

  const {
    configurationState,
    setSlopedRoofInsulationType: setStoreSlopedWallInsulationType,
  } = useSlopedRoof();

  const slopedRoofInsulationType = configurationState.roofInsulation.type;
  const nextButtonDisabled = !slopedRoofInsulationType;

  function handleNextButtonClick() {
    history.push(Routes.SLOPED_ROOF_3_OVERVIEW);
  }

  function setSlopedRoofInsulationType(type: any) {
    setStoreSlopedWallInsulationType(type);
    renderer.displaySlopedRoofInsulation(type);
  }

  return {
    t,
    slopedRoofInsulationType,
    setSlopedRoofInsulationType,
    handleNextButtonClick,
    nextButtonDisabled,
  };
}
