import { SolLayers } from "@iko-design-center/shared"
import { MultiSelectOption } from "../../components/common/MultiSelect"

export type DownloadState = {
  technicalFiche: {
    solInsulation: boolean
  }
  DOP: {
    solInsulation: boolean
  }
  thermalCalculation: boolean
  specifications: boolean
  BIMFile: boolean
  screenshots: boolean
  processingGuideline: boolean
}

export const initialDownloadState: DownloadState = {
  technicalFiche: {
    solInsulation: false,
  },
  DOP: {
    solInsulation: false,
  },
  thermalCalculation: false,
  specifications: false,
  BIMFile: false,
  screenshots: false,
  processingGuideline: false,
}

export const allSelectedDownloadStateChapeTG: DownloadState = {
  technicalFiche: {
    solInsulation: true,
  },
  DOP: {
    solInsulation: true,
  },
  thermalCalculation: true,
  specifications: true,
  BIMFile: true,
  screenshots: true,
  processingGuideline: true,
}

export const allSelectedDownloadStateKrAlu: DownloadState = {
  technicalFiche: {
    solInsulation: true,
  },
  DOP: {
    solInsulation: true,
  },
  thermalCalculation: true,
  specifications: true,
  BIMFile: true,
  screenshots: true,
  processingGuideline: false,
}

export type LayerState = {
  [key in SolLayers]: boolean
}

export function getLayerOptions({
  t,
  state,
}: {
  t: any
  state: LayerState
}): MultiSelectOption<SolLayers>[] {
  return [
    {
      label: t("modal.download.insulation"),
      value: SolLayers.solInsulation,
      checked: state.solInsulation,
    },
  ]
}
