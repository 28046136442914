import React, { ElementType, ReactNode, SyntheticEvent } from "react";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { useModal } from "../../store/modal/hooks";
import { styles } from "../../styles";
import { Button } from "../common/Button";
import { CloseIcon } from "../common/Icons";
import { Spacer } from "../common/Spacer";
import { H1, P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";

type StandardModalProps = {
  titleIcon: ElementType;
  title: string;
  subtitle?: string;
  buttonText: string;
  children: ReactNode;
  handleSubmit?: (e: SyntheticEvent) => void;
  classnames?: string;
};

const Card = styled.section`
  min-width: 450px;
  width: ${(props) => props.theme.designWidth(448)}px;
  height: auto;
  position: relative;
  background: ${styles.colors.dark2};
  border-radius: 5px;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  padding-bottom: ${(props) => props.theme.designHeight(40)}px;
  padding-top: ${(props) => props.theme.designHeight(30)}px;
  z-index: 10001;

  &.country-and-language-selector {
    min-width: 350px;
    width: ${(props) => props.theme.designWidth(330)}px;
    padding-bottom: 30px;
    background: ${styles.colors.white};

    h1 {
      color: #000;
    }

    > svg {
      position: absolute;
      right: ${(props) => props.theme.designWidth(30)}px;
      top: ${(props) => props.theme.designWidth(30)}px;
    }

    .country-and-language-selector-title {
      svg,
      > div {
        display: none;
      }
    }
  }

  @media screen and (pointer: coarse) {
    border-radius: 10px;

    &.country-and-language-selector {
      max-width: 500px;
      width: calc(100% - 32px);
      padding: 32px 24px;
      bottom: 10%;

      h1 {
        margin-left: 19px;
        font-size: 24px;
        line-height: 28px;
      }
    }

    &.standard-modal {
      overflow-y: auto;
      min-width: unset;
      padding: 56px 16px 16px 16px;
      position: relative;

      svg {
        position: absolute;
      }

      .top-icon svg {
        top: 16px;
        left: 16px;
        width: 20px;
        height: 20px;
      }

      * {
        font-size: 14px;
        line-height: 20px;
      }

      h1 {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &.detail-info {
      width: 80%;
    }

    &.success {
      width: 80%;

      button:last-child {
        padding: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &.support,
    &.advice,
    &.mail {
      width: 100%;
      height: 100%;
      border-radius: 0;

      label {
        margin-top: 24px;
      }

      input {
        min-height: 52px;
      }

      textarea {
        min-height: 120px;
      }

      input,
      textarea {
        padding: 16px;
        font-size: 14px;
        line-height: 20px;

        & + p {
          margin-top: 8px;
        }
      }

      .checkbox {
        display: flex;
        align-items: center;
        gap: 10px;

        input, label {
          margin: 0;
        }
      }

      button:last-child {
        padding: 16px;
        margin-top: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
`;

const CloseButton = styled(UnstyledButton)`
  height: auto;
  position: absolute;
  right: ${(props) => props.theme.designWidth(30)}px;
  top: ${(props) => props.theme.designHeight(30)}px;
  width: ${(props) => props.theme.designWidth(18)}px;

  @media screen and (pointer: coarse) {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;

    svg {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    width: ${(props) => props.theme.designWidth(20)}px;
  }
`;

export const StandardModal = ({
  buttonText,
  subtitle,
  title,
  titleIcon: TitleIcon,
  children,
  handleSubmit,
  classnames,
}: StandardModalProps) => {
  const { closeModal } = useModal();

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  return (
    <Card onClick={handleCardClick} className={classnames}>
      <CloseButton>
        <CloseIcon onClick={closeModal} />
      </CloseButton>
      <TitleContainer className={classnames + "-title"}>
        <div className={"top-icon"}>
          <TitleIcon />
        </div>
        {!isMobile() && <Spacer widthPx={13.6} />}
        <H1 color={styles.colors.white}>{title}</H1>
      </TitleContainer>
      <Spacer heightPx={8} />
      <P color={styles.colors.white} lineHeightPx={20}>
        {subtitle}
      </P>
      <Spacer heightPx={4} />
      {children}
      {handleSubmit && <Spacer heightPx={24} />}
      {handleSubmit && <Button onClick={handleSubmit}>{buttonText}</Button>}
    </Card>
  );
};
