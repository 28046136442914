import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import {
  CaretDownIcon,
  CaretUpIcon,
  CheckedIcon,
  UncheckedIcon,
} from "./Icons";
import { Spacer } from "./Spacer";
import { P } from "./Typography";

export type MultiSelectOption<T> = {
  label: string;
  value: T;
  checked: boolean;
};

type MultiSelectProps<T> = {
  title: string;
  options: MultiSelectOption<T>[];
  toggleAll: () => void;
  toggleOption: (value: T) => void;
};

const Card = styled.div<{ checked: boolean }>`
  width: 100%;
  border: 1px solid ${styles.colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  background: ${(props) =>
    props.checked ? styles.colors.darkBlue10Hex : `unset`};

  p {
    color: ${(props) =>
      props.checked ? styles.colors.dark3 : styles.colors.white};
  }
`;

const Header = styled.div`
  padding-top: ${(props) => props.theme.designHeight(16)}px;
  padding-bottom: ${(props) => props.theme.designHeight(16)}px;
  padding-left: ${(props) => props.theme.designWidth(23)}px;
  padding-right: ${(props) => props.theme.designWidth(23)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (pointer: coarse) {
    padding: 16px 24px;

    p {
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
    }

    svg {
      position: relative !important;
    }
  }
`;

const HeaderColumn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const Body = styled.div`
  border-top: 1px solid ${styles.colors.white};
`;

const Row = styled(Header)`
  justify-content: start;
  cursor: pointer;
  user-select: none;
`;

export function MultiSelect<T>({
  options,
  title,
  toggleAll,
  toggleOption,
}: MultiSelectProps<T>) {
  const [open, setOpen] = useState(false);

  const totalChecked = options.filter((option) => option.checked).length || 0;
  const total = options.length;

  return (
    <Card checked={totalChecked === options.length}>
      <Header>
        <HeaderColumn onClick={toggleAll}>
          {totalChecked === 0 && <UncheckedIcon />}
          {totalChecked > 0 && <CheckedIcon />}
          <Spacer widthPx={12} />
          <Text>{title}</Text>
        </HeaderColumn>
        <HeaderColumn onClick={() => setOpen(!open)}>
          <Text>{`${totalChecked}/${total}`}</Text>
          <Spacer widthPx={23} />
          {open && <CaretUpIcon color={totalChecked === options.length ? styles.colors.dark3 : styles.colors.white} />}
          {!open && <CaretDownIcon color={totalChecked === options.length ? styles.colors.dark3 : styles.colors.white} />}
        </HeaderColumn>
      </Header>
      {open && (
        <Body>
          {options.map((option) => (
            <Row
              key={`${option.value}`}
              onClick={() => toggleOption(option.value)}
            >
              {!option.checked && <UncheckedIcon />}
              {option.checked && <CheckedIcon />}
              <Spacer widthPx={12} />
              <Text>{option.label}</Text>
            </Row>
          ))}
        </Body>
      )}
    </Card>
  );
}

const Text = ({ children }: { children: ReactNode }) => (
  <P color={styles.colors.white} fontSizePx={14} lineHeightPx={20}>
    {children}
  </P>
);
