import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useModal } from "../../store/modal/hooks";
import { CalculatorIcon, SuccessIcon } from "../common/Icons";
import { StandardModal } from "./StandardModal";

const Content = styled.div`
  height: ${(props) => props.theme.designHeight(440)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequestAdviceSuccess = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <StandardModal
      title={t("modal.requestAdviceSuccess.title")}
      buttonText={t("modal.requestAdviceSuccess.button")}
      handleSubmit={closeModal}
      subtitle={t("modal.requestAdviceSuccess.subtitle")}
      titleIcon={CalculatorIcon}
      classnames={"success standard-modal"}
    >
      <Content>
        <SuccessIcon />
      </Content>
    </StandardModal>
  );
};
