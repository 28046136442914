import {
  VentilatedWallConfigurationState,
  VentilatedWallThermalPerformanceState,
  initialVentilatedWallConfigurationState,
  initialVentilatedWallThermalPerformanceState,
} from "@iko-design-center/shared";
import { DownloadState, initialDownloadState } from "./download";

export type VentilatedWallState = {
  configuration: VentilatedWallConfigurationState;
  download: DownloadState;
  thermalPerformance: VentilatedWallThermalPerformanceState;
  screenshots: string[];
  overviewScreenshot: string | null;
};

export const initialVentilatedWallState: VentilatedWallState = {
  configuration: initialVentilatedWallConfigurationState,
  download: initialDownloadState,
  thermalPerformance: initialVentilatedWallThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
};
