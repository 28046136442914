import { SupportDTO } from "@iko-design-center/shared";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api.service";
import { useModal } from "../../store/modal/hooks";
import { styles } from "../../styles";
import { CommunicationIcon } from "../common/Icons";
import { Form, Input, Label, Required, TextArea } from "../common/Input";
import { P } from "../common/Typography";
import { StandardModal } from "./StandardModal";
import { validationRegex } from "./validationRegex";

export const Support = () => {
  const { t, i18n } = useTranslation();
  const { displaySupportSuccessModal, closeModal } = useModal();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [message, setMessage] = useState("");
  const language = i18n.language;

  function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidName(false);
    setName(e.target.value);
  }

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidEmail(false);
    setEmail(e.target.value);
  }

  function handleMessageChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setInvalidMessage(false);
    setMessage(e.target.value);
  }

  function validate(): boolean {
    let valid = true;
    const validEmail = validationRegex.email.test(String(email).toLowerCase());

    if (!validEmail) {
      setInvalidEmail(true);
      valid = false;
    }

    if (name.length <= 1) {
      valid = false;
      setInvalidName(true);
    }

    if (message.length <= 1) {
      valid = false;
      setInvalidMessage(true);
    }

    return valid;
  }

  function submitRequest() {
    const dto: SupportDTO = {
      email,
      language,
    };

    if (name !== "") {
      dto.name = name;
    }
    if (message !== "") {
      dto.message = message;
    }

    api
      .postSupport(dto)
      .then((_) => {
        displaySupportSuccessModal();
      })
      .catch((_) => {
        closeModal();
      });
  }

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    if (validate()) {
      e.currentTarget.className += " clicked";
      e.currentTarget.setAttribute("disabled", "disabled");

      submitRequest();
    }
  }

  return (
    <StandardModal
      titleIcon={CommunicationIcon}
      title={t("modal.support.title")}
      subtitle={t("modal.support.subtitle")}
      buttonText={t("modal.support.button")}
      handleSubmit={handleSubmit}
      classnames={"support standard-modal"}
    >
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">
          {t("modal.support.nameLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidName}
          type="text"
          value={name}
          required
          onChange={handleNameChange}
        />
        {invalidName && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={24}>
            {t("modal.support.requiredField")}
          </P>
        )}
        <Label htmlFor="email">
          {t("modal.support.emailLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidEmail}
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {invalidEmail && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={24}>
            {t("modal.support.invalidEmail")}
          </P>
        )}
        <Label htmlFor="message">
          {t("modal.support.messageLabel")}
          <Required>*</Required>
        </Label>
        <TextArea
          invalid={invalidMessage}
          value={message}
          required
          onChange={handleMessageChange}
        />
        {invalidMessage && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={24}>
            {t("modal.support.requiredField")}
          </P>
        )}
      </Form>
    </StandardModal>
  );
};
