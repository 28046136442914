import {
  FlatRoofConfigurationState,
  initialFlatRoofConfigurationState,
  initialFlatRoofThermalPerformanceState,
  POSSIBLE_CONFIGURATIONS,
  PossibleConfiguration,
  FlatRoofThermalPerformanceState,
} from "@iko-design-center/shared";
import { DownloadState, initialDownloadState } from "./download";

export type FlatRoofState = {
  configuration: FlatRoofConfigurationState;
  allPossibleConfigurations: PossibleConfiguration[];
  possibleConfigurations: PossibleConfiguration[];
  download: DownloadState;
  thermalPerformance: FlatRoofThermalPerformanceState;
  screenshots: string[];
  overviewScreenshot: string | null;
};

export const initialFlatRoofState: FlatRoofState = {
  configuration: initialFlatRoofConfigurationState,
  download: initialDownloadState,
  allPossibleConfigurations: POSSIBLE_CONFIGURATIONS,
  possibleConfigurations: POSSIBLE_CONFIGURATIONS,
  thermalPerformance: initialFlatRoofThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
};
