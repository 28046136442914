import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useApplication } from "../../store/application/hooks";
import { styles } from "../../styles";
import { ModelIcon, ConfiguratorIcon } from "../common/Icons";
import { P } from "../common/Typography";

const Toggle = styled.div`
  @media screen and (pointer: coarse) {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // TEMP - MOBILE
    // opacity: 0;
    // pointer-events: none;

    & > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.1s ease-out;
      width: 56px;
      height: 48px;

      &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: 1px solid ${(props) => styles.colors.dark2Opacity50};
        border-top: 1px solid ${(props) => styles.colors.dark2Opacity50};
        border-bottom: 1px solid ${(props) => styles.colors.dark2Opacity50};

        &.active {
          border-left: 1px solid ${(props) => styles.colors.dark2};
          border-top: 1px solid ${(props) => styles.colors.dark2};
          border-bottom: 1px solid ${(props) => styles.colors.dark2};
        }
      }

      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right: 1px solid ${(props) => styles.colors.dark2Opacity50};
        border-top: 1px solid ${(props) => styles.colors.dark2Opacity50};
        border-bottom: 1px solid ${(props) => styles.colors.dark2Opacity50};

        &.active {
          border-right: 1px solid ${(props) => styles.colors.dark2};
          border-top: 1px solid ${(props) => styles.colors.dark2};
          border-bottom: 1px solid ${(props) => styles.colors.dark2};
        }
      }

      svg {
        pointer-events: none;
        width: 28px;
        height: 28px;

        * {
          transition: all 0.1s ease-out;
          fill: ${(props) => styles.colors.dark2Opacity50};
        }
      }

      p {
        font-weight: bold;
        font-size: 11px !important;
        line-height: 11px !important;
        color: ${(props) => styles.colors.dark2Opacity50};
        margin: 0;
      }

      &.active {
        background-color: ${(props) => styles.colors.dark2};

        svg * {
          fill: ${(props) => styles.colors.white};
        }

        p {
          color: ${(props) => styles.colors.white};
        }
      }
    }
  }
`;

export const SwiperToggle = ({ onClick }: any) => {
  const { modelView } = useApplication();
  const { t } = useTranslation() as any;

  return (
    <Toggle onClick={onClick}>
      <div className={modelView ? "active" : ""}>
        <ModelIcon />
        <P>{t("navigation.mobile.viewer")}</P>
      </div>
      <div className={!modelView ? "active" : ""}>
        <ConfiguratorIcon />
        <P>{t("navigation.mobile.config")}</P>
      </div>
    </Toggle>
  );
};
