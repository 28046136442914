import { CavityWallConfigurationState } from "@iko-design-center/shared";
import React, { ReactNode, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useApplication } from "../../../store/application/hooks";
import { ApplicationTypes } from "../../../store/application/store";
import { Home } from "../Home";
import { useCavityWall } from "../../../store/cavity-wall/hooks";
import { useRoutes } from "../../../store/routes/hooks";

export const Guard = (props: { children: ReactNode }) => {
  return (
    <>
      <ResetStateGuard>
        <ValidStateGuard>{props.children}</ValidStateGuard>
      </ResetStateGuard>
    </>
  );
};

// This makes sure, that when a user navigates backwards,
// either via the Navbar or via the browser back button,
// the state is reset. This so the user can reselect any options.
const ResetStateGuard = (props: { children: ReactNode }) => {
  const {
    resetConfiguration,
    resetConfigurationToWallType,
    resetConfigurationToWallInsulation,
  } = useCavityWall();
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const routeHome = Routes.HOME;
  const routeCavityWallWallType = Routes.CAVITY_WALL_1_WALL_TYPE;
  const routeCavityWallWallInsulation = Routes.CAVITY_WALL_2_WALL_INSULATION;
  const routeCavityWallOverview = Routes.CAVITY_WALL_3_OVERVIEW;

  useEffect(() => {
    switch (location.pathname) {
      case routeHome:
        resetConfiguration();
        break;
      case routeCavityWallWallType:
        resetConfigurationToWallType();
        break;
      case routeCavityWallWallInsulation:
        resetConfigurationToWallInsulation();
        break;
      case routeCavityWallOverview:
        break;
    }
  }, [
    resetConfiguration,
    resetConfigurationToWallType,
    resetConfigurationToWallInsulation,
    location.pathname,
    routeHome,
    routeCavityWallWallType,
    routeCavityWallWallInsulation,
    routeCavityWallOverview,
  ]);

  return <>{props.children}</>;
};

// When a user refreshes the screen on let's say the OverviewPage,
// No state will be present in Redux, thus 'cannot read of undefined's will pop up.
// This prevents that.
const ValidStateGuard = (props: { children: ReactNode }) => {
  const { directLink, setDirectLink, setApplicationType, applicationType } = useApplication();
  const location = useLocation();
  const { configurationState } = useCavityWall();
  const { Routes } = useRoutes() as any;
  const history = useHistory();

  const valid = checkValidStateForRoute(
    applicationType,
    configurationState,
    location.pathname,
    Routes
  );

  useEffect(() => {
    if (!valid) {
      if (!directLink) {
        setDirectLink(true)
        setApplicationType(ApplicationTypes.CAVITY_WALL);
        history.push(Routes.CAVITY_WALL_1_WALL_TYPE);
      } else {
        history.push(Routes.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, setApplicationType, history, setDirectLink, directLink])

  return valid ? <>{props.children}</> : <Home />;
};

function checkValidStateForRoute(
  applicationType: ApplicationTypes | null,
  configurationState: CavityWallConfigurationState,
  route: string,
  Routes: any
): boolean | undefined {
  const { wallType, wallInsulation, outerCavityLeaf } = configurationState;

  const applicationTypeValid = applicationType !== null;
  const wallTypeValid = wallType.type !== null;
  const wallInsulationValid = wallInsulation.type !== null;
  const outerCavityLeafValid = outerCavityLeaf.type !== null;

  switch (route) {
    case Routes.CAVITY_WALL_3_OVERVIEW:
      return wallTypeValid && wallInsulationValid && outerCavityLeafValid;
    case Routes.CAVITY_WALL_2_WALL_INSULATION:
      return wallTypeValid;
    case Routes.CAVITY_WALL_1_WALL_TYPE:
      return applicationTypeValid;
    case Routes.HOME:
      return true;
  }
}
