import React from "react";
import styled from "styled-components";
import { InfoIcon } from "../common/Icons";
import { UnstyledButton } from "../common/UnstyledButton";
import { Models } from "./renderer.api";
import { useModal } from "../../store/modal/hooks";
import { useApplication } from "../../store/application/hooks";
import { useViewerDetail } from "./viewerDetail.hooks";

const SectionRight = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 7;
  display: flex;
  align-items: flex-start;
  transform: scale(1.5);

  @media screen and (pointer: coarse) {
    bottom: 16px;
    right: 16px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

export const ViewerDetail = () => {
  const { displayDetailInfoModal } = useModal();
  const { currentModel } = useApplication();
  const { detailDescription } = useViewerDetail();
  const description = detailDescription();
  const shouldShow =
    currentModel !== Models.DOLLHOUSE &&
    // FLAT ROOF
    currentModel !== Models.FLAT_ROOF &&
    // CAVITY WALL
    currentModel !== Models.CAVITY_WALL &&
    // SLOPED ROOF
    currentModel !== Models.SLOPED_ROOF &&
    // VENTILATED WALL
    currentModel !== Models.VENTILATED_WALL &&
    // FLOOR
    currentModel !== Models.FLOOR &&
    description !== undefined;

  function handleInfoClick() {
    displayDetailInfoModal();
  }

  return (
    <SectionRight>
      {shouldShow && (
        <UnstyledButton onClick={handleInfoClick}>
          <InfoIcon />
        </UnstyledButton>
      )}
    </SectionRight>
  );
};
