import { VentilatedWallFacadeCladdingTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useFacadeCladding } from "./facadeCladding.hooks";
import { getFacadeCladdingTranslations } from "./translations";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const FacadeCladding = () => {
  const {
    t,
    ventilatedWallFacadeCladdingType,
    setVentilatedWallFacadeCladdingType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useFacadeCladding();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("ventilatedWall.facadeCladding.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("ventilatedWall.facadeCladding.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("ventilatedWall.facadeCladding.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL}
          checked={
            ventilatedWallFacadeCladdingType ===
            VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL
          }
          onClick={() =>
            setVentilatedWallFacadeCladdingType(
              VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL
            )
          }
          text={getFacadeCladdingTranslations(
            t,
            VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL
          )}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE}
          checked={
            ventilatedWallFacadeCladdingType ===
            VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE
          }
          onClick={() =>
            setVentilatedWallFacadeCladdingType(
              VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE
            )
          }
          text={getFacadeCladdingTranslations(
            t,
            VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE
          )}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
