import { Layers, RoofDetails } from "@iko-design-center/shared";
import { MultiSelectOption } from "../../components/common/MultiSelect";

export type DownloadState = {
  technicalFiche: {
    topLayer: boolean;
    bottomLayer: boolean;
    insulation: boolean;
    vaporShield: boolean;
    roofFloor: boolean;
  };
  DOP: {
    topLayer: boolean;
    bottomLayer: boolean;
    insulation: boolean;
    vaporShield: boolean;
    roofFloor: boolean;
  };
  roofDetails: {
    roofSurface: boolean;
    roofUpstand: boolean;
    drainagePipe: boolean;
    dome: boolean;
    angleConnection: boolean;
    settlementJoint: boolean;
    verticalWall: boolean;
  };
  thermalCalculation: boolean;
  specifications: boolean;
  BIMFile: boolean;
  screenshots: boolean;
  processingGuideline: boolean;
};

export const initialDownloadState: DownloadState = {
  technicalFiche: {
    topLayer: false,
    bottomLayer: false,
    insulation: false,
    vaporShield: false,
    roofFloor: false,
  },
  DOP: {
    topLayer: false,
    bottomLayer: false,
    insulation: false,
    vaporShield: false,
    roofFloor: false,
  },
  roofDetails: {
    roofSurface: false,
    roofUpstand: false,
    drainagePipe: false,
    dome: false,
    angleConnection: false,
    settlementJoint: false,
    verticalWall: false,
  },
  thermalCalculation: false,
  specifications: false,
  BIMFile: false,
  screenshots: false,
  processingGuideline: false,
};

export const allSelectedDownloadState: DownloadState = {
  technicalFiche: {
    topLayer: true,
    bottomLayer: true,
    insulation: true,
    vaporShield: true,
    roofFloor: true,
  },
  DOP: {
    topLayer: true,
    bottomLayer: true,
    insulation: true,
    vaporShield: true,
    roofFloor: true,
  },
  roofDetails: {
    roofSurface: true,
    roofUpstand: true,
    drainagePipe: true,
    dome: true,
    angleConnection: true,
    settlementJoint: true,
    verticalWall: true,
  },
  thermalCalculation: true,
  specifications: true,
  BIMFile: true,
  screenshots: true,
  processingGuideline: true,
};

export type LayerState = {
  [key in Layers]: boolean;
};

type RoofDetailsState = {
  [key in RoofDetails]: boolean;
};

export function getLayerOptions({
  t,
  state,
}: {
  t: any;
  state: LayerState;
}): MultiSelectOption<Layers>[] {
  return [
    {
      label: t("modal.download.topLayer"),
      value: Layers.topLayer,
      checked: state.topLayer,
    },
    {
      label: t("modal.download.bottomLayer"),
      value: Layers.bottomLayer,
      checked: state.bottomLayer,
    },
    {
      label: t("modal.download.insulation"),
      value: Layers.insulation,
      checked: state.insulation,
    },
    {
      label: t("modal.download.vaporShield"),
      value: Layers.vaporShield,
      checked: state.vaporShield,
    },
  ];
}

export function getRoofDetailOptions({
  t,
  state,
}: {
  t: any;
  state: RoofDetailsState;
}): MultiSelectOption<RoofDetails>[] {
  return [
    {
      label: t("modal.download.roofSurface"),
      value: RoofDetails.roofSurface,
      checked: state.roofSurface,
    },
    {
      label: t("modal.download.roofUpstand"),
      value: RoofDetails.roofUpstand,
      checked: state.roofUpstand,
    },
    {
      label: t("modal.download.drainagePipe"),
      value: RoofDetails.drainagePipe,
      checked: state.drainagePipe,
    },
    {
      label: t("modal.download.dome"),
      value: RoofDetails.dome,
      checked: state.dome,
    },
    {
      label: t("modal.download.angleConnection"),
      value: RoofDetails.angleConnection,
      checked: state.angleConnection,
    },
    {
      label: t("modal.download.settlementJoint"),
      value: RoofDetails.settlementJoint,
      checked: state.settlementJoint,
    },
    {
      label: t("modal.download.verticalWall"),
      value: RoofDetails.verticalWall,
      checked: state.verticalWall,
    },
  ];
}
