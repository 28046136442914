import React, { useMemo } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "./Typography";
import { useTranslation } from "react-i18next";
import { SolNoiseLevelTypes } from "@iko-design-center/shared";
import { SolAcousticLayerTypes } from "@iko-design-center/shared";

const Container = styled.div<{ background: boolean }>`
  background: ${(props) =>
    props.background ? styles.colors.darkBlue5 : "unset"};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  & > div {
    &:nth-of-type(even) {
      background: ${styles.colors.white};
    }
  }
`;
export const AcousticPerformance = ({ noiseLevelType, acousticLayer, thickness }: any) => {
  const { t } = useTranslation() as any

  const selection = useMemo(() => {
    switch (noiseLevelType) {
      case SolNoiseLevelTypes.DB_18:
        switch (acousticLayer) {
          case SolAcousticLayerTypes.AUCUNE:
            if (25 <= thickness && thickness <= 39) return '18_0'
            if (40 <= thickness && thickness <= 53) return '18_1'
            if (54 <= thickness && thickness <= 140) return '18_2'
            break

          case SolAcousticLayerTypes.ASSOURCHAPE_20:
            if (81 <= thickness) return '18_A'
            break

          case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
            if (30 <= thickness && thickness <= 80) return '18_T_30'
            if (81 <= thickness) return '18_T'
            break
        }
        return -1;

      case SolNoiseLevelTypes.DB_19:
        switch (acousticLayer) {
          case SolAcousticLayerTypes.AUCUNE:
            if (40 <= thickness && thickness <= 53) return '19_1'
            if (54 <= thickness && thickness <= 140) return '19_2'
            break

          case SolAcousticLayerTypes.ASSOURCHAPE_20:
            if (81 <= thickness) return '19_A'
            break

          case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
            if (30 <= thickness && thickness <= 80) return '19_T_30'
            if (81 <= thickness) return '19_T'
            break
        }
        return -1;

      case SolNoiseLevelTypes.DB_20:
        switch (acousticLayer) {
          case SolAcousticLayerTypes.AUCUNE:
            if (54 <= thickness && thickness <= 140) return '20_2'
            break

          case SolAcousticLayerTypes.ASSOURCHAPE_20:
            if (81 <= thickness) return '20_A'
            break

          case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
            if (30 <= thickness && thickness <= 80) return '20_T_30'
            if (81 <= thickness) return '20_T'
            break
        }
        return -1;

      case SolNoiseLevelTypes.DB_22:
        switch (acousticLayer) {
          case SolAcousticLayerTypes.ASSOURCHAPE_20:
            if (81 <= thickness) return '22_A'
            break

          case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
            if (81 <= thickness) return '22_T'
            break
        }
        return -1;

      case SolNoiseLevelTypes.DB_23:
        switch (acousticLayer) {
          case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
            if (81 <= thickness) return '23_T'
            break
        }
        return -1;
    }
  }, [thickness, acousticLayer, noiseLevelType])

  const renderRows = () => {
    switch (noiseLevelType) {
      case SolNoiseLevelTypes.DB_18:
        return <>
          <Row
            title={t('sol.overview.acousticPerformance.18Title')}
            value={t('sol.overview.acousticPerformance.18Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_0'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.19Title')}
            value={t('sol.overview.acousticPerformance.19Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_1'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.20Title')}
            value={t('sol.overview.acousticPerformance.20Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_2'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.22Title')}
            value={t('sol.overview.acousticPerformance.22Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_A'}
            extra={'ASSOURCHAPE 20'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.23Title')}
            value={t('sol.overview.acousticPerformance.23Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_T'}
            extra={'TRAMICHAPE ECO PRO'}
          />
          <Row
            title={'CHAPE TG ≥ 30 mm'}
            value={'20'}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '18_T_30'}
            extra={'TRAMICHAPE ECO PRO'}
          />
        </>
      case SolNoiseLevelTypes.DB_19:
        return <>
          <Row
            title={t('sol.overview.acousticPerformance.19Title')}
            value={t('sol.overview.acousticPerformance.19Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '19_1'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.20Title')}
            value={t('sol.overview.acousticPerformance.20Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '19_2'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.22Title')}
            value={t('sol.overview.acousticPerformance.22Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '19_A'}
            extra={'ASSOURCHAPE 20'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.23Title')}
            value={t('sol.overview.acousticPerformance.23Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '19_T'}
            extra={'TRAMICHAPE ECO PRO'}
          />
          <Row
            title={'CHAPE TG ≥ 30 mm'}
            value={'20'}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '19_T_30'}
            extra={'TRAMICHAPE ECO PRO'}
          />
        </>
      case SolNoiseLevelTypes.DB_20:
        return <>
          <Row
            title={t('sol.overview.acousticPerformance.20Title')}
            value={t('sol.overview.acousticPerformance.20Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '20_2'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.22Title')}
            value={t('sol.overview.acousticPerformance.22Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '20_A'}
            extra={'ASSOURCHAPE 20'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.23Title')}
            value={t('sol.overview.acousticPerformance.23Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '20_T'}
            extra={'TRAMICHAPE ECO PRO'}
          />
          <Row
            title={'CHAPE TG ≥ 30 mm'}
            value={'20'}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '20_T_30'}
            extra={'TRAMICHAPE ECO PRO'}
          />
        </>
      case SolNoiseLevelTypes.DB_22:
        return <>
          <Row
            title={t('sol.overview.acousticPerformance.22Title')}
            value={t('sol.overview.acousticPerformance.22Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '22_A'}
            extra={'ASSOURCHAPE 20'}
          />
          <Row
            title={t('sol.overview.acousticPerformance.23Title')}
            value={t('sol.overview.acousticPerformance.23Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '22_T'}
            extra={'TRAMICHAPE ECO PRO'}
          />
        </>
      case SolNoiseLevelTypes.DB_23:
        return <>
          <Row
            title={t('sol.overview.acousticPerformance.23Title')}
            value={t('sol.overview.acousticPerformance.23Value')}
            unit={t('sol.overview.acousticPerformance.unit')}
            selected={selection === '23_T'}
            extra={'TRAMICHAPE ECO PRO'}
          />
        </>
    }
  }

  return (
    <Container background={true}>
      {renderRows()}
    </Container>
  );
};

const ValueContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: Row;
  border-left: ${(props) =>
    props.selected ? `3px solid ${styles.colors.black}` : `unset`};
  padding-inline: ${(props) => props.theme.designHeight(16)}px;
  padding-top: ${(props) => props.theme.designHeight(12)}px;
  padding-bottom: ${(props) => props.theme.designHeight(12)}px;
  min-height: 61px;

  .selected {
    font-size: 9px;
    line-height: 14px;
    font-weight: 700;
    margin-bottom: 7px;
    display: block;
    color: ${styles.colors.black};
    text-transform: uppercase;
  }

  @media screen and (pointer: coarse) {
    p {
      font-size: 13px;
      line-height: 16px;
    }
  }
`;

const ValueItem = styled.div`
  p {
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  .unit {
    margin-left: 6px;
    white-space: nowrap;
    text-transform: lowercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  @media screen and (pointer: coarse) {
    p {
      font-size: 18px;
    }

    .unit {
      font-size: 13px;
    }
  }
`;

const Row = ({
  title,
  value,
  unit,
  selected,
  extra,
}: {
  title: string;
  value: any;
  unit: string;
  selected: boolean;
  extra?: string;
}) => {
  const { t } = useTranslation() as any
  return (
    <ValueContainer selected={selected}>
      <div>
        {selected &&
          <span className="selected">{t('sol.overview.acousticPerformance.selection')}</span>
        }
        <P fontSizePx={13} color={styles.colors.dark2} lineHeightPx={16} innerHTML={title} fontWeight={400}>
          {title}
        </P>
        {extra &&
          <P fontSizePx={10} color={styles.colors.dark2} lineHeightPx={14} fontWeight={400}>
            + {extra}
          </P>
        }
      </div>
      <ValueItem>
        <P fontSizePx={24} color={styles.colors.dark2} lineHeightPx={24} fontWeight={700}>
          {value} <span className="unit">{unit}</span>
        </P>
      </ValueItem>
    </ValueContainer>
  );
};
