import {
  VENTILATED_WALL_R_VALUE_RSE,
  VENTILATED_WALL_R_VALUE_RSI,
} from "@iko-design-center/shared";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import { OverviewItem, OverviewItemProps } from "../../common/OverviewItem";
import { Spacer } from "../../common/Spacer";
import { ThermalPerformance } from "../../common/ThermalPerformance";
import { H2, P, TypographyProps } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useThermalCalculation } from "./thermalCalculation.hook";
import { Input } from "../../common/Input";
import {
  getWallTypeTranslations,
  getWallInsulationTranslations,
} from "./translations";
import { isMobile } from "../../../services/helpers.service";
import { CloseIcon } from "../../common/Icons";

const Section = styled.section`
  border-radius: 5px;
  width: ${(props) => props.theme.designWidth(728)}px;
  background: ${styles.colors.white};
  overflow: hidden;

  @media screen and (pointer: coarse) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
    padding-bottom: 128px;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  height: ${(props) => props.theme.designHeight(80)}px;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  padding-top: ${(props) => props.theme.designHeight(24)}px;
  padding-bottom: ${(props) => props.theme.designHeight(18)}px;
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    padding: 48px 16px 16px 16px;

    h2 {
      font-size: 24px;
      line-height: 30px;
      color: ${styles.colors.dark2};
    }
  }
`;

const LeftColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RightColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.1666666;
  justify-content: flex-start;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${styles.colors.darkBlue10};
`;

const TableBody = styled.div``;

const TableFooter = styled.div`
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  height: ${(props) => props.theme.designHeight(168)}px;
  background: ${styles.colors.darkBlue5};
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    padding: 0;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    height: auto;

    & > div:first-child {
      order: 1;

      * {
        font-size: 12px;
        line-height: 18px;
      }
    }

    & > div {
      padding: 20px 16px;
      display: flex;
      width: 100%;
    }
  }
`;
const TableFooterLeftColumn = styled(LeftColumn)`
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.theme.designHeight(24)}px;
  padding-bottom: ${(props) => props.theme.designHeight(18)}px;
`;

const TableFooterRightColumn = styled.div`
  height: 100%;
  flex: 0.5;
`;

const TableActions = styled.div`
  display: flex;
`;

const TableActionsLeftColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  background: ${styles.colors.darkBlue10};
`;

const ApplyButton = styled(UnstyledButton)`
  &:hover {
    background: ${styles.colors.buttonHover};
  }

  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${styles.colors.dark2};

  @media screen and (pointer: coarse) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: unset;
    padding: 24px 0;

    * {
      font-size: 20px;
      line-height: 23px;
    }

    svg {
      margin-top: 6px;
    }
  }
`;

const CancelButton = styled(UnstyledButton)`
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  padding-left: ${(props) => props.theme.designWidth(40)}px;

  @media screen and (pointer: coarse) {
    display: none;
  }
`;

const CloseButton = styled(UnstyledButton)`
  @media screen and (pointer: coarse) {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;

    svg {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);

      * {
        fill: ${styles.colors.dark2};
      }
    }
  }
`;

const h2Styles = {
  fontWeight: 500,
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

const unitStyles = {
  fontSizePx: 12,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

export const ThermalCalculation = () => {
  const {
    t,
    thermalPerformance,
    wallTypeType,
    wallInsulationType,

    handleCardClick,
    handleCancel,
    handleApply,

    localWallTypeMaterial,

    editableThicknessValid,
    editableThicknessValue,
    handleEditableThicknessChange,
  } = useThermalCalculation();

  return (
    <Section onClick={handleCardClick}>
      {isMobile() && (
        <CloseButton>
          <CloseIcon onClick={handleCancel} />
        </CloseButton>
      )}
      {!isMobile() ? (
        <TableHeader>
          <LeftColumn>
            <H2 {...h2Styles}>{t("thermalCalculation.headerLayer")}</H2>
          </LeftColumn>
          <RightColumn>
            <H2 {...h2Styles}>{t("thermalCalculation.headerThickness")}</H2>
            <P {...unitStyles}>{t("units.thicknessUnit")}</P>
          </RightColumn>
          <RightColumn>
            <H2 {...h2Styles}>{t("thermalCalculation.headerLambdaValue")}</H2>
            <P {...unitStyles}>{t("units.lambdaUnit")}</P>
          </RightColumn>
          <RightColumn>
            <H2 {...h2Styles}>
              {t("thermalCalculation.headerInsulationValue")}
            </H2>
            <P {...unitStyles}>{t("units.insulationUnit")}</P>
          </RightColumn>
        </TableHeader>
      ) : (
        <TableHeader>
          <H2 {...h2Styles}>{t("thermalCalculation.headerLayers")}</H2>
        </TableHeader>
      )}
      <TableBody>
        {!isMobile() && <Line />}
        <RSRow type={"RSI"} />
        <Line />
        <TableRow
          type="wallType"
          title={t("ventilatedWall.overview.wallType").toUpperCase()}
          text={getWallTypeTranslations(t, wallTypeType)}
          thickness={thermalPerformance.wallType.thickness!}
          lambdaValue={thermalPerformance.wallType.lambda!}
          insulationValue={thermalPerformance.wallType.RValue!}
          editableThickness={true}
          editableThicknessValue={localWallTypeMaterial.material.thickness!.toString()}
          onEditableThicknessChange={handleEditableThicknessChange}
          editableThicknessValid={localWallTypeMaterial.valid}
        />
        <Line />
        <TableRow
          type="wallInsulation"
          title={t("ventilatedWall.overview.wallInsulation").toUpperCase()}
          text={getWallInsulationTranslations(t, wallInsulationType)}
          thickness={thermalPerformance.wallInsulation.thickness!}
          lambdaValue={thermalPerformance.wallInsulation.lambda!}
          insulationValue={thermalPerformance.wallInsulation.RValue!}
        />
        <Line />
        <RSRow type={"RSE"} />
      </TableBody>
      <TableFooter>
        <TableFooterLeftColumn>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <P fontSizePx={12} lineHeightPx={18} color={styles.colors.dark2}>
              {t("ventilatedWall.thermalCalculation.info")}
            </P>
          </div>
          <Spacer widthPx={40} />
        </TableFooterLeftColumn>
        <TableFooterRightColumn>
          <ThermalPerformance
            insulationThickness={thermalPerformance.wallInsulation.thickness!}
            UCValue={thermalPerformance.UCValue!}
            RTValue={thermalPerformance.RTValue!}
            background={false}
            editThickness={true}
            editableThicknessValid={editableThicknessValid}
            editableThicknessValue={editableThicknessValue}
            onEditableThicknessChange={handleEditableThicknessChange}
          />
        </TableFooterRightColumn>
      </TableFooter>
      <TableActions>
        <TableActionsLeftColumn>
          <CancelButton onClick={handleCancel}>
            <P fontSizePx={14} fontWeight={500} color={styles.colors.dark2}>
              {t("thermalCalculation.modalCancel")}
            </P>
          </CancelButton>
        </TableActionsLeftColumn>
        <ApplyButton onClick={handleApply}>
          <P fontSizePx={14} fontWeight={500} color={styles.colors.white}>
            {t("thermalCalculation.modalApply")}
          </P>
        </ApplyButton>
      </TableActions>
    </Section>
  );
};

const TableRowContainer = styled.div`
  height: ${(props) => props.theme.designHeight(64)}px;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
  display: flex;

  @media screen and (pointer: coarse) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;

    h2 {
      font-size: 12px;
      line-height: 14px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }

    input {
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      padding: 10px;
    }
  }
`;

const TableRowLeftColumn = styled(LeftColumn)`
  justify-content: center;

  @media screen and (pointer: coarse) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const TableRowRightColumn = styled(RightColumn)`
  justify-content: center;

  @media screen and (pointer: coarse) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 8px 0;

    p:first-of-type {
      font-size: 14px;
      line-height: 22px;
      color: ${styles.colors.darkBlue};
    }
  }
`;

const CustomInput = styled(Input)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60px;
  font-weight: 700;
  font-size: ${16 / 16}rem;
  line-height: 16px;
  padding: 10px;
`;

const valueStyle = {
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

type TableRowProps = {
  type: string;
  thickness: number;
  lambdaValue: number;
  insulationValue: number;
  editableThickness?: Boolean;
  onEditableThicknessChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  editableThicknessValue?: string;
  editableThicknessValid?: boolean;
} & OverviewItemProps;

const TableRow = ({
  type,
  title,
  text,
  suffix,
  thickness,
  insulationValue,
  lambdaValue,
  editableThickness,
  onEditableThicknessChange,
  editableThicknessValue,
  editableThicknessValid,
}: TableRowProps) => {
  const { t } = useThermalCalculation();
  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <OverviewItem title={title} text={text} suffix={suffix} />
      </TableRowLeftColumn>
      <TableRowRightColumn>
        {isMobile() && <P>{t("thermalCalculation.headerThickness")}</P>}
        {editableThickness && (
          <CustomInput
            data-type={type}
            invalid={!editableThicknessValid}
            value={editableThicknessValue ?? thickness}
            onChange={onEditableThicknessChange}
          />
        )}
        {!editableThickness && <P {...valueStyle}>{thickness}</P>}
      </TableRowRightColumn>
      <TableRowRightColumn>
        {isMobile() && <P>{t("thermalCalculation.headerLambdaValue")}</P>}
        <P {...valueStyle}>{lambdaValue}</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        {isMobile() && <P>{t("thermalCalculation.headerInsulationValue")}</P>}
        <P {...valueStyle}>{insulationValue}</P>
      </TableRowRightColumn>
    </TableRowContainer>
  );
};

const RSRow = ({ type }: { type: "RSI" | "RSE" }) => {
  const { t } = useThermalCalculation();
  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <P
          fontWeight={400}
          fontSizePx={16}
          color={styles.colors.dark2}
          lineHeightPx={20}
        >
          {type}
        </P>
      </TableRowLeftColumn>
      {!isMobile() && <TableRowRightColumn />}
      {!isMobile() && <TableRowRightColumn />}
      <TableRowRightColumn>
        {isMobile() && <P>{t("thermalCalculation.headerInsulationValue")}</P>}
        <P {...valueStyle}>
          {type === "RSI"
            ? VENTILATED_WALL_R_VALUE_RSI
            : VENTILATED_WALL_R_VALUE_RSE}
        </P>
      </TableRowRightColumn>
    </TableRowContainer>
  );
};
