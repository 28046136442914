import { WaterproofingTypes } from "@iko-design-center/shared";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { styles } from "../../../styles";
import { InfoIcon } from "../../common/Icons";
import { RadioButton } from "../../common/RadioButton";
import { Spacer } from "../../common/Spacer";
import { FontFamilies, H1, H2, P } from "../../common/Typography";
import { Sidebar } from "../Sidebar";
import { useWaterproofing } from "./waterproofing.hooks";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";
import { useRoutes } from "../../../store/routes/hooks";

export const Waterproofing = () => {
  const {
    t,
    type,
    setWaterproofingType,
    handleNextButtonClick,
    nextButtonDisabled,
    bituminousDisabled,
    liquidDisabled,
  } = useWaterproofing();

  const { Routes } = useRoutes() as any
  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonDisabled={nextButtonDisabled}
      nextButtonText={`${t("flatRoof.waterproofing.nextButton")} (${currentStep + 1}/${totalSteps})`}
    >
      <Intro>
        <H1>{t("flatRoof.waterproofing.title")}</H1>
      </Intro>
      {!isMobile() && <Spacer heightPx={40} />}
      <RadioButtonList>
        <RadioButton
          value={WaterproofingTypes.BITUMINOUS}
          checked={type === WaterproofingTypes.BITUMINOUS}
          onClick={() => setWaterproofingType(WaterproofingTypes.BITUMINOUS)}
          text={t("flatRoof.waterproofing.bituminous")}
          disabled={bituminousDisabled}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={WaterproofingTypes.LIQUID}
          checked={type === WaterproofingTypes.LIQUID}
          onClick={() => setWaterproofingType(WaterproofingTypes.LIQUID)}
          text={t("flatRoof.waterproofing.liquid")}
          disabled={liquidDisabled}
        />
        {bituminousDisabled && (
          <DisabledInfo
            disabledText={t("flatRoof.waterproofing.bituminousDisabled")}
            disabledReturnText={t("flatRoof.waterproofing.returnToRoofType")}
            disabledReturnLink={Routes.FLAT_ROOF_1_ROOF_TYPE}
          />
        )}
        {liquidDisabled && (
          <DisabledInfo
            disabledText={t("flatRoof.waterproofing.liquidDisabled")}
            disabledReturnText={t("flatRoof.waterproofing.returnToRoofType")}
            disabledReturnLink={Routes.FLAT_ROOF_1_ROOF_TYPE}
          />
        )}
        {type === WaterproofingTypes.BITUMINOUS && (
          <Info
            title={t("flatRoof.waterproofing.bituminous")}
            info={t("flatRoof.waterproofing.bituminousInfo")}
          />
        )}
        {type === WaterproofingTypes.LIQUID && (
          <Info
            title={t("flatRoof.waterproofing.liquid")}
            info={t("flatRoof.waterproofing.liquidInfo")}
          />
        )}
      </RadioButtonList>
    </Sidebar>
  );
};

type InfoProps = {
  title: string;
  info: string;
};

const Text = styled.div`
  font-size: ${13 / 16}rem;
  color: ${styles.colors.darkBlue};

  @media screen and (pointer: coarse) {
    padding: 0;
    font-size: 15px;
    line-height: 22px;
    color: #2e3341;
  }
`;

const Info = ({ title, info }: InfoProps) => {
  return (
    <>
      <Spacer heightPx={!isMobile() ? 40 : 16} />
      <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
        {title}
      </H2>
      {!isMobile() && <Spacer heightPx={16} />}
      {isMobile() ? (
        <Text>{info}</Text>
      ) : (
        <P fontSizePx={13} color={styles.colors.darkBlue}>
          {info}
        </P>
      )}
    </>
  );
};

const DisabledInfoContainer = styled.div`
  display: inline-flex;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-family: ${FontFamilies.ROBOTO};
  font-size: ${12 / 16}rem;
  color: ${styles.colors.dark2};
  font-weight: 500;
  line-height: 1rem;
  color: ${styles.colors.darkBlue};
`;

type DisabledInfoProps = {
  disabledText: string;
  disabledReturnLink?: string;
  disabledReturnText?: string;
};

const DisabledInfo = ({
  disabledText,
  disabledReturnLink,
  disabledReturnText,
}: DisabledInfoProps) => {
  return (
    <>
      <Spacer heightPx={12} />
      <DisabledInfoContainer>
        <P fontSizePx={12} lineHeightPx={16} color={styles.colors.darkBlue}>
          <InfoIcon />
          {"\u00A0"}
          {disabledText}
          {"\u00A0"}
          {disabledReturnLink && disabledReturnText && (
            <StyledLink to={disabledReturnLink}>
              {disabledReturnText}
            </StyledLink>
          )}
        </P>
      </DisabledInfoContainer>
    </>
  );
};
