import { ReactNode, useLayoutEffect, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    innerWidth: number;
    innerHeight: number;
    designHeight: (height: number) => number;
    designWidth: (width: number) => number;
  }
}

export const DESIGN_DEVICE_HEIGHT = 900;
export const DESIGN_DEVICE_WIDTH = 1400;

export const SizeProvider = (props: { children: ReactNode }) => {
  const initialState: DefaultTheme = {
    innerWidth: document.documentElement.clientWidth,
    innerHeight: document.documentElement.clientHeight,
    designHeight: function (height: number) {
      return (height / DESIGN_DEVICE_HEIGHT) * this.innerHeight;
    },
    designWidth: function (width: number) {
      return (width / DESIGN_DEVICE_WIDTH) * this.innerWidth;
    },
  };

  const [sizes, setSizes] = useState<DefaultTheme>(initialState);
  useLayoutEffect(() => {
    function updateSize() {
      setSizes((s) => ({
        ...s,
        innerWidth: document.documentElement.clientWidth,
        innerHeight: document.documentElement.clientHeight,
      }));
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return <ThemeProvider theme={sizes}>{props.children}</ThemeProvider>;
};
