import {
  FloorConfigurationState,
  FloorThermalPerformanceState,
  FEMaterial,
} from "@iko-design-center/shared";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { DownloadState } from "./download";
import { floorSlice } from "./store";

export function useFloor() {
  const dispatch = useAppDispatch();
  const floorState = useAppSelector((state) => state.floor);
  const configurationState = useAppSelector(
    (state) => state.floor.configuration
  );
  const downloadState = useAppSelector((state) => state.floor.download);
  const thermalPerformance = useAppSelector(
    (state) => state.floor.thermalPerformance
  );
  const floorScreenshots = useAppSelector((state) => state.floor.screenshots);

  const setFloorHeatingType = useCallback(
    (type: any) => {
      dispatch(floorSlice.actions.setFloorHeatingType(type));
    },
    [dispatch]
  );
  const setFloorInsulationType = useCallback(
    (type: any) => {
      dispatch(floorSlice.actions.setFloorInsulationType(type));
    },
    [dispatch]
  );
  const setFloorScreedType = useCallback(
    (type: any) => {
      dispatch(floorSlice.actions.setFloorScreedType(type));
    },
    [dispatch]
  );

  const setFloorHeatingMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(floorSlice.actions.setFloorHeatingMaterial(material));
    },
    [dispatch]
  );
  const setFloorInsulationMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(floorSlice.actions.setFloorInsulationMaterial(material));
    },
    [dispatch]
  );
  const setFloorScreedMaterial = useCallback(
    (material: FEMaterial) => {
      dispatch(floorSlice.actions.setFloorScreedMaterial(material));
    },
    [dispatch]
  );

  const setThermalPerformance = useCallback(
    (thermalPerformance: FloorThermalPerformanceState) => {
      dispatch(floorSlice.actions.setThermalPerformance(thermalPerformance));
    },
    [dispatch]
  );

  const resetConfigurationToHeating = useCallback(
    () => dispatch(floorSlice.actions.resetConfigurationToHeating()),
    [dispatch]
  );
  const resetConfigurationToInsulation = useCallback(
    () => dispatch(floorSlice.actions.resetConfigurationToInsulation()),
    [dispatch]
  );
  const resetConfigurationToScreed = useCallback(
    () => dispatch(floorSlice.actions.resetConfigurationToScreed()),
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration: FloorConfigurationState) =>
      dispatch(floorSlice.actions.setConfiguration(configuration)),
    [dispatch]
  );

  const setHumanReadableName = useCallback(
    (name: string) => dispatch(floorSlice.actions.setHumanReadableName(name)),
    [dispatch]
  );

  const resetConfiguration = useCallback(
    () => dispatch(floorSlice.actions.resetConfiguration()),
    [dispatch]
  );

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(floorSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  );

  const addScreenshot = useCallback(
    (dataURL: string) => dispatch(floorSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  );

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(floorSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  );

  return {
    floorState,
    addScreenshot,
    setOverviewScreenshot,
    floorScreenshots,
    thermalPerformance,
    setThermalPerformance,
    downloadState,
    configurationState,
    setFloorHeatingType,
    setFloorHeatingMaterial,
    setFloorInsulationType,
    setFloorInsulationMaterial,
    setFloorScreedType,
    setFloorScreedMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToHeating,
    resetConfigurationToInsulation,
    resetConfigurationToScreed,
    resetConfiguration,
  };
}
