import { SolConformityTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1 } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useSolConformity } from "./solConformity.hooks";
import { Sidebar } from "../Sidebar";
import { getSolConformityTranslations } from "./translations";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SolConformity = () => {
  const {
    t,
    solConformityType,
    setSolConformityType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useSolConformity();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("sol.solConformity.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1 lineHeightPx={33}>{t("sol.solConformity.title")}</H1>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={SolConformityTypes.P1_1_1}
          checked={solConformityType === SolConformityTypes.P1_1_1}
          onClick={() => setSolConformityType(SolConformityTypes.P1_1_1)}
          text={getSolConformityTranslations(t, SolConformityTypes.P1_1_1)}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolConformityTypes.P1_1_2}
          checked={solConformityType === SolConformityTypes.P1_1_2}
          onClick={() => setSolConformityType(SolConformityTypes.P1_1_2)}
          text={getSolConformityTranslations(t, SolConformityTypes.P1_1_2)}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
