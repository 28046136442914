import { SolInsulationTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1 } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useSolInsulation } from "./solInsulation.hooks";
import { Sidebar } from "../Sidebar";
import { getSolInsulationTranslations } from "./translations";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const SolInsulation = () => {
  const {
    t,
    solInsulationType,
    setSolInsulationType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useSolInsulation();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs.length

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("sol.solInsulation.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1 lineHeightPx={33}>{t("sol.solInsulation.title")}</H1>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={SolInsulationTypes.ENERTHERM_CHAPE_TG}
          checked={solInsulationType === SolInsulationTypes.ENERTHERM_CHAPE_TG}
          onClick={() => setSolInsulationType(SolInsulationTypes.ENERTHERM_CHAPE_TG)}
          text={getSolInsulationTranslations(t, SolInsulationTypes.ENERTHERM_CHAPE_TG)}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE}
          checked={solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE}
          onClick={() => setSolInsulationType(SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE)}
          text={getSolInsulationTranslations(t, SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE)}
          disabled={false}
        />

        {!isMobile() && <Spacer heightPx={10} />}

        <RadioButton
          value={SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE}
          checked={solInsulationType === SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE}
          onClick={() => setSolInsulationType(SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE)}
          text={getSolInsulationTranslations(t, SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE)}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
