import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { styles } from "../../styles";
import { Input } from "./Input";
import { P } from "./Typography";

type ThermalPerformanceProps = {
  insulationThickness: number;
  UCValue: number;
  RTValue: number;
  background: boolean;
  editThickness?: boolean;
  onEditableThicknessChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  editableThicknessValid?: boolean;
  editableThicknessValue?: string;
};

const Container = styled.div<{ background: boolean }>`
  background: ${(props) =>
    props.background ? styles.colors.darkBlue5 : "unset"};
  height: ${(props) => props.theme.designHeight(150)}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.designWidth(16)}px;
  padding-bottom: ${(props) => props.theme.designWidth(16)}px;
`;

export const ThermalPerformance = ({
  insulationThickness,
  UCValue,
  RTValue,
  background,
  editThickness,
  onEditableThicknessChange,
  editableThicknessValid,
  editableThicknessValue,
}: ThermalPerformanceProps) => {
  const { t } = useTranslation();

  return (
    <Container background={background}>
      <Column
        title={t("flatRoof.overview.requiredThickness")}
        value={insulationThickness}
        unit={t("units.thicknessUnit")}
        borderRight={true}
        editThickness={editThickness}
        onEditableThicknessChange={onEditableThicknessChange}
        editableThicknessValid={editableThicknessValid}
        isThicknessValue={true}
        editableThicknessValue={editableThicknessValue}
      />
      <Column
        title={t("flatRoof.overview.UCValue")}
        value={UCValue}
        unit={t("units.lambdaUnitBottom")}
        borderRight={true}
        isThicknessValue={false}
      />
      <Column
        title={t("flatRoof.overview.RTValue")}
        value={RTValue}
        unit={t("units.insulationUnitBottom")}
        borderRight={false}
        isThicknessValue={false}
      />
    </Container>
  );
};

const ValueContainer = styled.div<{ borderRight: boolean }>`
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${styles.colors.darkBlue30}` : `unset`};

  @media screen and (pointer: coarse) {
    p {
      font-size: 13px;
      line-height: 16px;
      margin-top: 0;
    }

    .value * {
      font-weight: 700;
      font-size: 24px;
      line-height: 18px;
    }
  }
`;

const ValueItem = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex ?? `unset`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const H3 = styled.h3`
  padding-left: ${(props) => props.theme.designWidth(16)}px;
  padding-right: ${(props) => props.theme.designWidth(16)}px;

  @media screen and (pointer: coarse) {
    font-size: 13px;
    line-height: 16px;
  }
`;

const CustomInput = styled(Input)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  font-weight: 700;
  font-size: ${24 / 16}rem;
  line-height: 18px;
  padding: 10px 6px 10px 6px;
  text-align: center;
`;

const Column = ({
  title,
  value,
  unit,
  borderRight,
  editThickness,
  onEditableThicknessChange,
  editableThicknessValue,
  editableThicknessValid,
}: {
  title: string;
  value: number;
  unit: string;
  borderRight: boolean;
  editThickness?: boolean;
  onEditableThicknessChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  isThicknessValue: boolean;
  editableThicknessValue?: string;
  editableThicknessValid?: boolean;
}) => {
  const editable = editThickness && onEditableThicknessChange;
  return (
    <ValueContainer borderRight={borderRight}>
      <ValueItem flex={1.7}>
        <H3>
          <P fontSizePx={13} color={styles.colors.dark2} lineHeightPx={16}>
            {title}
          </P>
        </H3>
      </ValueItem>
      <ValueItem flex={1} className={"value"}>
        {!editable && (
          <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
            {value}
          </P>
        )}
        {editable && (
          <CustomInput
            invalid={!editableThicknessValid}
            value={editableThicknessValue}
            onChange={onEditableThicknessChange}
          />
        )}
      </ValueItem>
      <ValueItem flex={1}>
        <P fontSizePx={12} lineHeightPx={18}>
          {unit}
        </P>
      </ValueItem>
    </ValueContainer>
  );
};
