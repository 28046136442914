import { SecuringMethodTypes } from "@iko-design-center/shared";

export function getSecuringMethodTranslation(
  t: any,
  type: SecuringMethodTypes
): string {
  switch (type) {
    case SecuringMethodTypes.WELDING:
      return t("flatRoof.types.securingMethods.welding");
    case SecuringMethodTypes.LIQUID:
      return t("flatRoof.types.securingMethods.liquid");
    case SecuringMethodTypes.MECHANICAL:
      return t("flatRoof.types.securingMethods.mechanical");
    case SecuringMethodTypes.SELF_ADHERING:
      return t("flatRoof.types.securingMethods.selfAdhering");
    case SecuringMethodTypes.LOOSE:
      return t("flatRoof.types.securingMethods.loose");
    case SecuringMethodTypes.GLUE:
      return t("flatRoof.types.securingMethods.glue");
    case SecuringMethodTypes.LOOSE_BALLAST:
      return t("flatRoof.types.securingMethods.looseBallast");
  }
}
