import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useModal } from "../../store/modal/hooks";
import { styles } from "../../styles";
import { Button } from "../common/Button";
import { CloseBlackIcon } from "../common/Icons";
import { Spacer } from "../common/Spacer";
import { H1, H2, P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";

const Card = styled.section`
  position: relative;
  background: ${styles.colors.white};
  border-radius: 8px;
  padding: ${(props) => props.theme.designWidth(24)}px;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.theme.designWidth(448)}px;

  @media screen and (pointer: coarse) {
    width: 90%;
    padding: 24px;

    button:first-of-type {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 12px;
      right: 12px;
      display: grid;
      place-content: center;

      & > div {
        top: unset;
        right: unset;
        width: auto;
      }
    }

    h1,
    h2 {
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
    }

    p,
    button:last-of-type {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: ${(props) => props.theme.designWidth(30)}px;
  top: ${(props) => props.theme.designHeight(30)}px;
  width: ${(props) => props.theme.designWidth(18)}px;
`;

const TextContainer = styled.div`
  display: flex;
`;

export const ClimateClass = () => {
  const { t } = useTranslation();
  const { closeModal, displaySupportModal } = useModal();

  function handleClose() {
    closeModal();
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  function handleButtonClick() {
    displaySupportModal();
  }

  return (
    <Card onClick={handleCardClick}>
      <UnstyledButton onClick={handleClose}>
        <CloseIcon>
          <CloseBlackIcon />
        </CloseIcon>
      </UnstyledButton>
      <H1 fontSizePx={20} lineHeightPx={23}>
        {t("modal.climateClass.title")}
      </H1>
      <Spacer heightPx={19.5} />
      <P fontSizePx={16} color={styles.colors.dark3} lineHeightPx={25.6}>
        {t("modal.climateClass.subtitle")}
      </P>
      <Spacer heightPx={32} />
      <H2 fontSizePx={18}>{t("modal.climateClass.notSure")}</H2>
      <Spacer heightPx={8} />
      <TextContainer>
        <P fontSizePx={16} color={styles.colors.dark3} lineHeightPx={25.6}>
          {t("modal.climateClass.choose")}
        </P>
        {"\u00A0"}
        <P
          fontSizePx={16}
          fontWeight={500}
          color={styles.colors.dark3}
          lineHeightPx={25.6}
        >
          {t("modal.climateClass.standardOption")}
        </P>
      </TextContainer>
      <P fontSizePx={16} color={styles.colors.dark3} lineHeightPx={25.6}>
        {t("modal.climateClass.standardExplanation")}
      </P>
      <Spacer heightPx={32} />
      <Button onClick={handleButtonClick}>
        {t("modal.climateClass.button")}
      </Button>
    </Card>
  );
};
