import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../services/api.service";
import { useSlopedRoof } from "../../../store/sloped-roof/hooks";
import { useModal } from "../../../store/modal/hooks";
import { styles } from "../../../styles";
import { DownloadIcon } from "../../common/Icons";
import { Form, Input, Label, Required, TextArea } from "../../common/Input";
import { P } from "../../common/Typography";
import { StandardModal } from "../../modal/StandardModal";
import { validationRegex } from "../../modal/validationRegex";
import styled from "styled-components";

const OptinRow = styled.div`
  width: 100%;
  display: flex;
`;
const PhoneRow = styled.div`
  width: 100%;
  display: flex;
`;
const PhoneContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;
const SpacerContainer = styled.div`
  width: 20px;
`;
const PostalCodeContainer = styled.div`
  flex: 1;
  width: ${(props) => props.theme.designWidth(120)}px;
  display: flex;
  flex-direction: column;
`;

export const DownloadMail = () => {
  const { t, i18n } = useTranslation();
  const { displaySlopedRoofDownloadSuccessModal } = useModal();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [optin, setOptin] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [gdpr, setGdpr] = useState(false);
  const [invalidGdpr, setInvalidGdpr] = useState(false);
  const {
    slopedRoofState,
    downloadState,
    thermalPerformance,
    configurationState,
    slopedRoofScreenshots,
  } = useSlopedRoof();
  const language = i18n.language;

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidEmail(false);
    setEmail(e.target.value);
  }

  function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidName(false);
    setName(e.target.value);
  }

  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPhone(false);
    setPhone(e.target.value);
  }

  function handlePostalCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPostalCode(false);
    setPostalCode(e.target.value);
  }

  function handleGdprChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidGdpr(false);
    setGdpr(e.target.checked);
  }

  async function validate(e: SyntheticEvent) {
    const validMail = validationRegex.email.test(String(email).toLowerCase());
    const validName = name !== "";
    const validPhone = validationRegex.phone.test(phone);
    const validPostalCode = postalCode !== "";
    const validGdpr = gdpr !== false;

    if (!validMail) {
      setInvalidEmail(true);
    }

    if (!validName) {
      setInvalidName(true);
    }

    if (!validPhone) {
      setInvalidPhone(true);
    }

    if (!validPostalCode) {
      setInvalidPostalCode(true);
    }

    if (!validGdpr) {
      setInvalidGdpr(true);
    }

    if (validName && validMail && validPhone && validPostalCode && validGdpr) {
      const target = e.currentTarget;
      target.classList.add("clicked");
      target.setAttribute("disabled", "disabled");

      // TODO: Implement loading state
      const { roofType, roofInsulation, UCValue, RTValue } = thermalPerformance;
      api
        .postDownload(
          {
            screenshots: slopedRoofScreenshots,
            humanReadableName:
              configurationState.humanReadableName ?? undefined,
            overviewScreenshot: slopedRoofState.overviewScreenshot!,
            specifications: configurationState.files,
            thermalPerformance: {
              UCValue: UCValue!,
              RTValue: RTValue!,
              roofType: {
                thickness: roofType.thickness!,
                lambda: roofType.lambda!,
                RValue: roofType.RValue!,
              },
              roofInsulation: {
                thickness: roofInsulation.thickness!,
                lambda: roofInsulation.lambda!,
                RValue: roofInsulation.RValue!,
              },
            },
            download: downloadState,
            user: {
              name,
              email,
              phone,
              postalCode,
              company,
              message,
              language,
              optin,
            },
            materials: {
              roofTypeId: configurationState.roofType.material!.id!,
              roofInsulationId: configurationState.roofInsulation.material!.id!,
            },
          },
          "sloped-roof"
        )
        .then((url) => {
          displaySlopedRoofDownloadSuccessModal();
          if (typeof (window as any).lintrk !== "undefined") {
            (window as any).lintrk("track", { conversion_id: 5352082 });
          }
        })
        .catch((error) => {
          target.classList.remove("clicked");
          target.removeAttribute("disabled");
        });
    }
  }

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    await validate(e);
  }

  return (
    <StandardModal
      titleIcon={DownloadIcon}
      title={t("modal.downloadMail.title")}
      subtitle={t("modal.downloadMail.subtitle")}
      buttonText={t("modal.downloadMail.button")}
      handleSubmit={handleSubmit}
      classnames={"mail standard-modal"}
    >
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">
          {t("modal.downloadMail.nameLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidName}
          type="text"
          value={name}
          onChange={handleNameChange}
        />
        {invalidName && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.downloadMail.invalidName")}
          </P>
        )}
        <Label htmlFor="email">
          {t("modal.downloadMail.emailLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidEmail}
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {invalidEmail && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.downloadMail.invalidEmail")}
          </P>
        )}
        <PhoneRow>
          <PhoneContainer>
            <Label htmlFor="phone">
              {t("modal.requestAdvice.phoneLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPhone}
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              required
            />
            {invalidPhone && (
              <P
                fontSizePx={12}
                color={styles.colors.white65}
                lineHeightPx={20}
              >
                {t("modal.requestAdvice.invalidPhone")}
              </P>
            )}
          </PhoneContainer>
          <SpacerContainer />
          <PostalCodeContainer>
            <Label htmlFor="postalCode">
              {t("modal.requestAdvice.postalCodeLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPostalCode}
              type="text"
              value={postalCode}
              onChange={handlePostalCodeChange}
            />
          </PostalCodeContainer>
        </PhoneRow>
        <Label htmlFor="company">{t("modal.requestAdvice.companyLabel")}</Label>
        <Input
          invalid={false}
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <Label htmlFor="message">{t("modal.requestAdvice.messageLabel")}</Label>
        <TextArea
          invalid={false}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <OptinRow className={"checkbox"}>
          <Input
            type="checkbox"
            invalid={false}
            id="optin"
            value={optin ? "1" : "0"}
            onChange={(e) => setOptin(e.target.checked)}
          />
          <Label htmlFor="optin">{t("modal.downloadMail.optinLabel")}</Label>
        </OptinRow>
        <OptinRow className={"checkbox"}>
          <Input
            type="checkbox"
            invalid={invalidGdpr}
            id="gdpr"
            value={gdpr ? "1" : "0"}
            onChange={handleGdprChange}
            required
          />
          <Label htmlFor="gdpr">
            <span
              dangerouslySetInnerHTML={{
                __html: t("modal.downloadMail.gdprLabel", {
                  link: `<a href="${t(
                    "email.privacyNoticeURL"
                  )}" rel="noopener" target="_blank" style="color: #fff;">`,
                  endlink: "</a>",
                }),
              }}
            ></span>
            <Required>*</Required>
          </Label>
        </OptinRow>
        <OptinRow>
          {invalidGdpr && (
            <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
              {t("modal.downloadMail.invalidGdpr")}
            </P>
          )}
        </OptinRow>
      </Form>
    </StandardModal>
  );
};
