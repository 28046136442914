import {
  CavityWallConfigurationState,
  initialCavityWallConfigurationState,
  initialCavityWallThermalPerformanceState,
  POSSIBLE_CONFIGURATIONS,
  PossibleConfiguration,
  CavityWallThermalPerformanceState,
} from "@iko-design-center/shared";
import { DownloadState, initialDownloadState } from "./download";

export type CavityWallState = {
  configuration: CavityWallConfigurationState;
  allPossibleConfigurations: PossibleConfiguration[];
  possibleConfigurations: PossibleConfiguration[];
  download: DownloadState;
  thermalPerformance: CavityWallThermalPerformanceState;
  screenshots: string[];
  overviewScreenshot: string | null;
};

export const initialCavityWallState: CavityWallState = {
  configuration: initialCavityWallConfigurationState,
  download: initialDownloadState,
  allPossibleConfigurations: POSSIBLE_CONFIGURATIONS,
  possibleConfigurations: POSSIBLE_CONFIGURATIONS,
  thermalPerformance: initialCavityWallThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
};
