import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { ApplicationTypes } from "../../store/application/store";
import { styles } from "../../styles";
import { P } from "../common/Typography";

function getBorderColor(selected: boolean, disabled: boolean) {
  if (disabled) {
    return styles.colors.darkBlue10;
  }

  return selected
    ? isMobile()
      ? styles.colors.primary
      : styles.colors.dark2
    : styles.colors.darkBlue30;
}

const Button = styled.button<{ selected: boolean; disabled: boolean }>`
  &:focus {
    outline: none;
  }
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${styles.colors.white};
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => getBorderColor(props.selected, props.disabled)};
  border-radius: 10px;

  .coming {
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    padding: 10px 0;
    border-radius: 10px 10px 0 0;
    background: #d4d4db;
    color: #fff;
  }

  @media screen and (pointer: coarse) {
    // aspect-ratio: 172 / 127;
    padding: 16px;

    .coming {
      color: ${styles.colors.darkBlue};
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
    }

    svg {
      height: 56px;
      width: 56px;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const Text = styled.span`
  margin-top: ${(props) => props.theme.designHeight(16)}px;
`;

type TileProps = {
  translationKey: string;
  image: ReactNode;
  selected: boolean;
  handleClick: (value: ApplicationTypes) => void;
  value: ApplicationTypes;
  disabled: boolean;
};

const Tile = ({
  disabled,
  translationKey,
  image,
  selected,
  handleClick,
  value,
}: TileProps) => {
  const { t } = useTranslation();

  function onClick() {
    handleClick(value);
  }

  return (
    <Button onClick={onClick} selected={selected} disabled={disabled}>
      {disabled ? (
        <div className={"coming"}>{t("home.comingSoon" as any)}</div>
      ) : (
        ""
      )}
      {image}
      <Text>
        <P
          color={disabled ? styles.colors.darkBlue30 : styles.colors.dark2}
          fontSizePx={12}
        >
          {t(translationKey as any)}
        </P>
      </Text>
    </Button>
  );
};

export { Tile };
