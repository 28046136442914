import { SlopedRoofLayers } from "@iko-design-center/shared";
import { MultiSelectOption } from "../../components/common/MultiSelect";

export type DownloadState = {
  technicalFiche: {
    roofType: boolean;
    roofInsulation: boolean;
  };
  DOP: {
    roofType: boolean;
    roofInsulation: boolean;
  };
  thermalCalculation: boolean;
  specifications: boolean;
  BIMFile: boolean;
  screenshots: boolean;
  processingGuideline: boolean;
};

export const initialDownloadState: DownloadState = {
  technicalFiche: {
    roofType: false,
    roofInsulation: false,
  },
  DOP: {
    roofType: false,
    roofInsulation: false,
  },
  thermalCalculation: false,
  specifications: false,
  BIMFile: false,
  screenshots: false,
  processingGuideline: false,
};

export const allSelectedDownloadState: DownloadState = {
  technicalFiche: {
    roofType: true,
    roofInsulation: true,
  },
  DOP: {
    roofType: true,
    roofInsulation: true,
  },
  thermalCalculation: true,
  specifications: true,
  BIMFile: true,
  screenshots: true,
  processingGuideline: true,
};

export type LayerState = {
  [key in SlopedRoofLayers]: boolean;
};

export function getLayerOptions({
  t,
  state,
}: {
  t: any;
  state: LayerState;
}): MultiSelectOption<SlopedRoofLayers>[] {
  return [
    {
      label: t("modal.download.insulation"),
      value: SlopedRoofLayers.roofInsulation,
      checked: state.roofInsulation,
    },
  ];
}
