import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { useFlatRoof } from "../../store/flat-roof/hooks";
import { useCavityWall } from "../../store/cavity-wall/hooks";
import { useRoutes } from "../../store/routes/hooks";

export const HomeGuard = (props: { children: ReactNode }) => {
  return (
    <>
      <ResetAllStateGuard>{props.children}</ResetAllStateGuard>
    </>
  );
};

// This makes sure, that when a user navigates backwards,
// either via the Navbar or via the browser back button,
// the state is reset. This so the user can reselect any options.
const ResetAllStateGuard = (props: { children: ReactNode }) => {
  const { resetConfiguration: flatRoofReset } = useFlatRoof();
  const { resetConfiguration: cavityWallReset } = useCavityWall();
  const { Routes } = useRoutes() as any;
  const location = useLocation();
  const routeHome = Routes.HOME;

  useEffect(() => {
    switch (location.pathname) {
      case routeHome:
        flatRoofReset();
        cavityWallReset();
        break;
    }
  }, [flatRoofReset, cavityWallReset, location.pathname, routeHome]);

  return <>{props.children}</>;
};
