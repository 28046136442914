import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../services/screenshot.service";
import { useSlopedRoof } from "../../../store/sloped-roof/hooks";
import { useModal } from "../../../store/modal/hooks";
import { renderer } from "../../viewer/Renderer";
import { useApplication } from "../../../store/application/hooks";
import { isMobile } from "../../../services/helpers.service";

type VisibleState = {
  roofType: boolean;
  roofInsulation: boolean;
  roofInsulationFoil: boolean;
  roofVaporBarrier: boolean;
  roofConstruction: boolean;
  details: boolean;
  // SKYLIGHT
  skylight: boolean;
  skylightGutter: boolean;
  skylightAccessories: boolean;
  // CHIMNEY
  chimneyTransitPan: boolean;
  chimneyDrain: boolean;
  // FOOTING BEAM
  footingBeamGutter: boolean;
  // VALLEY GUTTER
  valleyGutter: boolean;
  valleyGutterCarpentry: boolean;
  valleyGutterGutter: boolean;
  // RIDGE
  ridgeSlab: boolean;
  ridgeBeam: boolean;
};

export function useOverview() {
  const { t } = useTranslation() as any;
  const {
    configurationState: { roofType, roofInsulation },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
  } = useSlopedRoof();

  const roofTypeType = roofType.type!;
  const roofInsulationType = roofInsulation.type!;

  const {
    displaySlopedRoofDownloadModal,
    displaySlopedRoofThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal();
  const { currentModel, applicationType } = useApplication();

  const initialVisibleState = useMemo(
    () => ({
      roofType: true,
      roofInsulation: true,
      roofInsulationFoil: true,
      roofVaporBarrier: true,
      roofConstruction: true,
      details: true,
      // SKYLIGHT
      skylight: true,
      skylightGutter: true,
      skylightAccessories: true,
      // CHIMNEY
      chimneyTransitPan: true,
      chimneyDrain: true,
      // FOOTING BEAM
      footingBeamGutter: true,
      // VALLEY GUTTER
      valleyGutter: true,
      valleyGutterCarpentry: true,
      valleyGutterGutter: true,
      // RIDGE
      ridgeSlab: true,
      ridgeBeam: true,
    }),
    []
  );
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  );
  const humanReadableName = `${roofType.type} / ${roofInsulation.type}`;

  useEffect(() => {
    setHumanReadableName(humanReadableName);
  }, [humanReadableName, setHumanReadableName]);

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers();
      const dataURL = renderer.createScreenshot();
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL);
      });

      return () => {
        renderer.hideMarkers();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  );

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] });
  }

  useEffect(() => {
    setVisibleState(initialVisibleState);
    if (!isMobile()) renderer.displayMarkers();
    renderer.toggleAllLayers(applicationType, true);
  }, [currentModel, setVisibleState, initialVisibleState, applicationType]);

  function handleToggleClick(toggle = "roofType", layer = "DAKBEDEKKING") {
    toggleVisibleState(toggle as keyof VisibleState);
    renderer.toggleLayers(layer);
  }

  function handleDetailsClick() {
    toggleVisibleState("details");
    renderer.toggleLayers("DAKDETAILS");
    renderer.toggleDetails();
  }

  function handleAdjustSpecificationsClick() {
    displaySlopedRoofThermalCalculationModal();
  }

  function handleDownloadFilesClick() {
    displaySlopedRoofDownloadModal();
  }

  function handleRequestAdviceClick() {
    displayRequestAdviceModal();
  }

  return {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    roofTypeType,
    roofInsulationType,

    handleToggleClick,
    handleDetailsClick,

    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,
  };
}
