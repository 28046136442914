import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { RadioCheckedIcon, RadioUncheckedIcon } from "./Icons";
import { UnstyledButton } from "./UnstyledButton";

const RadioContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? `unset` : `pointer`)};
  position: relative;
  width: 100%;
  height: ${(props) => props.theme.designHeight(52)}px;
  min-height: 45px;
  border: ${(props) =>
    props.checked
      ? `2px solid ${styles.colors.primary}`
      : `1px solid ${styles.colors.darkBlue30}`};
  border-radius: 8px;
  padding-left: ${(props) =>
    props.checked
      ? props.theme.designWidth(24)
      : props.theme.designWidth(25)}px;
  padding-right: ${(props) =>
    props.checked
      ? props.theme.designWidth(24)
      : props.theme.designWidth(25)}px;
  padding-top: ${(props) =>
    props.checked
      ? props.theme.designHeight(15)
      : props.theme.designHeight(16)}px;
  padding-bottom: ${(props) =>
    props.checked
      ? props.theme.designHeight(15)
      : props.theme.designHeight(16)}px;
  display: flex;
`;

const DisabledOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${styles.colors.darkBlue5};
  border-radius: 8px;
`;

const Radio = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  font-weight: 400;
  font-size: ${() => 14 / 16}rem;
  color: ${styles.colors.dark2};
  line-height: ${() => 20 / 19}rem;
  margin-top: 3px;
`;

type RadioButtonProps = {
  checked: boolean;
  value: any;
  text: string;
  onClick: (value: any) => void;
  disabled: boolean;
};

export const RadioButton = ({
  checked,
  value,
  onClick,
  text,
  disabled,
}: RadioButtonProps) => {
  function handleClick() {
    if (!disabled) {
      onClick(value);
    }
  }

  return (
    <>
      <RadioContainer
        onClick={handleClick}
        checked={checked}
        disabled={disabled}
        className={"radio"}
      >
        {disabled && <DisabledOverlay />}
        <Radio>
          {checked && <RadioCheckedIcon />}
          {!checked && <RadioUncheckedIcon />}
          <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
        </Radio>
      </RadioContainer>
    </>
  );
};
