"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VentilatedWallFacadeCladdingTypes = exports.VentilatedWallWallTypeTypes = exports.downloadVentilatedWallDTOSchema = exports.SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS = exports.SLOPED_ROOF_R_VALUE_RSE = exports.SLOPED_ROOF_R_VALUE_RSI = exports.initialSlopedRoofThermalPerformanceState = exports.initialSlopedRoofConfigurationState = exports.SlopedRoofLayers = exports.slopedRoofMaterialMap = exports.SlopedRoofInsulationTypeTypes = exports.SlopedRoofTypeTypes = exports.downloadSlopedRoofDTOSchema = exports.CAVITY_WALL_MINIMUM_INSULATION_THICKNESS = exports.CAVITY_WALL_R_VALUE_AIR_CAVITY = exports.CAVITY_WALL_R_VALUE_RSE = exports.CAVITY_WALL_R_VALUE_RSI = exports.initialCavityWallThermalPerformanceState = exports.initialCavityWallConfigurationState = exports.CavityWallLayers = exports.cavityWallMaterialMap = exports.OuterCavityLeafTypes = exports.WallInsulationTypes = exports.WallTypeTypes = exports.downloadCavityWallDTOSchema = exports.RoofDetails = exports.Layers = exports.FR_FR_TRANSLATIONS = exports.NL_NL_TRANSLATIONS = exports.FR_BE_TRANSLATIONS = exports.NL_BE_TRANSLATIONS = exports.NL_TRANSLATIONS = exports.FLAT_ROOF_MINIMUM_INSULATION_THICKNESS = exports.FLAT_ROOF_R_VALUE_RSE = exports.FLAT_ROOF_R_VALUE_RSI = exports.initialFlatRoofThermalPerformanceState = exports.initialFlatRoofConfigurationState = exports.RoofFloorTypes = exports.RoofTypeClimateClasses = exports.RoofTypeTypes = exports.WaterproofingTypes = exports.SecuringMethodTypes = exports.requestAdviceDTOSchema = exports.supportDTOSchema = exports.downloadFlatRoofDTOSchema = exports.POSSIBLE_CONFIGURATIONS = exports.MaterialIDs = exports.FE_MATERIALS = exports.MATERIALS = exports.FILES = void 0;
exports.SOL_MINIMUM_INSULATION_THICKNESS = exports.SOL_R_VALUE_RSE = exports.SOL_R_VALUE_RSI = exports.initialSolThermalPerformanceState = exports.initialSolConfigurationState = exports.SolLayers = exports.SolMaterialMap = exports.SolBuildingTypeTypes = exports.SolConformityTypes = exports.SolAcousticLayerTypes = exports.SolNoiseLevelTypes = exports.SolInsulationTypes = exports.downloadSolDTOSchema = exports.FLOOR_MINIMUM_INSULATION_THICKNESS = exports.FLOOR_R_VALUE_RSE = exports.FLOOR_R_VALUE_RSI = exports.initialFloorThermalPerformanceState = exports.initialFloorConfigurationState = exports.FloorLayers = exports.FloorMaterialMap = exports.FloorScreedTypes = exports.FloorInsulationTypes = exports.FloorHeatingTypes = exports.downloadFloorDTOSchema = exports.VENTILATED_WALL_MINIMUM_INSULATION_THICKNESS = exports.VENTILATED_WALL_R_VALUE_RSE = exports.VENTILATED_WALL_R_VALUE_RSI = exports.initialVentilatedWallThermalPerformanceState = exports.initialVentilatedWallConfigurationState = exports.VentilatedWallLayers = exports.ventilatedWallMaterialMap = exports.VentilatedWallInsulationTypes = void 0;
const constants_1 = require("./constants");
Object.defineProperty(exports, "downloadFlatRoofDTOSchema", { enumerable: true, get: function () { return constants_1.downloadFlatRoofDTOSchema; } });
Object.defineProperty(exports, "supportDTOSchema", { enumerable: true, get: function () { return constants_1.supportDTOSchema; } });
Object.defineProperty(exports, "requestAdviceDTOSchema", { enumerable: true, get: function () { return constants_1.requestAdviceDTOSchema; } });
Object.defineProperty(exports, "initialFlatRoofConfigurationState", { enumerable: true, get: function () { return constants_1.initialFlatRoofConfigurationState; } });
Object.defineProperty(exports, "initialFlatRoofThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialFlatRoofThermalPerformanceState; } });
Object.defineProperty(exports, "FLAT_ROOF_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.FLAT_ROOF_R_VALUE_RSI; } });
Object.defineProperty(exports, "FLAT_ROOF_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.FLAT_ROOF_R_VALUE_RSE; } });
Object.defineProperty(exports, "FLAT_ROOF_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.FLAT_ROOF_MINIMUM_INSULATION_THICKNESS; } });
Object.defineProperty(exports, "Layers", { enumerable: true, get: function () { return constants_1.Layers; } });
Object.defineProperty(exports, "NL_TRANSLATIONS", { enumerable: true, get: function () { return constants_1.NL_TRANSLATIONS; } });
Object.defineProperty(exports, "NL_BE_TRANSLATIONS", { enumerable: true, get: function () { return constants_1.NL_BE_TRANSLATIONS; } });
Object.defineProperty(exports, "FR_BE_TRANSLATIONS", { enumerable: true, get: function () { return constants_1.FR_BE_TRANSLATIONS; } });
Object.defineProperty(exports, "NL_NL_TRANSLATIONS", { enumerable: true, get: function () { return constants_1.NL_NL_TRANSLATIONS; } });
Object.defineProperty(exports, "FR_FR_TRANSLATIONS", { enumerable: true, get: function () { return constants_1.FR_FR_TRANSLATIONS; } });
Object.defineProperty(exports, "RoofDetails", { enumerable: true, get: function () { return constants_1.RoofDetails; } });
Object.defineProperty(exports, "RoofFloorTypes", { enumerable: true, get: function () { return constants_1.RoofFloorTypes; } });
Object.defineProperty(exports, "RoofTypeClimateClasses", { enumerable: true, get: function () { return constants_1.RoofTypeClimateClasses; } });
Object.defineProperty(exports, "RoofTypeTypes", { enumerable: true, get: function () { return constants_1.RoofTypeTypes; } });
Object.defineProperty(exports, "SecuringMethodTypes", { enumerable: true, get: function () { return constants_1.SecuringMethodTypes; } });
Object.defineProperty(exports, "WaterproofingTypes", { enumerable: true, get: function () { return constants_1.WaterproofingTypes; } });
Object.defineProperty(exports, "FILES", { enumerable: true, get: function () { return constants_1.FILES; } });
Object.defineProperty(exports, "MATERIALS", { enumerable: true, get: function () { return constants_1.MATERIALS; } });
Object.defineProperty(exports, "POSSIBLE_CONFIGURATIONS", { enumerable: true, get: function () { return constants_1.POSSIBLE_CONFIGURATIONS; } });
Object.defineProperty(exports, "MaterialIDs", { enumerable: true, get: function () { return constants_1.MaterialIDs; } });
Object.defineProperty(exports, "FE_MATERIALS", { enumerable: true, get: function () { return constants_1.FE_MATERIALS; } });
Object.defineProperty(exports, "downloadCavityWallDTOSchema", { enumerable: true, get: function () { return constants_1.downloadCavityWallDTOSchema; } });
Object.defineProperty(exports, "WallTypeTypes", { enumerable: true, get: function () { return constants_1.WallTypeTypes; } });
Object.defineProperty(exports, "WallInsulationTypes", { enumerable: true, get: function () { return constants_1.WallInsulationTypes; } });
Object.defineProperty(exports, "OuterCavityLeafTypes", { enumerable: true, get: function () { return constants_1.OuterCavityLeafTypes; } });
Object.defineProperty(exports, "cavityWallMaterialMap", { enumerable: true, get: function () { return constants_1.cavityWallMaterialMap; } });
Object.defineProperty(exports, "CavityWallLayers", { enumerable: true, get: function () { return constants_1.CavityWallLayers; } });
Object.defineProperty(exports, "initialCavityWallConfigurationState", { enumerable: true, get: function () { return constants_1.initialCavityWallConfigurationState; } });
Object.defineProperty(exports, "initialCavityWallThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialCavityWallThermalPerformanceState; } });
Object.defineProperty(exports, "CAVITY_WALL_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.CAVITY_WALL_R_VALUE_RSI; } });
Object.defineProperty(exports, "CAVITY_WALL_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.CAVITY_WALL_R_VALUE_RSE; } });
Object.defineProperty(exports, "CAVITY_WALL_R_VALUE_AIR_CAVITY", { enumerable: true, get: function () { return constants_1.CAVITY_WALL_R_VALUE_AIR_CAVITY; } });
Object.defineProperty(exports, "CAVITY_WALL_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.CAVITY_WALL_MINIMUM_INSULATION_THICKNESS; } });
Object.defineProperty(exports, "downloadSlopedRoofDTOSchema", { enumerable: true, get: function () { return constants_1.downloadSlopedRoofDTOSchema; } });
Object.defineProperty(exports, "SlopedRoofTypeTypes", { enumerable: true, get: function () { return constants_1.SlopedRoofTypeTypes; } });
Object.defineProperty(exports, "SlopedRoofInsulationTypeTypes", { enumerable: true, get: function () { return constants_1.SlopedRoofInsulationTypeTypes; } });
Object.defineProperty(exports, "slopedRoofMaterialMap", { enumerable: true, get: function () { return constants_1.slopedRoofMaterialMap; } });
Object.defineProperty(exports, "SlopedRoofLayers", { enumerable: true, get: function () { return constants_1.SlopedRoofLayers; } });
Object.defineProperty(exports, "initialSlopedRoofConfigurationState", { enumerable: true, get: function () { return constants_1.initialSlopedRoofConfigurationState; } });
Object.defineProperty(exports, "initialSlopedRoofThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialSlopedRoofThermalPerformanceState; } });
Object.defineProperty(exports, "SLOPED_ROOF_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.SLOPED_ROOF_R_VALUE_RSI; } });
Object.defineProperty(exports, "SLOPED_ROOF_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.SLOPED_ROOF_R_VALUE_RSE; } });
Object.defineProperty(exports, "SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.SLOPED_ROOF_MINIMUM_INSULATION_THICKNESS; } });
Object.defineProperty(exports, "downloadVentilatedWallDTOSchema", { enumerable: true, get: function () { return constants_1.downloadVentilatedWallDTOSchema; } });
Object.defineProperty(exports, "VentilatedWallWallTypeTypes", { enumerable: true, get: function () { return constants_1.VentilatedWallWallTypeTypes; } });
Object.defineProperty(exports, "VentilatedWallFacadeCladdingTypes", { enumerable: true, get: function () { return constants_1.VentilatedWallFacadeCladdingTypes; } });
Object.defineProperty(exports, "VentilatedWallInsulationTypes", { enumerable: true, get: function () { return constants_1.VentilatedWallInsulationTypes; } });
Object.defineProperty(exports, "ventilatedWallMaterialMap", { enumerable: true, get: function () { return constants_1.ventilatedWallMaterialMap; } });
Object.defineProperty(exports, "VentilatedWallLayers", { enumerable: true, get: function () { return constants_1.VentilatedWallLayers; } });
Object.defineProperty(exports, "initialVentilatedWallConfigurationState", { enumerable: true, get: function () { return constants_1.initialVentilatedWallConfigurationState; } });
Object.defineProperty(exports, "initialVentilatedWallThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialVentilatedWallThermalPerformanceState; } });
Object.defineProperty(exports, "VENTILATED_WALL_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.VENTILATED_WALL_R_VALUE_RSI; } });
Object.defineProperty(exports, "VENTILATED_WALL_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.VENTILATED_WALL_R_VALUE_RSE; } });
Object.defineProperty(exports, "VENTILATED_WALL_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.VENTILATED_WALL_MINIMUM_INSULATION_THICKNESS; } });
Object.defineProperty(exports, "downloadFloorDTOSchema", { enumerable: true, get: function () { return constants_1.downloadFloorDTOSchema; } });
Object.defineProperty(exports, "FloorHeatingTypes", { enumerable: true, get: function () { return constants_1.FloorHeatingTypes; } });
Object.defineProperty(exports, "FloorInsulationTypes", { enumerable: true, get: function () { return constants_1.FloorInsulationTypes; } });
Object.defineProperty(exports, "FloorScreedTypes", { enumerable: true, get: function () { return constants_1.FloorScreedTypes; } });
Object.defineProperty(exports, "FloorMaterialMap", { enumerable: true, get: function () { return constants_1.FloorMaterialMap; } });
Object.defineProperty(exports, "FloorLayers", { enumerable: true, get: function () { return constants_1.FloorLayers; } });
Object.defineProperty(exports, "initialFloorConfigurationState", { enumerable: true, get: function () { return constants_1.initialFloorConfigurationState; } });
Object.defineProperty(exports, "initialFloorThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialFloorThermalPerformanceState; } });
Object.defineProperty(exports, "FLOOR_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.FLOOR_R_VALUE_RSI; } });
Object.defineProperty(exports, "FLOOR_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.FLOOR_R_VALUE_RSE; } });
Object.defineProperty(exports, "FLOOR_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.FLOOR_MINIMUM_INSULATION_THICKNESS; } });
Object.defineProperty(exports, "downloadSolDTOSchema", { enumerable: true, get: function () { return constants_1.downloadSolDTOSchema; } });
Object.defineProperty(exports, "SolInsulationTypes", { enumerable: true, get: function () { return constants_1.SolInsulationTypes; } });
Object.defineProperty(exports, "SolNoiseLevelTypes", { enumerable: true, get: function () { return constants_1.SolNoiseLevelTypes; } });
Object.defineProperty(exports, "SolAcousticLayerTypes", { enumerable: true, get: function () { return constants_1.SolAcousticLayerTypes; } });
Object.defineProperty(exports, "SolConformityTypes", { enumerable: true, get: function () { return constants_1.SolConformityTypes; } });
Object.defineProperty(exports, "SolBuildingTypeTypes", { enumerable: true, get: function () { return constants_1.SolBuildingTypeTypes; } });
Object.defineProperty(exports, "SolMaterialMap", { enumerable: true, get: function () { return constants_1.SolMaterialMap; } });
Object.defineProperty(exports, "SolLayers", { enumerable: true, get: function () { return constants_1.SolLayers; } });
Object.defineProperty(exports, "initialSolConfigurationState", { enumerable: true, get: function () { return constants_1.initialSolConfigurationState; } });
Object.defineProperty(exports, "initialSolThermalPerformanceState", { enumerable: true, get: function () { return constants_1.initialSolThermalPerformanceState; } });
Object.defineProperty(exports, "SOL_R_VALUE_RSI", { enumerable: true, get: function () { return constants_1.SOL_R_VALUE_RSI; } });
Object.defineProperty(exports, "SOL_R_VALUE_RSE", { enumerable: true, get: function () { return constants_1.SOL_R_VALUE_RSE; } });
Object.defineProperty(exports, "SOL_MINIMUM_INSULATION_THICKNESS", { enumerable: true, get: function () { return constants_1.SOL_MINIMUM_INSULATION_THICKNESS; } });
