import { FloorHeatingTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useFloorHeating } from "./floorHeating.hooks";
import { Sidebar } from "../Sidebar";
import { getFloorHeatingTranslations } from "./translations";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const FloorHeating = () => {
  const {
    t,
    floorHeatingType,
    setFloorHeatingType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useFloorHeating();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("floor.floorHeating.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("floor.floorHeating.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("floor.floorHeating.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={FloorHeatingTypes.FLOOR_HEATING}
          checked={floorHeatingType === FloorHeatingTypes.FLOOR_HEATING}
          onClick={() => setFloorHeatingType(FloorHeatingTypes.FLOOR_HEATING)}
          text={getFloorHeatingTranslations(t, FloorHeatingTypes.FLOOR_HEATING)}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={FloorHeatingTypes.NO_FLOOR_HEATING}
          checked={floorHeatingType === FloorHeatingTypes.NO_FLOOR_HEATING}
          onClick={() =>
            setFloorHeatingType(FloorHeatingTypes.NO_FLOOR_HEATING)
          }
          text={getFloorHeatingTranslations(
            t,
            FloorHeatingTypes.NO_FLOOR_HEATING
          )}
          disabled={false}
        />
      </RadioButtonList>
    </Sidebar>
  );
};
