import { Layers } from "@iko-design-center/shared";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../services/screenshot.service";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { useModal } from "../../../store/modal/hooks";
import { renderer } from "../../viewer/Renderer";
import { useApplication } from "../../../store/application/hooks";
import { isMobile } from "../../../services/helpers.service";

type VisibleState = {
  topLayer: boolean;
  bottomLayer: boolean;
  insulation: boolean;
  vaporShield: boolean;
  roofFloor: boolean;
  roofDetails: boolean;
};

export function useOverview() {
  const { t } = useTranslation();
  const {
    configurationState: { roofStructure, securingMethods, roofFloor },
    thermalPerformance,
    setOverviewScreenshot,
  } = useFlatRoof();

  const topLayerMaterial = roofStructure.topLayerMaterial!;
  const bottomLayerMaterial = roofStructure.bottomLayerMaterial!;
  const insulationMaterial = roofStructure.insulationMaterial!;
  const vaporShieldMaterial = roofStructure.vaporShieldMaterial!;
  const roofFloorMaterial = roofStructure.roofFloorMaterial!;
  const topLayerSecuringMethod = securingMethods.topLayer!;
  const bottomLayerSecuringMethod = securingMethods.bottomLayer!;
  const insulationSecuringMethod = securingMethods.insulation!;
  const vaporShieldSecuringMethod = securingMethods.vaporShield!;
  const roofFloorType = roofFloor.type!;

  const {
    displayFlatRoofDownloadModal,
    displayFlatRoofThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal();
  const { currentModel } = useApplication();

  const initialVisibleState = useMemo(
    () => ({
      topLayer: true,
      bottomLayer: true,
      insulation: true,
      vaporShield: true,
      roofFloor: true,
      roofDetails: true,
    }),
    []
  );
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  );

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers();
      const dataURL = renderer.createScreenshot();
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL);
      });

      return () => {
        renderer.hideMarkers();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  );

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] });
  }

  useEffect(() => {
    setVisibleState(initialVisibleState);
    if (!isMobile()) renderer.displayMarkers();
    for (const layer in Layers) {
      renderer.toggleLayers(layer as Layers, true);
    }
  }, [currentModel, setVisibleState, initialVisibleState]);

  function handleTopLayerClick() {
    toggleVisibleState("topLayer");
    renderer.toggleLayers(Layers.topLayer);
  }
  function handleBottomLayerClick() {
    toggleVisibleState("bottomLayer");
    renderer.toggleLayers(Layers.bottomLayer);
  }
  function handleInsulationClick() {
    toggleVisibleState("insulation");
    renderer.toggleLayers(Layers.insulation);
  }
  function handleVaporShieldClick() {
    toggleVisibleState("vaporShield");
    renderer.toggleLayers(Layers.vaporShield);
  }
  function handleRoofFloorClick() {
    toggleVisibleState("roofFloor");
    renderer.toggleLayers(Layers.roofFloor);
  }
  function handleRoofDetailsClick() {
    toggleVisibleState("roofDetails");
    renderer.toggleDetails();
  }
  function handleAdjustSpecificationsClick() {
    displayFlatRoofThermalCalculationModal();
  }
  function handleDownloadFilesClick() {
    displayFlatRoofDownloadModal();
  }
  function handleRequestAdviceClick() {
    displayRequestAdviceModal();
  }
  return {
    t,
    thermalPerformance,
    visibleState,
    topLayerMaterial,
    topLayerSecuringMethod,
    bottomLayerMaterial,
    bottomLayerSecuringMethod,
    insulationMaterial,
    insulationSecuringMethod,
    vaporShieldMaterial,
    vaporShieldSecuringMethod,
    roofFloorMaterial,
    roofFloorType,
    displayFlatRoofDownloadModal,
    displayRequestAdviceModal,
    displayFlatRoofThermalCalculationModal,
    handleBottomLayerClick,
    handleTopLayerClick,
    handleInsulationClick,
    handleVaporShieldClick,
    handleRoofFloorClick,
    handleRoofDetailsClick,
    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,
  };
}
