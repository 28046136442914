import { RoofFloorTypes } from "@iko-design-center/shared"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"

export function useRoofFloor() {
  const { Routes } = useRoutes() as any
  const { t } = useTranslation()
  const history = useHistory()
  const {
    configurationState,
    setRoofFloorType: setStoreRoofFloorType,
  } = useFlatRoof()

  const roofFloorType = configurationState.roofFloor.type
  const nextButtonDisabled = !configurationState.roofFloor.type

  function handleNextButtonClick() {
    history.push(Routes.FLAT_ROOF_3_WATERPROOFING)
  }

  function setRoofFloorType(type: RoofFloorTypes) {
    setStoreRoofFloorType(type)
    renderer.displayRoofFloor(type)
  }

  return {
    t,
    handleNextButtonClick,
    nextButtonDisabled,
    setRoofFloorType,
    roofFloorType,
  }
}
