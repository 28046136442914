import {
  FEMaterial,
  SlopedRoofConfigurationState,
  SlopedRoofThermalPerformanceState,
  initialSlopedRoofThermalPerformanceState,
  ThermalPerformanceLayer,
} from "@iko-design-center/shared";

export function updateThermalLayer(
  thermalPerformance: SlopedRoofThermalPerformanceState,
  material: FEMaterial,
  type: string
) {
  const draft: SlopedRoofThermalPerformanceState = { ...thermalPerformance };
  // @ts-ignore
  draft[type] = getThermalPerformanceLayer(material);
  return calculateThermalPerformance(draft);
}

export function getThermalPerformance(
  state: SlopedRoofConfigurationState
): SlopedRoofThermalPerformanceState {
  const thermalPerformance = { ...initialSlopedRoofThermalPerformanceState };

  thermalPerformance.roofType = getThermalPerformanceLayer(
    state.roofType.material!
  );
  thermalPerformance.roofInsulation = getThermalPerformanceLayer(
    state.roofInsulation.material!
  );

  return calculateThermalPerformance(thermalPerformance);
}

function calculateThermalPerformance(
  state: SlopedRoofThermalPerformanceState
): SlopedRoofThermalPerformanceState {
  const draft = { ...state };
  let isComplete = true;

  ["roofType", "roofInsulation"].forEach((layer: string) => {
    // @ts-ignore
    const { thickness, lambda } = draft[layer] as ThermalPerformanceLayer;

    if (thickness && lambda) {
      // @ts-ignore
      draft[layer] = {
        thickness,
        lambda,
        RValue: parseFloat((thickness / 1000 / lambda).toFixed(2)),
      };
    } else {
      isComplete = false;
    }
  });

  if (isComplete) {
    draft.RTValue = parseFloat(
      (
        // draft.roofType.RValue! +
        draft.roofInsulation.RValue!
      ).toFixed(2)
    );
    draft.UCValue = parseFloat((1 / draft.RTValue).toFixed(2));
  }
  return draft;
}

function getThermalPerformanceLayer(
  material: FEMaterial
): ThermalPerformanceLayer {
  const isVariable =
    material.lambdaThicknessThresholds &&
    material.lambdaThicknessThresholds.length > 0 &&
    material.lambdas.length > 1;

  const thickness = material.thickness;
  let lambda: number;

  if (!isVariable) {
    lambda = material.lambdas[0];
  } else {
    material.lambdaThicknessThresholds!.forEach((threshold, index) => {
      if (thickness > threshold) {
        lambda = material.lambdas[index + 1];
      }
    });
  }

  return {
    thickness,
    lambda: lambda!,
    RValue: null,
  };
}
