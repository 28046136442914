import { RoofFloorTypes } from "@iko-design-center/shared";
import React from "react";
import { Spacer } from "../../common/Spacer";
import { H1, P } from "../../common/Typography";
import { Grid } from "../Grid";
import { PictureTile } from "../PictureTile";
import { useRoofFloor } from "./roofFloor.hooks";
import { Sidebar } from "../Sidebar";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const RoofFloor = () => {
  const {
    roofFloorType,
    setRoofFloorType,
    nextButtonDisabled,
    handleNextButtonClick,
    t,
  } = useRoofFloor();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("flatRoof.roofFloor.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("flatRoof.roofFloor.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("flatRoof.roofFloor.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <Grid rows={2} gaps={true} className={"tiles-grid"}>
        <PictureTile
          src={"/./assets/images/wood_texture.png"}
          selected={roofFloorType === RoofFloorTypes.WOOD}
          text={t(`flatRoof.types.roofFloor.wood`)}
          onClick={() => setRoofFloorType(RoofFloorTypes.WOOD)}
        />
        <PictureTile
          src={"/./assets/images/concrete_texture.png"}
          selected={roofFloorType === RoofFloorTypes.CONCRETE}
          text={t(`flatRoof.types.roofFloor.concrete`)}
          onClick={() => setRoofFloorType(RoofFloorTypes.CONCRETE)}
        />
        <PictureTile
          src={"/./assets/images/steel_deck_texture.png"}
          selected={roofFloorType === RoofFloorTypes.STEEL_DECK}
          text={t(`flatRoof.types.roofFloor.steelDeck`)}
          onClick={() => setRoofFloorType(RoofFloorTypes.STEEL_DECK)}
        />
      </Grid>
    </Sidebar>
  );
};
