import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useModal } from "../../../store/modal/hooks";
import { DownloadIcon, SuccessIcon } from "../../common/Icons";
import { StandardModal } from "../../modal/StandardModal";

const Content = styled.div`
  height: ${(props) => props.theme.designHeight(150)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadSuccess = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <StandardModal
      title={t("modal.downloadSuccess.title")}
      buttonText={t("modal.downloadSuccess.button")}
      handleSubmit={closeModal}
      subtitle={t("modal.downloadSuccess.subtitle")}
      titleIcon={DownloadIcon}
      classnames={"success standard-modal"}
    >
      <Content>
        <SuccessIcon />
      </Content>
    </StandardModal>
  );
};
