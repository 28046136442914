import { VENTILATED_WALL_MINIMUM_INSULATION_THICKNESS } from "@iko-design-center/shared";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateThermalLayer } from "../../../store/ventilated-wall/thermalPerformance";
import { useVentilatedWall } from "../../../store/ventilated-wall/hooks";
import { useModal } from "../../../store/modal/hooks";
import { FEMaterial } from "@iko-design-center/shared";

export function useThermalCalculation() {
  const {
    configurationState,
    thermalPerformance,
    setVentilatedWallWallTypeMaterial,
    setVentilatedWallInsulationMaterial,
  } = useVentilatedWall();
  const { closeModal } = useModal();
  const { t } = useTranslation() as any;

  const [localThermalPerformance, setLocalThermalPerformance] = useState(
    thermalPerformance
  );

  const [localWallTypeMaterial, setLocalWallTypeMaterial] = useState({
    material: configurationState.wallType.material!,
    valid: true,
  });
  const [
    localWallInsulationMaterial,
    setLocalWallInsulationMaterial,
  ] = useState({
    material: configurationState.wallInsulation.material!,
    valid: true,
  });

  const [editableThicknessValue, setEditableThicknessValue] = useState(
    thermalPerformance.wallInsulation.thickness!.toString()
  );
  const [editableThicknessValid, setEditableThicknessValid] = useState(true);

  function handleEditableThicknessChange(
    event: SyntheticEvent<HTMLInputElement>
  ) {
    const input = event.target as HTMLInputElement;
    const type = input.dataset.type!;
    let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

    let validThickness = true;
    if (!thickness) {
      validThickness = false;
    }

    if (type && thickness.toString().length <= 3) {
      const updatedLocalMaterial = {
        material: {
          // @ts-ignore
          ...(configurationState[type].material as FEMaterial),
          thickness,
        },
        valid: validThickness,
      };

      switch (type) {
        case "wallType":
          setLocalWallTypeMaterial(updatedLocalMaterial);
          break;
        case "wallInsulation":
          setLocalWallInsulationMaterial(updatedLocalMaterial);
          break;
      }
      setLocalThermalPerformance(
        updateThermalLayer(
          localThermalPerformance,
          updatedLocalMaterial.material,
          type
        )
      );
    } else {
      const value = (event.target as HTMLInputElement).value;
      setEditableThicknessValue(value);

      thickness = parseInt(value);
      if (
        thickness &&
        thickness >= VENTILATED_WALL_MINIMUM_INSULATION_THICKNESS &&
        thickness.toString().length <= 3
      ) {
        const updatedLocalMaterial = {
          material: {
            // @ts-ignore
            ...(configurationState["wallInsulation"].material as FEMaterial),
            thickness,
          },
          valid: validThickness,
        };

        setLocalWallInsulationMaterial(updatedLocalMaterial);
        setLocalThermalPerformance(
          updateThermalLayer(
            localThermalPerformance,
            updatedLocalMaterial.material,
            "wallInsulation"
          )
        );
        setEditableThicknessValid(true);
      } else {
        setEditableThicknessValid(false);
      }
    }
  }

  function handleCancel() {
    closeModal();
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation();
  }

  function handleApply() {
    const allValid =
      localWallTypeMaterial.valid &&
      localWallInsulationMaterial.valid &&
      editableThicknessValid;

    if (allValid) {
      setVentilatedWallWallTypeMaterial(localWallTypeMaterial.material);
      setVentilatedWallInsulationMaterial(localWallInsulationMaterial.material);
      closeModal();
    }
  }

  return {
    t,
    thermalPerformance: localThermalPerformance,
    wallTypeType: configurationState.wallType.type!,
    wallInsulationType: configurationState.wallInsulation.type!,

    handleCardClick,
    handleCancel,
    handleApply,

    localWallTypeMaterial,
    localWallInsulationMaterial,

    editableThicknessValue,
    editableThicknessValid,
    handleEditableThicknessChange,
  };
}
