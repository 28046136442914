import React from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { useApplication } from "../../store/application/hooks";
import { HomeIcon } from "../common/Icons";
import { Spacer } from "../common/Spacer";
import { UnstyledButton } from "../common/UnstyledButton";
import { renderer } from "./Renderer";
import { initialModel } from "./renderer.api";
import { ViewerNavigationDropdown } from "./ViewerNavigationDropdown";
import { useRoutes } from "../../store/routes/hooks";

import { useSol } from "../../store/sol/hooks";
import { useFlatRoof } from "../../store/flat-roof/hooks";
import { useSlopedRoof } from "../../store/sloped-roof/hooks";
import { useCavityWall } from "../../store/cavity-wall/hooks";
import { useVentilatedWall } from "../../store/ventilated-wall/hooks";
import { useFloor } from "../../store/floor/hooks";

const SectionLeft = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 7;
  display: flex;
  align-items: flex-start;

  @media screen and (pointer: coarse) {
    gap: 10px;
    top: 16px;
    left: 16px;
    right: 16px;
    overflow: hidden;

    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      min-width: 36px;
      height: 36px;
    }
  }
`;

export const ViewerNavigation = () => {
  const {
    setApplicationType,
    applicationType,
    currentModel,
  } = useApplication();
  const { Routes } = useRoutes() as any
  const history = useHistory();
  const location = useLocation();
  // FLAT ROOF
  const { resetConfiguration: flatRoofReset } = useFlatRoof();
  // SLOPED ROOF
  const { resetConfiguration: slopedRoofReset } = useSlopedRoof();
  // CAVITY WALL
  const { resetConfiguration: cavityWallReset } = useCavityWall();
  // VENTILATED WALL
  const { resetConfiguration: ventilatedWallReset } = useVentilatedWall();
  // FLOOR
  const { resetConfiguration: floorReset } = useFloor();
  // SOL
  const { resetConfiguration: solReset } = useSol();

  function handleHomeIconClick() {
    renderer.displayModel(initialModel);
    renderer.reset();
    if (location.pathname !== Routes.PREVIEW) history.push(Routes.HOME);
    setApplicationType(null);

    // FLAT ROOF
    flatRoofReset()
    // SLOPED ROOF
    slopedRoofReset()
    // CAVITY WALL
    cavityWallReset()
    // VENTILATED WALL
    ventilatedWallReset()
    // FLOOR
    floorReset()
    // SOL
    solReset()
  }

  return (
    <SectionLeft>
      <UnstyledButton onClick={handleHomeIconClick}>
        <HomeIcon />
      </UnstyledButton>
      {!isMobile() && <Spacer widthPx={11} />}
      <ViewerNavigationDropdown
        applicationType={applicationType}
        setApplicationType={setApplicationType}
        currentModel={currentModel}
      />
    </SectionLeft>
  );
};
