import {
  SolConfigurationState,
  SolThermalPerformanceState,
  initialSolConfigurationState,
  initialSolThermalPerformanceState,
} from "@iko-design-center/shared"
import { DownloadState, initialDownloadState } from "./download"

export type SolState = {
  configuration: SolConfigurationState
  download: DownloadState
  thermalPerformance: SolThermalPerformanceState
  screenshots: string[]
  overviewScreenshot: string | null
}

export const initialSolState: SolState = {
  configuration: initialSolConfigurationState,
  download: initialDownloadState,
  thermalPerformance: initialSolThermalPerformanceState,
  screenshots: [],
  overviewScreenshot: null,
}
